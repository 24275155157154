import { Tooltip } from 'elements';
import { useNavigate } from 'react-router-dom';

import { Wrapper, Bar, Line, CircleLink, CircleDiv } from "./styled";

const Steps = ({ items, step, withLabels, withLinks }) => {
  const navigate = useNavigate();

  const handleClick = step => e => {
    if (step === 1) {
      e.preventDefault();
      navigate(`?redirect=${e.target.pathname}`);
    }
  }

  return (
    <Wrapper>
      {/* <Text style={{ display: notShow ? "none" : "block" }}>Step {step} of 10</Text> */}

      <Bar $withLabels={withLabels}>
        <Line />

        {items?.map((item) =>
          withLinks ? (
            <Tooltip title={item.title} key={item.title}>
              <CircleLink
                to={item.path}
                onClick={handleClick(step)}
                $active={item.step === step}
                $completed={item.step < step}
                $color={item.color}
                $label={item.label}
                $labelWidth={item.labelWidth}
                $withLabels={withLabels}
              />
            </Tooltip>
          ) : (
            <CircleDiv
              $active={item.step === step}
              $completed={item.step < step}
              $color={item.color}
              $label={item.label}
              $labelWidth={item.labelWidth}
              $withLabels={withLabels}
              key={item.title}
            />
          )
        )}
      </Bar>
    </Wrapper>
  );
};

export default Steps;
