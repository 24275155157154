import { getMyClients } from "api";
// import { Error } from "Responses/Response";
import { ActionTypes } from "./types";

export const GetMyClients = () => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data } = await getMyClients();
    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
  } catch (error) {
    // Error(error?.response?.data?.detail);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
