import styled from 'styled-components';
import DefaultAvatar from '../../../assets/images/DefaultAvatar.png';

export const ProfileImageWrapper = styled.div`
  position: absolute;
  min-height: 120px;
  min-width: 120px;
  margin-left: 20px;
  margin-bottom: 8px;
  left: 0;
  bottom: 0;

  ${({ theme }) => theme.max('lg')`
    height: 100px;
    width: 100px;
  `}
`;

export const ProfileImage = styled.img`
  height: 120px;
  width: 120px;
  object-fit: cover;
  border-radius: 50%;
  background-image: url(${DefaultAvatar});
  border: 2px solid #fff;

  ${({ theme }) => theme.max('lg')`
    height: 100px;
    width: 100px;
  `}
`;

export const ProfilePlanIcon = styled.img`
  position: absolute;
  right: -8px;
  top: -2px;
`;

export const EditProfileIcon = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 28px;
  width: 28px;
  cursor: pointer;
`;

export const FileUploader = styled.input`
  display: none;
`;
