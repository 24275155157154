// @ts-ignore
import { listTasks } from "../apm-apis";

import { ActionTypes } from "./type";

export const fetchTasks = () => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await listTasks();

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });
  }
};

export const getTask = (taskId: string, callback: any) => async (_: any, getState: any) => {
  const { data } = getState().listTasks;
  const task = data.find((task: any) => task.id === taskId);

  callback(task);
}
