import { BaseService } from "api/base";

const buildUrl = (url, params) => {
    const queryString = Object.keys(params)
        .filter(key => params[key] !== '' && params[key] !== undefined && params[key] !== null)
        .map(key => {
            const value = params[key];
            if (Array.isArray(value)) {
                // Join array values with a comma
                return `${encodeURIComponent(key)}=${encodeURIComponent(value.join(','))}`;
            } else {
                // Directly encode the string representation of the value
                return `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`;
            }
        })
        .join('&');
    return url + (queryString ? `?${queryString}` : '');
};

export const listUnprioritizedActions = () => BaseService.get(`/apm/prioritized-activities/list_unprioritized_actions/`);

export const listPrioritizedActivities = (filterParams = {}) => {
    const url = buildUrl('/apm/prioritized-activities/', filterParams);
    return BaseService.get(url);
}

export const listPrioritizedActivitiesGrouped = (filterParams = {}) => {
    const url = buildUrl('/apm/prioritized-activities/grouped_priorities/', filterParams);
    return BaseService.get(url);
}

export const listFocusedPrioritized = (week_starting) => week_starting ?  BaseService.get(`/apm/focused-priorities/?week_starting=${week_starting}`) : BaseService.get(`/apm/focused-priorities/`)
export const listCompleteFocusedPrioritized = (week_starting) => week_starting ?  BaseService.get(`/apm/focused-priorities/completed_focused_priorities/?week_starting=${week_starting}`) : BaseService.get(`/apm/focused-priorities/completed_focused_priorities/`)
export const createPrioritizedActivity = (data) => BaseService.post(`/apm/prioritized-activities/`, data);
export const listAvailableAreasAndIndirectScores = (prioritizedActivityId) => BaseService.get(`/apm/prioritized-activities/list_available_areas/?prioritized_activity_id=${prioritizedActivityId}`);
export const updateIndirectScores = (data) => BaseService.post(`/apm/prioritized-activities/update_indirect_scores/`, data);
export const updateTimeline = (data) => BaseService.post(`/apm/prioritized-activities/update_timeline/`, data);
export const updateWeeklyDuration = (data) => BaseService.post(`/apm/prioritized-activities/update_weekly_duration/`, data);
export const addPrioritizedActivityToFocusedActivities = (data) => BaseService.post(`/apm/focused-priorities/${data.override_limit ? '?override_limit=True' : ''}`, data);
export const updateFocusedActivityPriorities = (data) => BaseService.post(`/apm/focused-priorities/update_priorities/`, data);
export const removeFocusedPriority = (focusedPriorityId) => BaseService.remove(`/apm/focused-priorities/${focusedPriorityId}/`);
export const removeFocusedPriorities = (focusedPrioritiesIds) => BaseService.post(`/apm/focused-priorities/bulk-delete/`, focusedPrioritiesIds);
export const listJourneyFilters = () => BaseService.get(`/apm/prioritized-activities/my_journeys/`);
export const listAreaFilters = () => BaseService.get(`/apm/prioritized-activities/my_areas/`);

export const listCategories = (data) => BaseService.get(`/domains/search_categories/${data ? `?category_name=${data}` : ''}`);
export const listGoals = (data) => BaseService.get(`/apm/prioritized-activities/list_goals/?category_name=${data}`);
export const listTasks = (data) => BaseService.get(`/tasks/?completed=False&ordering=-estimated_duration`);
export const createActionItem = (data) => BaseService.post(`/apm/prioritized-activities/create_action/`, data);
export const updateActionItem = (data) => BaseService.post(`/apm/prioritized-activities/update_action/`, data);
export const createTask = (data) => BaseService.post(`/tasks/`, data);
export const updateTask = (data, id) => BaseService.patch(`/tasks/${id}/`, data);
export const deleteTask = (id) => BaseService.remove(`/tasks/${id}/`);
export const completeTask = (task_id) => BaseService.post(`/tasks/mark_completed/`,{task_id:task_id});

export const getFocusedExtremes = (startDate) => BaseService.get(`/apm/focused-priorities/time_extremes/?week_starting=${startDate}`);
