import styled from "styled-components";
/* import PopoverCustom, { Toggler } from "../PopoverCustom";
import { DatePicker } from "antd";
import { Input } from "../Form";
 */
interface BackgroundProps {
  $active: boolean;
}

export const Background = styled.div<BackgroundProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(79, 89, 102, 0.50);
  top: 0;
  left: 0;
  z-index: 998;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s, visibility .25s;

  ${({ $active }) => $active && `
    opacity: 1;
    visibility: visible;
  `};
}`;

export const Wrapper = styled.div<BackgroundProps>`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 390px;
  height: calc(100vh - 92px);
  background: #fff;
  right: 15px;
  top: 0;
  margin-top: 75px;
  border-radius: 8px;
  z-index: 99999;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  transition: transform .25s, opacity .25s, visibility .25s;

  ${({ $active }) =>
    $active &&
    `
    transform: translateX(0%);
    opacity: 1;
    visibility: visible;
  `};
}`;

interface AreaColorProps {
  $color?: string;
}

export const Header = styled.div<AreaColorProps>`
  border-bottom: ${({ $color }) => ($color ? ` 4px solid ${$color}` : "")};
  padding: 10px 12px 10px 8px;
  min-height: auto;
`;

export const HeaderContent = styled.div`
  display: flex;
  height: auto;

`;

export const TopContent = styled.div`
  display: flex;
  height: auto;
  border-bottom: 1px solid #ced0d4;
  justify-content: flex-end;
  padding-bottom: 4px;
  margin-bottom: 14px;

`;

/* 
export const AreaColor = styled.div<AreaColorProps>`
  min-width: 5px;
  height: auto;
  border-radius: 0px 4px 4px 0px;
  background: ${({ $color }) => ($color ? $color : "#79D4B6")};
  margin-right: 9px;
`; */

/* export const MainHeader = styled.div`
  display: flex;
  width: 319px;
  max-height: 45px;
  min-height: 30px;
  justify-content: space-between;
`;
 */
/* export const CustomPopover = styled(PopoverCustom)`
  background: red;
  ${Toggler} {
    align-self: baseline;
  }
`; */

/* export const Breadcrumbs = styled.p`
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #6d7784;
  margin: 3px 0px;
`; */

/* interface GoalProps {
  $active?: boolean;
}

export const Goal = styled.div<GoalProps>`
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  width: 74px;
  align-items: center;

  &:hover {
    #Ellipse_405 {
      fill: #1271a6;
    }

    #Vector {
      stroke: #1271a6;
    }
  }
  &:focus-within {
    #Ellipse_405 {
      fill: ${({ $active }) => ($active ? "#1271A6" : "initial")};
    }

    #Vector {
      stroke: ${({ $active }) => ($active ? "#1271A6" : "initial")};
    }
  }
`; */

/* interface GoalTitleProps {
  $active?: boolean;
}

export const GoalTitle = styled.div<GoalTitleProps>`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  color: ${({ $active }) => ($active ? "#1271A6" : "#6D7784")};
  &:hover{
    color: #1271A6;
  }
}`; */

/* export const SubTitle = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #6d7784;
  margin: -8px 0px -8px 0px;
  max-height: 20px !important;
`; */

/* export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.span`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
`; */

export const CloseBtn = styled.button`
  display: flex;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  padding: 0;
  transition: transform 0.25s;
  margin-left: 10px;

  &:hover {
    transform: rotate(180deg);
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 12px;
  overflow-y: auto;
  margin-right: 6px;
  margin-top: 6px;

  ::-webkit-scrollbar:vertical {
    width: 6px !important;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important ;
  }

  ::-webkit-scrollbar-thumb:vertical {
    background-color: #acb4bc;
    border-radius: 100px;
  }
`;

export const Content = styled.div`
  margin-top: -6px;
`;

interface ListProps {
  $priority?: boolean;
}

export const List = styled.div<ListProps>`
  display: flex;
  align-items: baseline;
  width: 359px;
  height: 34px;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  gap: 12px;
  background: #f5f5f5;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;

  ${({ $priority }) => $priority && `
    margin-top: 12px;
    margin-bottom: 0;
  `};
`;

interface ListTitleProps {
  $duration?: boolean;
}
export const ListTitle = styled.div<ListTitleProps>`
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #737373;

  ${({ $duration }) =>
    $duration &&
    `
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #1B2A3D;
  `};
`;

export const ListItemCount = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

/* export const ReturnValue = styled.div`
  display: flex;
  align-items: baseline;
  gap: 5px;
`; */

/* export const CustomDatePicker = styled.span`
  width: 187px;
  height: 28px;
  border-radius: 4px;
  border: 0.5px;
  gap: 12px';
`; */

/* interface CustomInputProps {
  $urgency?: boolean;
}

export const CustomInput = styled(Input)<CustomInputProps>`
  width: 36px;
  height: 28px;
  padding: 4px 6px 4px 6px;
  border-radius: 4px;
  border: 0.5px;
  gap: 12px;

  ${({ $urgency }) =>
    $urgency &&
    `
      width: 62px;
      border: 0.3px solid grey;
      border-radius: 4px;

      
  `};
}`; */

/* export const Delegation = styled.span`
  color: #171717;
  padding: 7px 6px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`; */

/* export const StyledCalender = styled(DatePicker)`
  .ant-picker-clear {
    display: none !important;
  }
`; */
