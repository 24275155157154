
import styled from "styled-components";

export const TypeChar = styled.div<{width:number,height:number}>`
  text-align: center;
  width: ${({ width }) => width ? `${width}px` : '16px !important'};
  height: ${({ height }) => height ? `${height}px` : '16px !important'};
  border-radius: 2px;
  background: #EBEEF2;
  color: #4F9BE8;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  text-transform: uppercase;
  margin-right: 10px;
  letter-spacing: 0em;
  cursor: default;
  padding: 3px;
  
  &:hover{
    cursor: pointer;
  }

`;
