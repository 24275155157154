import { useEffect } from "react";
import { Progress, ProgressContent, ProgressWrapper, Wrapper } from "./styled";
import { useSelector } from "react-redux";

// @ts-ignore
import { RootState } from "Redux/store";

const ProgressBar = ({ setPercentages }: { setPercentages?: any }) => {
  const { listFocusedPrioritise: focusedData } = useSelector(
    (state: RootState) => state.listFocusedPrioritise
  );

  const { listCompleteFocusedPrioritise: completeFocusedData } = useSelector(
    (state: RootState) => state.listFocusedPrioritise
  );

  const _mins = focusedData.reduce((total: number, item: any) => {
    return (
      total +
      Number(
        item?.prioritized_activity?.total_time
          ? item?.prioritized_activity?.estimated_weekly_duration
          : item?.task?.estimated_duration || 0
      )
    );
  }, 0);

  const _completeMins = completeFocusedData.reduce(
    (total: number, item: any) => {
      return (
        total +
        Number(
          item?.prioritized_activity?.total_time
            ? item?.prioritized_activity?.estimated_weekly_duration
            : item?.task?.estimated_duration || 0
        )
      );
    },
    0
  );

  const totalHours = 168;
  const doneHours = Math.floor(_completeMins / 60);
  const prioritizedHours = Math.floor(_mins / 60);

  const donePercent = Math.round((doneHours / totalHours) * 100);
  const prioritizedPercent = Math.round((prioritizedHours / totalHours) * 100);
  const availableHours = totalHours - doneHours - prioritizedHours;
  // const availablePercent = Math.round((availableHours / totalHours) * 100);

  useEffect(() => {
    setPercentages &&
      setPercentages({
        doneHours,
        prioritizedHours,
        availableHours,
      });
  }, [
    doneHours,
    prioritizedHours,
    availableHours,
    setPercentages,
    completeFocusedData,
    focusedData,
  ]);

  return (
    <Wrapper>
      <ProgressWrapper>
        <Progress
          width={donePercent + "%"}
          $color1="rgba(71, 185, 31, 1)"
          $color2="rgba(80, 211, 93, 1)"
        />
        <Progress
          width={prioritizedPercent + "%"}
          $color1="rgba(34, 147, 228, 1)"
          $color2="rgba(88, 185, 255, 1)"
        />
      </ProgressWrapper>

      <ProgressContent>{totalHours} Hrs</ProgressContent>
    </Wrapper>
  );
};

export default ProgressBar;
