import moment from "moment";
import {
  Wrapper,
  Head,
  Row,
  Col,
  OrderNumber,
  Heading,
  Title,
  Text
} from "./styled.js";

const Group = ({ data }) => {
  return (
    <Wrapper>
      <Head>
        <OrderNumber>{data?.goal?.priority}</OrderNumber>

        <Heading>
          <Title>
            <Text $area>{data?.name}</Text>
            <Text $category>{data?.goal?.category_name}</Text>
          </Title>

          <Text $goal>{data?.goal?.goal_name}</Text>
        </Heading>
      </Head>

      <Row>
        <Col>
          <Text $title>Measurement</Text>
          <Text $p>{data?.goal?.measurement_current_value ||
            data?.goal?.measurement_target_value
            ? `${data?.goal?.measurement_current_value ??
            " - "
            }/${data?.goal?.measurement_target_value ??
            " - "
            } ${data?.goal?.measurement_metric_name ?? ''}`
            : data?.goal?.measurement}</Text>
        </Col>

        <Col>
          <Text $title>ACCOUNTABILITY</Text>
          <Text $p>{data?.goal?.accountability}</Text>
        </Col>

        <Col>
          <Text $title>STARTLINE</Text>
          <Text $p>
            {data?.goal?.startline
              ? moment(data?.goal?.startline).format("MM/DD/YY")
              : ""}
          </Text>
        </Col>

        <Col>
          <Text $title>DEADLINE</Text>
          <Text $p>
            {data?.goal?.deadline
              ? moment(data?.goal?.deadline).format("MM/DD/YY")
              : ""}
          </Text>
        </Col>
      </Row>

      <Row>
        <Col $hr>
          <Text $title>REWARDS</Text>
          <Text $p>{data?.goal?.pleasure}</Text>
        </Col>

        <Col>
          <Text $title>CONSEQUENCES</Text>
          <Text $p>{data?.goal?.pain}</Text>
        </Col>
      </Row>

      <Row>
        <Col $hr>
          <Text $title>ACTION 1</Text>
          <Text $p>
            {data?.goal?.actions && data?.goal?.actions[0]?.context}
          </Text>
        </Col>

        <Col>
          <Text $title>ACTION 2</Text>
          <Text $p>
            {data?.goal?.actions && data?.goal?.actions[1]?.context}
          </Text>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Group;
