import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";
import { Select } from "antd";
import moment, { Moment, duration } from "moment";
import { Button, Tooltip, InputArea, InputField, DatePicker } from "elements";

//@ts-ignore
import { newAction } from '../../../../Redux/APM/NewAction/action';

import {
  Background,
  Wrapper,

  Header,
  HeaderHeading,
  HeaderTitle,
  CloseBtn,

  Body,
  Row,
  Col,
  Label,
  ErrorLabel,

  QuestionIcn,
  Footer
} from "./styled";

// @ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/priorities/close.svg";

// @ts-ignore
import { ReactComponent as ActionIcon } from "assets/images/priorities/action-form.svg";

// @ts-ignore
import { ReactComponent as WarningIcon } from "assets/images/journeys/warning.svg";

// @ts-ignore
import { ReactComponent as QuestionIcon } from "assets/images/priorities/QuestionIcon.svg";

import CategoriesSelect from "./CategoriesSelect";
import GoalsSelect from "./GoalsSelect";
import PopoverCustom from "../PopoverCustom";
import DelegationPopup from "../DelegationPopup";
import WeeklyDuration from "components/PriorityApp/SimpleView/WeeklyDurationPopover";

// @ts-ignore
import { createAction, updateAction } from "Redux/APM/CreateAction/action";

// @ts-ignore
import { createTask, updateTask } from "Redux/APM/CreateTask/action";

interface IGoal {
  id: string;
  gap_percentage: number | null;
  goal_name: string;
  category_name: string;
  startline: string | null;
  deadline: string | null;
}

interface IProps {
  active: boolean;
  data?: any;
  goal?: IGoal;
  type?: ActionType;
  onClose: () => void;
  setActions:(data:any) => void
}

const dateFormat = "MM/DD/YYYY";

const ActionTypes = [
  {
    value: "Habit",
    label: "Habit",
  },
  {
    value: "Decision",
    label: "Decision",
  },
  {
    value: "Action Item",
    label: "Action",
  },
];

const PriorityOptions = [
  {
    value: "High",
    label: "High",
  },
  {
    value: "Medium",
    label: "Medium",
  },
  {
    value: "Low",
    label: "Low",
  },
];

interface IState {
  actionName: string;
  category: { id: number | null; name: string };
  goal: string | null| any;
  startline: Moment | null;
  deadline: Moment | null;
  duration: {
    h: string;
    m: string;
  };
  myTime: {
    h: string;
    m: string;
  };
  actionType: string | null;
  priority: string;
}

export enum ActionType {
  Habit = "Habit",
  Action = "Action",
  Task = "Task",
  Decision = "Decision"
}

const ActionItemForm: FC<IProps> = ({ active: isActive, data, goal, setActions }) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const createParam = queryParams.get('create');

  const [isRendered, setIsRendered] = useState(isActive);
  const [isActiveState, setIsActiveState] = useState(false);
  const [isAddGoal, setIsAddGoal] = useState(false);

  const [type, setType] = useState<ActionType>(ActionType.Action);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [hoveredComponent, setHoveredComponent] = useState<string>('');
  const [isEdited, setIsEdited] = useState(false);

  const [values, setValues] = useState<IState>({
    actionName: '',
    category: { id: null, name: '' },
    goal: null,
    startline: null,
    deadline: null,
    duration: {
      h: '',
      m: ''
    },
    myTime: {
      h: '',
      m: ''
    },
    actionType: null,
    priority: "Medium"
  });

  const [errors, setErrors] = useState({
    actionName: false,
    category: false,
    goal: false,
    startline: false,
    deadline: false,
    duration: false
  });

  useEffect(() => {
    if (data) {
      // Assuming that if there is no action, it's a task
      if (!data?.action) {
        setValues({
          ...values,
          actionName: data.name,
          deadline: data.deadline ? moment(data.deadline) : null,
          priority: data.priority,
          duration: {
            h: isNaN(Math.floor(data.estimated_duration / 60)) ? '' : (Math.floor(data.estimated_duration / 60)).toString(),
            m: isNaN(data.estimated_duration % 60) ? '' : (data.estimated_duration % 60).toString()
          },
        })
      } else {
        setValues({
          actionName: data.action.context,
          category: /* data.category_name */{ id: data.category_name, name: data.category_name },
          goal: data.goal_name,
          startline: data.action.startline ? moment(data.action.startline) : null,
          deadline: data.action.deadline ? moment(data.action.deadline) : null,
          duration: {
            h: isNaN(Math.floor(data.delegation_time / 60)) ? '' : (Math.floor(data.delegation_time / 60)).toString(),
            m: isNaN(data.delegation_time % 60) ? '' : (data.delegation_time % 60).toString()
          },
          myTime: {
            h: isNaN(Math.floor(data.management_time / 60)) ? '' : (Math.floor(data.management_time / 60)).toString(),
            m: isNaN(data.management_time % 60) ? '' : (data.management_time % 60).toString()
          },
          actionType: data.action.type,
          priority: data.action.priority
        });
      }

      setIsEdited(false); // Reset isEdited when data is loaded
    }
  }, [data]);

  const $input = useRef<HTMLInputElement | null>(null);
  
  const { loading: actionLoading } = useSelector((state: any) => state.createAction);
  const { loading: taskLoading } = useSelector((state: any) => state.createTask);

  const loading = actionLoading || taskLoading;

  useEffect(() => {
    if (isActive || createParam) {
      setIsRendered(true);
  
      setTimeout(() => {
        setIsActiveState(true);
        setIsEditMode(!createParam);
        setType(createParam ? createParam as ActionType : data?.action ? data?.action?.type ? data?.action?.type as ActionType : ActionType.Action : ActionType.Task);
      }, 50);
    } else {
      setIsActiveState(false);
      setTimeout(() => setIsRendered(false), 500); // Delay unmounting by 500ms
    }
  }, [isActive, createParam]);
  
  useEffect(() => {
    if (isActiveState) {
      setTimeout(() => {
        if ($input.current ) {
          $input.current.focus();
         
        }
      }, 50);
    }
  }, [isActiveState]);

  const handleClose = () => {
    setValues({
      actionName: '',
      category: { id: null, name: '' },
      goal: null,
      startline: null,
      deadline: null,
      duration: {
        h: '',
        m: ''
      },
      myTime: {
        h: '',
        m: ''
      },
      actionType: null,
      priority: "Medium"
    });

    setErrors({
      actionName: false,
      category: false,
      goal: false,
      startline: false,
      deadline: false,
      duration: false
    });

    navigate('.', { replace: true, state: { create: null } });
    setActions(null);
    dispatch(newAction(false));
  }

  const handleActionNameChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValues({
      ...values,
      actionName: e.target.value ?? ''
    });

    setErrors({
      ...errors,
      actionName: false
    });

    setIsEdited(true); // Set edited to true on change
  }

  const handleDateChange = (field: string) => (date: any) => {
    setValues({
      ...values,
      [field]: date
    });

    setErrors({
      ...errors,
      [field]: false
    });

    setIsEdited(true); // Set edited to true on change
  }

  const handleTimeChange = (field: string, unit: string) => (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value.length) {
      setValues({
        ...values,
        [field]: {
          // @ts-ignore
          ...values[field],
          [unit]: ''
        }
      });

      setIsEdited(true); // Set edited to true on change

      return;
    }

    let value = parseInt(e.target.value);

    if (isNaN(value)) {
      return;
    }
  
    if (unit === 'h') {
      value = Math.min(999, Math.max(0, value));
    } else if (unit === 'm') {
      value = Math.min(59, Math.max(0, value));
    }
  
    setValues({
      ...values,
      [field]: {
        // @ts-ignore
        ...values[field],
        [unit]: value
      }
    });
  
    if (field === 'duration') {
      setErrors({
        ...errors,
        duration: false});
    }
    setIsEdited(true); // Set edited to true on change
  };

  const handleCategorySelect = (value: any) => {
    setValues({
      ...values,
      category: value,
      goal: null
    });
  
    setErrors({
      ...errors,
      category: false
    });
  
    setIsEdited(true); // Set edited to true on change
  };

  const handleGoalSelect = (value: any) => {
    setValues({
      ...values,
      goal: value
    });

    setErrors({
      ...errors,
      goal: false
    });

    setIsEdited(true); // Set edited to true on change
  }

  const handlePriorityChange = (option: string) => {
    setValues({
      ...values,
      priority: option
    });

    setIsEdited(true); // Set edited to true on change
  }

  const handleSubmit = () => {
    // Validation
    let validationErrors = {
      actionName: !values.actionName,
      duration: (!values.duration.h || values.duration.h === '0') && (!values.duration.m || values.duration.m === '0')
    };

    if (type === ActionType.Task) {
      setErrors({
        ...errors,
        ...validationErrors
      });
  
      if (Object.values(validationErrors).some(error => error)) {
        return;
      }

      const formData = new FormData();

      formData.append("name", values.actionName);
      formData.append("priority", values.priority);

      if (values.duration.h || values.duration.m) {
        const duration = {
          h: parseInt(values.duration.h || '0'),
          m: parseInt(values.duration.m || '0')
        };

        formData.append("estimated_duration", (duration.h * 60 + duration.m).toString());
      }

      formData.append("deadline", values.deadline ? moment(values.deadline).format('YYYY-MM-DD') : '');

      if (data) {
        dispatch(updateTask(formData, data.id, handleClose));
      }
      else {
        dispatch(createTask(formData, handleClose));
      }
    }
    
    else {
      setErrors({
        ...errors,
        actionName: !values.actionName.trim().length,
        category: !values.category.id,
        goal: !values.goal,
        deadline: !values.deadline,
        duration: (!values.duration.h && !values.duration.m)
      });

      if (!values.actionName.trim().length || !values.category.id || !values.goal || !values.deadline || (!values.duration.h && !values.duration.m)) {
        return;
      }

      const formData = new FormData();

      if (data) {
        formData.append("prioritized_activity_id", data.id);

        if (values.actionName !== data.action.context)
          formData.append("action_name", values.actionName.trim());

        if (values.startline && values.startline !== moment(data.action.startline))
          formData.append("startline", moment(values.startline).format('YYYY-MM-DD') ?? '');

        if (values.deadline !== moment(data.action.deadline))
          formData.append("deadline", moment(values.deadline).format('YYYY-MM-DD') ?? '');

        /* if (values.actionType !== data.action.type)
          formData.append("action_type", values.actionType ?? ''); */

        // @ts-ignore
        if (values.category.area) {
          // @ts-ignore
          formData.append("journey_name", values.category?.area?.subdomain?.domain?.name);

          // @ts-ignore
          if (values.category?.area?.name) {
            // @ts-ignore
            formData.append("area_name", values.category?.area?.name);

            // @ts-ignore
            formData.append("area_color", values.category?.area?.color);

            // @ts-ignore
            formData.append("category_name", values.category?.name);

            // @ts-ignore
            if (values.goal?.value) {
              // @ts-ignore
              formData.append("goal_id", values.goal?.value);
            }
            else {
              formData.append("goal_name", values.goal ?? '');
            }
          }
        }
        else if (values.goal !== data.goal_name) {
          // @ts-ignore
          if (values.goal?.value) {
            // @ts-ignore
            formData.append("goal_id", values.goal?.value);
          }
          else {
            formData.append("goal_name", values.goal ?? '');
          }
        }
      }
      else {
        // @ts-ignore
        formData.append("journey_name", values.category?.area?.subdomain?.domain?.name);

        // @ts-ignore
        formData.append("area_name", values.category?.area?.name);

        // @ts-ignore
        formData.append("area_color", values.category?.area?.color);

        // @ts-ignore
        formData.append("category_name", values.category?.name);

        if (values.startline)
          formData.append("startline", moment(values.startline).format('YYYY-MM-DD'));

        if (values.deadline)
          formData.append("deadline", moment(values.deadline).format('YYYY-MM-DD'));

        formData.append("action_type", type === "Action" ? "Action Item" : type);

        // @ts-ignore
        if (values.goal?.value) {
          // @ts-ignore
          formData.append("goal_id", values.goal?.value);
        }
        else {
          formData.append("goal_name", values.goal ?? '');
        }

        formData.append("action_name", values.actionName);
      }

      if (values.duration.h || values.duration.m) {
        const duration = {
          h: parseInt(values.duration.h || '0'),
          m: parseInt(values.duration.m || '0')
        };

        formData.append("estimated_duration", (duration.h * 60 + duration.m).toString());
      }

      if (values.myTime.h || values.myTime.m) {
        const myTime = {
          h: parseInt(values.myTime.h || '0'),
          m: parseInt(values.myTime.m || '0')
        };

        // Adjust duration if myTime is greater than duration
        if (myTime.h * 60 + myTime.m > (parseInt(values.duration.h || '0') * 60 + parseInt(values.duration.m || '0'))) {
          const duration = {
            h: myTime.h,
            m: myTime.m
          };

          formData.append("estimated_duration", (duration.h * 60 + duration.m).toString());
        }

        formData.append("my_time", (myTime.h * 60 + myTime.m).toString());
      }
      else {
        // @ts-ignore
        formData.append("my_time", 0);
      }

      if (data) {
        dispatch(updateAction(formData, handleClose));
      }
      else {
        dispatch(createAction(formData, handleClose));
      }
    }
  }

  if (!isRendered) {
    return null;
  }

  const title = (() => {
    switch (type as string) {
      case "Habit":
        return "Habit";
      case "Decision":
        return "Decision";
      case "Task":
        return "Task";
      case "Action":
        return "Action";
      case "Action Item":
        return "Action";
      default:
        return "";
    }
  })();

  let actionDeadline = moment(data?.deadline);

  let goalStartline = (goal?.startline || values?.goal?.startline) ? moment(goal?.startline || values?.goal?.startline) : undefined;
  let goalDeadline = (goal?.deadline || values?.goal?.deadline) ? moment(goal?.deadline || values?.goal?.deadline) : undefined;

  let minStartline = moment(goalStartline).toDate();
  let maxStartline = data?.deadline && goalDeadline
    ? moment.min(actionDeadline, goalDeadline).endOf('day')
    : (data?.deadline ? moment(data?.deadline).endOf('day') : moment(goal?.deadline).endOf('day'));

  return createPortal(
    <>
      <Wrapper $active={isActiveState}>
        <Header>
          <HeaderHeading>
            <ActionIcon />
            <HeaderTitle>{isEditMode ? "Edit" : "Create"} {title}</HeaderTitle>
          </HeaderHeading>

          <CloseBtn onClick={handleClose}>
            <CloseIcon />
          </CloseBtn>
        </Header>

        <Body>
          <Row>
            <Col>
              <Label>{type}*</Label>
              <InputArea
                value={values.actionName}
                placeholder="Enter Name..."
                error={errors.actionName}
                ref={$input}
                max={150}
                counter
                onChange={handleActionNameChange}
              />
              {errors.actionName && <ErrorLabel>This field is required</ErrorLabel>}
            </Col>
          </Row>

          {type !== ActionType.Task ? <Row>
            <Col>
              <Label>Category*</Label>
              <CategoriesSelect
                value={values.category.id}
                disabled={!values.actionName}
                status={errors.category ? "error" : ""}
                onChange={handleCategorySelect}
              />
              {errors.category && <ErrorLabel>This field is required</ErrorLabel>}
            </Col>
          </Row> : null}

          {type !== ActionType.Task ? <Row>
            <Col>
              <Label>
                Select Goal*
                <Tooltip
                  placement="rightBottom"
                  type="tertiary"
                  title=""
                  text={
                    <p style={{ maxWidth: 176, marginBottom: 0 }}>Assign your Action to related goal. If you dont have any goals created yet you can create one in Goal manager</p>
                  }
                  alternative
                  
                  // @ts-ignore
                  align={{
                    offset: [0, 2]
                  }}
                >
                  <WarningIcon />
                </Tooltip>
              </Label>

              <GoalsSelect
                value={values?.goal}
                category_name={values.category?.name}
                disabled={!values.category?.name}
                status={errors.goal ? "error" : ""}
                setIsAddGoal={setIsAddGoal}
                onChange={handleGoalSelect}
              />
              {errors.goal && <ErrorLabel>This field is required</ErrorLabel>}
            </Col>
          </Row> : null}

          <Row>
            {type !== ActionType.Task ? <Col>
              <Label>Startline</Label>
              <DatePicker
                value={values.startline}
                format={dateFormat}
                placeholder={dateFormat}
                status={errors.startline ? "error" : ""}
                inputReadOnly
                allowClear
                disabledDate={
                  (current: any) => (
                    current && (
                      (maxStartline !== undefined && current > moment(maxStartline).subtract(1, 'day').endOf('day')) || 
                      (minStartline !== undefined && current < moment(minStartline).startOf('day'))
                    )
                  )
                }
                onChange={handleDateChange('startline')}
              />
              {errors.startline && <ErrorLabel>This field is required</ErrorLabel>}
            </Col> : null}

            <Col style={{ marginBottom: errors.deadline ? '-15px' : '' }}>
              <Label>Deadline{type !== ActionType.Task ? "*" : ""}</Label>
              <DatePicker
                value={values.deadline}
                format={dateFormat}
                placeholder={dateFormat}
                status={errors.deadline ? "error" : ""}
                inputReadOnly
                disabledDate={
                  (current: any) => (
                    type !== ActionType.Task && current && (
                      (goalDeadline !== undefined && current > moment(goalDeadline).endOf('day')) || 
                      (values.startline === null ? current < moment().endOf('day') : current < moment.min(moment(values.startline).endOf('day'), moment().endOf('day')))
                    )
                  )
                }
                allowClear={type === ActionType.Task}
                onChange={handleDateChange('deadline')}
              />
              {errors.deadline && <ErrorLabel>This field is required</ErrorLabel>}
            </Col>
          </Row>

          <Row style={{marginTop:"13px"}}>
            <Col style={{ marginBottom: errors.duration ? '-19px' : '' }}>
              <div style={{ display: "flex", alignItems: "center"}}>
                <Label>Estimated Duration* </Label>
                <Tooltip
                  placement="rightBottom"
                  type="tertiary"
                  title="Estimated Weekly Duration"
                  text={
                    <p style={{ maxWidth: 169, marginBottom: 0 ,height:45, fontSize:11 }}>A How much time will it take you to complete this task weekly?</p>
                  }
                  alternative
                  // @ts-ignore
                  align={{
                    offset: [0, 2]
                  }} 
                >
                  <QuestionIcn>
                    <QuestionIcon />
                  </QuestionIcn>
                </Tooltip>
              </div>

              <Row>
                <InputField
                  /* @ts-ignore */
                  style={{ width: 70, paddingRight: 12 }}
                  value={values.duration.h}
                  error={errors.duration}
                  placeholder="-"
                  suffix={"h"}
                  onChange={handleTimeChange('duration', 'h')}
                />
                <InputField
                  /* @ts-ignore */
                  style={{ width: 70 }}
                  value={values.duration.m}
                  error={errors.duration}
                  placeholder="-"
                  suffix={"m"}
                  onChange={handleTimeChange('duration', 'm')}
                />
              </Row>
              {errors.duration && <ErrorLabel>The time is required</ErrorLabel>}
            </Col>

            {type !== ActionType.Task ? <Col>
            <div style={{display:"flex", alignItems:"center"}}>

              <Label>Delegation Overhead</Label>

              <Tooltip
                placement="rightBottom"
                type="tertiary"
                title="Delegation Overhead"
                text={
                  <p style={{ maxWidth: 169, marginBottom: 0 ,height:65, fontSize:11 }}>Enter the time it will take to delegate this task including instructions, material and access.</p>
                }
                // alternative
                
                // @ts-ignore
                align={{
                  offset: [0, 2],
                }}
                
              >
                <QuestionIcn>
                  <QuestionIcon />
                </QuestionIcn>
            </Tooltip>

            </div>
              <Row>
                <InputField
                  /* @ts-ignore */
                  style={{ width: 70, paddingRight: 12 }}
                  value={values.myTime.h}
                  placeholder="-"
                  suffix={"h"}
                  onChange={handleTimeChange('myTime', 'h')}
                />
                <InputField
                  /* @ts-ignore */
                  style={{ width: 70 }}
                  value={values.myTime.m}
                  placeholder="-"
                  suffix={"m"}
                  onChange={handleTimeChange('myTime', 'm')}
                />
              </Row>
              {/* {errors.myTime && <ErrorLabel>The time is required</ErrorLabel>} */}

            </Col> : null}
          </Row>

          {type === ActionType.Task ? <Row>
            <Col>
              <Label style={{marginTop:"20px"}}>Priority</Label>
              <Select
                style={{
                  width: "100%",
                  maxWidth: 'initial'
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                value={values.priority}
                placeholder="Select..."
                options={PriorityOptions}
                onChange={handlePriorityChange}
              />
            </Col>
          </Row> : null}

          {/* <Row>
            <Col>
              <Label>
                Action Type
                <Tooltip
                  placement="rightBottom"
                  type="tertiary"
                  title="Set the type of action:"
                  text={
                    <>
                      <p className="mb-0">Action — an activity you do once.</p>
                      <p className="mb-0">Decision — a choice you have to make.</p>
                      <p className="mb-0">Habit — an action you do repeatedly.</p>
                    </>
                  }
                  alternative
                  // @ts-ignore
                  align={{
                    offset: [0, 2]
                  }}
                >
                  <WarningIcon />
                </Tooltip>
              </Label>
              <Select
                style={{
                  width: "100%",
                  maxWidth: 'initial'
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
                value={values.actionType}
                placeholder="Select..."
                options={ActionTypes}
                onChange={handleTypeChange}
              />
            </Col>
          </Row> */}

          {/* <Row>
            <Col>
              <RemindersSection />
            </Col>
          </Row> */}
        </Body>

        <Footer>
          <Button styleType="secondary" onClick={isActiveState ? handleClose : undefined}>Cancel</Button>
          <Button disabled={!isEdited || loading} onClick={handleSubmit}>{data ? "Save" : "Add"}</Button>
        </Footer>
      </Wrapper>

      <Background
        $active={isActiveState}
        $higher={isAddGoal}
        onClick={isActiveState ? handleClose : undefined}
      />
    </>,
    document.getElementById("portal") as HTMLElement
  );
};

export default ActionItemForm;
