import React from "react";
import { Modal } from "antd";
import ArootahLogo from "assets/images/logo.png";
import styled from "styled-components";
import Button from "../Button";

const ConfirmationModal = ({
  availableCredit,
  domain,
  open,
  setOpen,
  handleStart,
}) => {
  const handleCancel = () => {
    setOpen();
  };
  return (
    <>
      <Modal
        open={open}
        footer={false}
        centered
        width={750}
        onCancel={handleCancel}
      >
        <Wrapper>
          <Logo src={ArootahLogo} alt="Arootah/" />
          <Description>
            Ready to begin? Start your <b>{domain}</b> journey by spending{" "}
            <b>1</b>
            credit. You currently have <b>{availableCredit}</b> credit(s)
            remaining.
          </Description>
          <Description>
            Keep in mind that starting a journey requires <b>1</b> credit, and
            further credits will be necessary to initiate additional journeys.
          </Description>
          <ActionButton color="white" onClick={handleStart}>
            Start
          </ActionButton>
          <CancelButton onClick={handleCancel} color="#282C31">
            Cancel
          </CancelButton>
        </Wrapper>
      </Modal>
    </>
  );
};

export default ConfirmationModal;

const Wrapper = styled.div`
  padding: 2rem 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.max("sm")`
    padding: 2rem 0rem;
  `}
`;
const Description = styled.p`
  text-align: center;
  font-family: "Inter";
  color: #4c545b;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;
const Logo = styled.img`
  margin-bottom: 2rem;
`;
const ActionButton = styled(Button)`
  background: linear-gradient(161.19deg, #1263de 18.35%, #448fff 74.45%);

  border-radius: 3px;
  color: ${({ color }) => color} !important;
  width: 100%;
  text-transform: initial;
  height: 36px;
  line-height: 36px;
  text-align: center;
  width: 196px;
  ${({ theme }) => theme.max("sm")`
    width: 100%;
  `}

  &:disabled {
    opacity: 0.5;
  }
`;
const CancelButton = styled(Button)`
  background: transparent;
  color: ${({ color }) => color};
  width: 100%;
  text-transform: initial;
  height: 36px;
  line-height: 36px;
  width: 196px;
  margin-top: 0.5rem;
  ${({ theme }) => theme.max("sm")`
    width: 100%;
  `}

  &:disabled {
    opacity: 0.5;
  }
`;
