// @ts-ignore
import { listGoals } from "../apm-apis";

import { ActionTypes } from "./type";

export const fetchGoals = (payload: string) => async (dispatch: any) => {
  try {
    if (!payload) {
      dispatch({
        type: ActionTypes.SUCCESS,
        payload: null
      });

      return;
    }

    dispatch({ type: ActionTypes.REQUEST });
    const { data } = await listGoals(payload);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });
  }
};
