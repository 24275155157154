import { Button } from "antd";
import { Accordion } from "react-bootstrap";
import styled from "styled-components";
import { ReactComponent as Upicon } from "assets/images/priorities/common/up.svg";
import { ReactComponent as Flag } from "assets/images/priorities/common/flag.svg";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  padding: ${({ $mobile }) => ($mobile ? '16px' : '0 0 0 10px;')};
  width: 100%;
  display: flex;
  gap: 4px;
  background: #ECF3F6;
  position: relative;
  height: 100%;
`;

export const List = styled.div`
  width: 100%;
  overflow-y: auto;
  padding: 4px;
  height: calc(100vh - 150px);
  user-select: none;

  .accordion-button::after{
    margin-left: 10px;
  }

  .accordion-button {
    padding: 3px !important;
    background: #EBF3F6;
    border: none;
    &:active {
      background: #EBF3F6;
    }
    &:visited {
      background: #EBF3F6;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .accordion-button:not(.collapsed){
    background: #EBF3F6;
  }

  .accordion{
    --bs-accordion-border-color: none;
  }

  .accordion-body{
    padding: 0px;
  }

  ${({ theme }) => theme.min("sm")`
    ::-webkit-scrollbar {
      width: 26px;
    }

    ::-webkit-scrollbar-track {
      background: transparent !important;
    }
    
    ::-webkit-scrollbar-thumb {
      border: 10px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #acb4bc;
    }
  `}
`;

export const EmptyListTitle = styled.div`
  font-family: Poppins;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.48px;
  text-align: left;
  color: #798B99;
  text-align: center;
`;

export const EmptyListSubTitle = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #798B99;
  text-align: center;
  margin:2px
`;

export const MainTitle = styled.div`
  color: var(--Fill-Button-Prussian-blue, #112538);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const BoxIconBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;
  padding: 0;
  transform: rotate(180deg);
  transition: transform .25s;

  ${({ $active }) => $active && `
    transform: rotate(0);
  `};
`;

/* export const CustomBtn = styled.p`
  width: ${({ $mobile }) => ($mobile ? '300px' : '178px')};
  height: 32px;
  padding: 6px 12px 6px 12px;
  gap: 6px;
  border-radius: 2px 0px 0px 0px;
  opacity: 0px;
  color:white;
  background: #FF9900;
  font-family:inter;
  Line-height:20px;
  display:flex;
  justify-content: center;
  cursor: pointer;
`; */
/* 
export const CustomLink = styled(Link)`
  width: 183px;
  height: 32px;
  gap: 6px;
  background: transparent;
  color: #1271A6;
  text-decoration: none;
`; */

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  gap: 14px;
  justify-content: center;
  padding:${({ $mobile }) => ($mobile ? '30px' : '0px')};
  
`;

export const CustomAccordion = styled(Accordion)`
  position: relative;

  .accordion-button::after {
    display: none;
  }

  .accordion-button:not(.collapsed)::after {
    display: none;
  }
`;

export const CustomAccordionItem = styled(CustomAccordion.Item)`
  background-color: transparent;
`;

export const SingleGroupView = styled.div`
  // display: flex;
  // flex-direction: column;
  padding: 4px;
  background: #fff;
  // position: relative;
`;

/* export const CustomUpicon = styled(Upicon)`
    margin-left: 2px;
    &:hover{
      cursor: pointer;
    }
    transform: rotate(0deg);
    transition: transform .5s;
  ${({$activeKey1}) => $activeKey1 && `
    transform: rotate(180deg);
    transition: transform .5s;
  `}
  ${({$activeKey2}) => $activeKey2 && `
    transform: rotate(180deg);
    transition: transform .5s;
  `}
`;
 */
/* export const CustomFlag = styled(Flag)`
  margin-left: 5px;
`; */

export const Box = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 6px;
  margin-bottom: 6px;
`;

export const customPlus = styled.div`
  color:red;
`;

/* export const Circlee = styled.div`
  position: fixed;
  right: 10px;
  top:  ${({ height }) => (height ? `${height - 100}px` : '0px')};
`; */
