import styled from "styled-components";

export const NoGoals = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 8vh 20px;
`;

export const NoGoalsText = styled.span`
  color: var(--Neutral-500, #737373);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
`;

export const AddGoalOption = styled.button`
  padding: 6px 10px;
  background: transparent;
  border: none;
  color: var(--primary-500-main, #1271A6);
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
`;
