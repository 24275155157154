import { getMissionStatement } from "api";

import { Error } from "Responses/Response";
import { ActionTypes } from "./types";

export const GetMissionStatement = (goalId) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data } = await getMissionStatement(goalId);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data[0],
    });
  } catch (error) {
    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};

export const SetMissionStatement = (payload) => async (dispatch) => {
  dispatch({
    type: ActionTypes.SUCCESS,
    payload,
  });
};
