import { updateLastStep } from "api";
import { ListDomain } from "Redux/ListDomains/action";
import { Error } from "Responses/Response";
import { getCookie } from "utils/StorageVariables";
import { ActionTypes } from "./types";

export const UpdateLastStep =
  (formData, isRefresh = false, masterplanId = false) =>
  async (dispatch) => {
    try {
      let MasterPlanId = getCookie("MasterPlanId");
      dispatch({ type: ActionTypes.LOADING });
      const { data, status } = await updateLastStep(
        formData,
        masterplanId ? masterplanId : MasterPlanId
      );
      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data,
      });
      if (status === 200 && isRefresh) {
        dispatch(ListDomain());
      }
    } catch (error) {
      Error(error?.response?.data?.error);
      dispatch({
        type: ActionTypes.ERROR,
        payload: error,
      });
    }
  };
