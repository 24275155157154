import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

import Button from '../../components/Button';
import { fadeInUpMixin } from './animated';

const circleAreaMove = keyframes`
  0% {
    transform: translateX(120px) translateY(-120px) scale(1.2);
  }
  100% {
    transform: translateX(0) translateY(-0) scale(1);
  }
`;

export const Wrapper = styled.div`
  height: 100vh;
`;

export const Screen = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8vh 0;
  min-height: calc(100vh - 76px);
  position: relative;

  ${({ $withFooter }) => $withFooter && css`
    min-height: calc(100vh - 76px - 50px);
  `}

  ${({ $terms }) => $terms && css`
    padding: 0;
  `}

  ${({ theme }) => theme.max('lg')`
    flex-direction: column-reverse;
    min-height: calc(100vh - 50px);

    ${({ $landing }) => $landing && css`
      padding: 0;
      justify-content: center;
    `}
  `}

  ${({ theme }) => theme.max('sm')`
    justify-content: center;
    padding: 4vh 16px;

    ${({ $signup }) => $signup && css`
      justify-content: space-between;
    `}

    ${({ $terms }) => $terms && css`
      padding: 0;
    `}
  `}
`;

export const Back = styled(Link)`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 24px;
  margin-left: 44px;
  color: var(--denim, #1271A6);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  text-decoration: none;

  ${({ $alternative }) => $alternative && css`
    ${({ theme }) => theme.max('lg')`
      margin-right: 44px;
      right: 0;
      left: initial;
      margin-top: 58px;
      z-index: 9;
    `}

    ${({ theme }) => theme.max('sm')`
      display: none;
    `}
  `}

  svg {
    margin-right: 4px;
    position: relative;
    top: 1px;
  }
`;

export const Column = styled.div`
  display: flex;
  align-items: center;

  ${({ $first }) => $first && css`
    width: 45%;
    justify-content: center;

    ${({ theme }) => theme.max('lg')`
      width: 100%;
    `}
  `}

  ${({ $second }) => $second && css`
    width: 70%;
    left: 33%;
    position: absolute;
    overflow: hidden;

    ${({ theme }) => theme.max('lg')`
      justify-content: center;
      width: 100%;
      left: 0;
      position: relative;
    `}
  `}

  ${({ $fullHeight }) => $fullHeight && css`
    height: 100%;
  `}

  ${({ $text }) => $text && css`
    width: 55%;
    overflow: hidden;
    left: 45%;
    top: 50%;
    margin-top: -100px;

    ${({ theme }) => theme.max('lg')`
      margin-top: 0;
      margin-bottom: 8vh;
      top: 0;
    `}
  `}

  ${({ $middle }) => $middle && css`
    width: 100%;
    justify-content: center;
  `}
`;

export const Content = styled.div`
  height: calc(100vh - 76px);
  overflow-y: auto;
  position: relative;

  ${({ theme }) => theme.max('lg')`
    height: calc(100vh - 50px);
  `}
`;

export const Footer = styled.div`
  position: absolute;
  bottom: -50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  gap: 5px;

  figure {
    width: 5px;
    height: 5px;
    margin: 0;
    border-radius: 50%;
    background-color: var(--Denim, #1271A6);
  }
`;

export const FooterLink = styled(Link)`
  color: var(--Denim, #1271A6);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.15px;
`;

export const Background = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    background: #85C4C9;
    opacity: 0.1;
    border-radius: 50%;
    width: 40vw;
    height: 40vw;
    transform: translateX(120px) translateY(-120px) scale(1.2);
  }

  ${({ $animate }) => $animate && css`
    &::before {
      animation: ${circleAreaMove} 0.8s cubic-bezier(0.80, 0.01, 0.05, 1) forwards;
    }
  `}
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 340px;
  padding: 0 20px;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 12px;
  color: rgba(#000, 0.87);
  text-align: center;
  ${fadeInUpMixin}
`;

export const Text = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #4B545B;
  margin-bottom: 45px;
`;

export const AreaButton = styled.button`
  background: #ECF3F7;
  border-radius: 2px;
  border: none;
  padding: 10px 0;
  color: #1271A6;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
  width: 100%;
  transition: all .3s;

  ${({ $active }) => $active && css`
    background: #1271A6;
    color: #fff;
  `}
`;

export const Next = styled(Link)`
  color: #4B545B;
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
`;

export const Submit = styled(Button)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  height: 40px;
  text-transform: initial;
  background: #1271A6;
  font-weight: 500;
  border-radius: 2px;
  color: #4B545B;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  transition: opacity .3s;

  &:disabled {
    opacity: 0.5;
  }
`;

export const SocialAuth = styled(Button)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  text-transform: initial;
  font-weight: 500;
  background: transparent;
  padding: 10px 16px;
  border: 1px solid #E5E5E5;
  border-radius: 2px;
  height: 40px;
  color: #525252;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  ${fadeInUpMixin};

  img {
    margin-right: 6px;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 34px;
  max-width: 40vw;
`;

export const Div = styled.div`
  ${({ $mb }) => $mb && css`
    margin-bottom ${$mb}px;
  `}
`;

export const SocialAuthWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Disclaimer = styled.span`
  color: #4B545B;
  font-weight: 400;
  font-size: 10px;
  line-height: 113.5%;
`;

export const Or = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  margin-top: 12px;

  span {
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    font-family: ${({ theme }) => theme.fonts.secondary};
    color: #A3A3A3;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    padding: 0 13px;
    background: #fff;
    z-index: 1;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #CDCDCD;
  }
`;

export const Linkbox = styled.div`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 12px;
  line-height: 20px;
  color: #1B2A3D;

  a {
    margin-left: 7px;
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    color: #1271A6;
  }
`;

export const AreaIconsList = styled.div`
  display: flex;
`;

// Define the keyframes
const easeIn = keyframes`
  0% {
    transform: translateY(5px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Area = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: -6px;
  position: relative;
  transform: translateY(5px);
  opacity: 0;

  ${({ $color }) => $color && css`
    background: ${$color};
  `}

  ${({ $index, $animate }) => $index && $animate && css`
    z-index: ${10-$index};
    animation: ${easeIn} 0.4s cubic-bezier(0.80, 0.01, 0.05, 1) forwards ${(10-$index)*50}ms;
  `}
`;

export const TypeTitle = styled.span`
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.15px;
  font-weight: 700;
  font-size: 40px;
  line-height: 160%;
  margin-bottom: 16px;
  transform: translateY(5px);
  opacity: 0;

  ${({ $animate }) => $animate && css`
    animation: ${easeIn} 0.4s ease-out forwards 200ms;
  `}
`;

export const TypeText = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #06203A;
  transform: translateY(5px);
  opacity: 0;

  ${({ $animate }) => $animate && css`
    animation: ${easeIn} 0.4s ease-out forwards 400ms;
  `}
`;
