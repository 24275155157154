import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";

  interface RootState {
    loading: boolean;
    listAreaReducer: { loading: boolean };
    listCategoryReducer: { loading: boolean };
    selectAreaAndCategoriesReducer: { loading: boolean };
    domainListReducer: { loading: boolean };
    deleteUserAreaReducer: { loading: boolean };
    deleteActionReducer: { loading: boolean };
    accountabilityReducer: { loading: boolean };
    createActionReducer: { loading: boolean };
    prioritizeActionPlansReducer: { loading: boolean };

    // APM
    listFocusedPrioritise: { loading: boolean };
    unprioritizedReducer: { loading: boolean };
    RetrieveMasterPlanDetailsReducer: { loading: boolean };
    updateTimeLine: { loading: boolean };
    weeklyDuration: { loading: boolean };
    prioritizedActions: { loading: boolean };
    createPrioritizedActivities: { loading: boolean };
    availableAreas: { loading: boolean };
    updateIndirectScores: { loading: boolean };
    updateFocusedPriorities: { loading: boolean };
    prioritizedtoFocused: { loading: boolean };
    createAction: { loading: boolean };
    createTask: { loading: boolean };
  }


const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #00000049;
  z-index: 1100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 9999999;
`;

const Loading: React.FC = () => {
  const { loading: listArealoading } = useSelector(
    (state:RootState) => state.listAreaReducer
  );
  const { loading: listCategoryLoading } = useSelector(
    (state:RootState) => state.listCategoryReducer
  );
  const { loading: selectAreaAndCategoriesLoading } = useSelector(
    (state:RootState) => state.selectAreaAndCategoriesReducer
  );
  const { loading: masterPlanDetailsLoading } = useSelector(
    (state:RootState) => state.RetrieveMasterPlanDetailsReducer
  );
  const { loading: domainListLoading } = useSelector(
    (state:RootState) => state.domainListReducer
  );
  const { loading: deleteUserAreaLoading } = useSelector(
    (state:RootState) => state.deleteUserAreaReducer
  );
  const { loading: deleteActionLoading } = useSelector(
    (state:RootState) => state.deleteActionReducer
  );
  const { loading: listAccountabilityLoading } = useSelector(
    (state:RootState) => state.accountabilityReducer
  );
  const { loading: createActionItemLoading } = useSelector(
    (state:RootState) => state.createActionReducer
  );
  const { loading: prioritizeActionItemsLoading } = useSelector(
    (state:RootState) => state.prioritizeActionPlansReducer
  );

  //apm loader
  const { loading: listFocusedPrioritise } = useSelector(
    (state:RootState) => state.listFocusedPrioritise
  );
  const { loading: unprioritizedReducer } = useSelector(
    (state:RootState) => state.unprioritizedReducer
  );
  const { loading: updateTimeLine } = useSelector(
    (state:RootState) => state.updateTimeLine
  );
  const { loading: weeklyDuration } = useSelector(
    (state:RootState) => state.weeklyDuration
  );
  const { loading: prioritizedActions } = useSelector(
    (state:RootState) => state.prioritizedActions
  );
  const { loading: createPrioritizedActivities } = useSelector(
    (state:RootState) => state.createPrioritizedActivities
  );
  const { loading: availableAreas } = useSelector(
    (state:RootState) => state.availableAreas
  );
  const { loading: updateIndirectScores } = useSelector(
    (state:RootState) => state.updateIndirectScores
  );
  const { loading: updateFocusedPriorities } = useSelector(
    (state:RootState) => state.updateFocusedPriorities
  );
  const { loading: prioritizedtoFocused } = useSelector(
    (state:RootState) => state.prioritizedtoFocused
  );
  const { loading: createAction } = useSelector(
    (state:RootState) => state.createAction
  );
  const { loading: createTask } = useSelector(
    (state:RootState) => state.createTask
  );

  const isLoading = [
    listAccountabilityLoading,
    listCategoryLoading,
    listArealoading,
    selectAreaAndCategoriesLoading,
    masterPlanDetailsLoading,
    domainListLoading,
    deleteUserAreaLoading,
    deleteActionLoading,
    createActionItemLoading,
    prioritizeActionItemsLoading,

    //apm loader
    listFocusedPrioritise,
    unprioritizedReducer,
    updateTimeLine,
    weeklyDuration,
    prioritizedActions,
    createPrioritizedActivities,
    availableAreas,
    updateIndirectScores,
    updateFocusedPriorities,
    prioritizedtoFocused,
    createAction,
    createTask
  ];

  return (
    <>
      {isLoading?.includes(true) && (
        <LoaderContainer>
          <Oval
            height={100}
            width={100}
            color="#FF9900"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#ffd499"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </LoaderContainer>
      )}
    </>
  );
};

export default Loading;
