import React, { useEffect, useMemo, useRef } from 'react';
import { uid } from 'uid';
import * as d3 from 'd3';

import { Wrapper } from './styled';

interface CircleBarProps {
  value: number;
  icon?: React.ReactNode;
}

const getUniqueId = (): string => {
  return 'gradient_' + uid();
}

const getGradientMap = (value: number): { start: string; end: string } => {
  if (value > 75)
    return { start: '#37BD5D', end: '#A1DE65' };
  else if (value > 50)
    return { start: '#00A3FF', end: '#A1DE65' };
  else if (value > 25)
    return { start: '#FF6B00', end: '#E4D337' };
  else if (value > 0)
    return { start: '#FF0000', end: '#EB6B23' };
  else
    return { start: '#fff', end: '#fff' }
}

const CircleBar: React.FC<CircleBarProps> = ({ value, icon }) => {
  const $wrapper = useRef<SVGSVGElement>(null);
  const dataValue = value <= 100 ? value : 0;

  const width = 92;
  const height = 92; // Update the height to match the width for a full circle
  const strokeWidth = 14 / 2;

  // Shift the start point by +90 degrees and convert to radians
  const startAngle = (Math.PI / 2);
  const endAngle = (Math.PI / 2) + (2 * Math.PI * (dataValue / 100)); // Clockwise direction

  const innerRadius = (Math.max(width, height) - strokeWidth * 2) / 2 - strokeWidth;
  const outerRadius = (Math.max(width, height) - strokeWidth * 2) / 2;

  const gradientMap = getGradientMap(dataValue);
  const gradientId = useMemo(() => getUniqueId(), [dataValue]);

  useEffect(() => {
    let svg;

    if ($wrapper.current) {
      svg = d3.select($wrapper.current);
    }

    svg && svg.selectAll('*').remove();

    // Define gradient for the radial bar
    const gradient = svg && svg
      .append('defs')
      .append('linearGradient')
      .attr('id', gradientId)
      .attr('x1', '0%')
      .attr('y1', '100%')
      .attr('x2', '100%')
      .attr('y2', '100%');

    gradient && gradient
      .append('stop')
      .attr('offset', '0%')
      .attr('stop-color', gradientMap.start)
      .attr('stop-opacity', 1);

    gradient && gradient
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', gradientMap.end)
      .attr('stop-opacity', 1);

    // Create arc generator for the background
    //@ts-ignore
    const backgroundArc = d3.arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .startAngle(0)
      .endAngle(2 * Math.PI); // Full circle

    // Create arc generator for the foreground (value)
    //@ts-ignore
    const foregroundArc = d3.arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .startAngle(startAngle)
      .endAngle(endAngle);

    // Draw background arc
    svg && svg
      .append('path')
      .attr('d', backgroundArc)
      .attr('transform', `translate(${width / 2}, ${height / 2})`) // Centered transform
      .attr('fill', 'none')
      .attr('stroke', '#ddd')
      .attr('stroke-width', strokeWidth);

    // Draw foreground arc
    svg && svg
      .append('path')
      .attr('d', foregroundArc)
      .attr('transform', `translate(${width / 2}, ${height / 2})`) // Centered transform
      .attr('fill', 'none')
      .attr('stroke', `url(#${gradientId})`)
      .attr('stroke-width', strokeWidth);
    }, [dataValue, gradientId]);

  return (
      <Wrapper width={width} height={height}>
        <svg ref={$wrapper} width={width} height={height} />
        <span>{value}%</span>
      </Wrapper>
  );
};

export default CircleBar;
