import React from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";

import sidebarCloseIcon from "assets/images/journeys/sidebar-close.svg";

import {
  ToggleSidebarAction,
} from "Redux/Global/action";

import {
  Wrapper,
  Content,
  Head,
  Title,
  Toggler,

  // Hr,

  List
} from './styled';

const Sidebar = ({ title, secondary, withoutToggle, children }) => {
  const dispatch = useDispatch();

  const { toggledSidebar } = useSelector((state) => state.globalReducer);

  const onTogglerClickHandle = () => {
    dispatch(ToggleSidebarAction(toggledSidebar === 'left' ? '' : 'left'));
  };

  return (
    <Wrapper $active={toggledSidebar === "left"}>
      <Content>
        <Head>
          <Title>{title}</Title>
          {!withoutToggle && <Toggler type="button" onClick={onTogglerClickHandle}>
            <img src={sidebarCloseIcon} alt="" />
          </Toggler>}
        </Head>

        {/* {!secondary && <Hr />} */}

        <List $secondary={secondary}>
          {children}
        </List>
      </Content>
    </Wrapper>
  );
}

Sidebar.propTypes = {
  title: PropTypes.string.isRequired,
  secondary: PropTypes.bool,
  withoutToggle: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default Sidebar;
