import React, { useState } from "react";
import styled from "styled-components";
import PopoverCustom from "../PopoverCustom";
import DirectImpacts from "components/PriorityApp/DirectImpactPopover";

//@ts-ignore
import { ReactComponent as QuestionMarkIcon } from "assets/images/priorities/question-mark.svg";

import InDirectImpact from "components/PriorityApp/IndirectImpactPopover";

import { AreaData } from "../PriorityEdit/Impact";

interface ImpactIndicatorProps {
  direct_impact: number;
  indirect_impacts: number;
  direct_impact_value: string;
  indirect_impacts_value: string;
  indirect_impacts_editable?: any;
  color: { color: string }[];
  onLeverageClose: (areaData: AreaData[]) => void;
  selectedItem: any;
  id: any;
}

const IndicatorContainer = styled.div`
  position: relative;
  width: 100%;
  height: 45px; // Height can be adjusted as needed
  margin-top: 4px; // Space between the bar and the indicator
  border-bottom: 0.5px solid #dedede; // Color of the indicator line
  border-left: 0.5px solid #dedede; // Color of the indicator line
  border-right: 0.5px solid #dedede;
  border-radius: 0 0 4px 4px;
  margin-bottom: 10px;
`;

const Label = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  top: 100%; // Position the label below the line
  transform: translateX(-50%) translateY(-21px); // Position the label below the line
  font-size: 0.75rem; // Label font size, adjust as needed
  font-weight: 600;
`;

const DirectImpact = styled.div<{ directImpact: number }>`
  height: 20px;
  margin-right: 1px;
  border-radius: 4px 4px 0 0;
  background-color: #418db8;
  width: ${({ directImpact }) => `${directImpact}%`};
  transition: width 0.5s ease;
`;

const IndirectImpactContainer = styled.div<{ directImpact: number }>`
  height: 20px;
  display: flex;
  gap: 1px;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  width: ${({ directImpact }) => `${100 - directImpact}%`};
`;

const IndirectImpactSegment = styled.div<{
  value: number;
  indirectTotal: number;
  color: string;
}>`
  height: 100%;
  background-color: ${({ color }) => color};
  width: ${({ value, indirectTotal }) => `${(value / indirectTotal) * 100}%`};
  transition: width 0.5s ease;
`;

export const TitleText = styled.span`
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  margin-right: 4px;

  span {
    font-family: Inter;
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #1b2a3d;
  }
`;

export const DirectImpactStyle = styled.span`
  display: flex;
  align-items: baseline;
`;

export const IndirectImpactStyle = styled.span`
  display: flex;
  align-items: baseline;
`;

const ImpactIndicator: React.FC<ImpactIndicatorProps> = ({
  direct_impact,
  onLeverageClose,
  indirect_impacts,
  color,
  selectedItem,
  id,
  direct_impact_value,
  indirect_impacts_value,
  indirect_impacts_editable,
}) => {
  const [hoveredComponent, setHoveredComponent] = useState<string>("");
  /* const [close, setClose] = useState<boolean>();
  const [refetch, setRefetch] = useState<boolean>(false);
  const [areaData, setAreaData] = useState<AreaData[]>([]);  */

  const handleMouseEnter = (componentName: string) => {
    setHoveredComponent(componentName);
  };

  const handleMouseLeave = () => {
    setHoveredComponent("");
  };

  return (
    <>
      <DirectImpactStyle>
        <TitleText>
          <span>Direct Impact</span>
        </TitleText>
        <div
          onMouseEnter={() => handleMouseEnter("direct")}
          onMouseLeave={handleMouseLeave}
        >
          <PopoverCustom
            content={<DirectImpacts />}
            popoverStyle={{
              arrowStyle: {
                top: "90px",
                left: "-3px",
                zIndex: 9999,
                rotate: "270deg",
              },
              containerStyle: { top: "-114px", left: "190px" },
            }}
            hover={hoveredComponent === "direct"}
          >
            <QuestionMarkIcon />
          </PopoverCustom>
        </div>
      </DirectImpactStyle>

      <IndicatorContainer>
        <DirectImpact directImpact={100} />
        <Label style={{ left: "50%" }}>{direct_impact_value}</Label>
      </IndicatorContainer>

      <IndirectImpactStyle>
        <TitleText>
          <span>Leverage</span> (Indirect Impact)
        </TitleText>
        <div
          onMouseEnter={() => handleMouseEnter("indirect")}
          onMouseLeave={handleMouseLeave}
        >
          <PopoverCustom
            content={<InDirectImpact />}
            positions={["top"]}
            popoverStyle={{
              arrowStyle: {
                top: "94px",
                left: "7px",
                zIndex: 9999,
                rotate: "90deg",
              },
              containerStyle: { top: "36px", left: "173px" },
            }}
            hover={hoveredComponent === "indirect"}
          >
            <QuestionMarkIcon />
          </PopoverCustom>
        </div>
      </IndirectImpactStyle>
      <IndicatorContainer>
        <IndirectImpactContainer directImpact={0}>
          {color?.map((impact, index) => (
            <IndirectImpactSegment
              key={index}
              value={100}
              indirectTotal={indirect_impacts}
              color={impact?.color}
              data-testid="indirect-impact-container"
            />
          ))}
        </IndirectImpactContainer>

        <Label style={{ left: `50%` }}>
          {indirect_impacts_value}
          {indirect_impacts_editable}
        </Label>
      </IndicatorContainer>
    </>
  );
};

export default ImpactIndicator;
