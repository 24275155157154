import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Footer from "components/Footer";
import { ThemeProvider } from "styled-components";
import Loading from "components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "utils/StorageVariables";
import { IsUser } from "Redux/User/action";
import { RetrieveMasterPlanDetails } from "Redux/RetrieveMasterPlan/action";
import SchedulerSideNav from "components/Scheduler/SideNav";
import { MobilePlaceholder } from "components/MobilePlaceholder";
import { useThemeMode } from "Hooks/themeColor";
import useWindowSize from "utils/useWindowSize";

import { Wrapper, PagesWrapper, PageLayout } from './styled';
import useDeviceType from "../../Hooks/ResponsiveHook";

const Scheduler = () => {
  const dispatch = useDispatch();

  const { userDetail } = useSelector((state) => state.userDetailReducer);
  const { colorTheme } = useThemeMode();
  const { width } = useWindowSize();
  const screenSize = useDeviceType()

  useEffect(() => {
    let MasterPlanId = getCookie("MasterPlanId");
    if (!MasterPlanId) return;
    dispatch(RetrieveMasterPlanDetails(MasterPlanId));
  }, []);

  useEffect(() => {
    if (userDetail === null) {
      let userData = JSON.parse(getCookie("userDetail"));
      dispatch(IsUser(userData));
    }
  }, [userDetail]);

  return (
    <ThemeProvider theme={colorTheme} isLight={false}>
      <Wrapper>
        <Loading />
         {screenSize === "desktop" && <SchedulerSideNav />}
        <PagesWrapper $mobile={screenSize !== "desktop"}>
          <PageLayout>
            <Outlet />
          </PageLayout>
        </PagesWrapper>
      </Wrapper>
      {/* <Footer apm={true} /> */}
    </ThemeProvider>
  );
};

export default Scheduler;
