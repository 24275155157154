import { Tooltip } from 'elements';
import { TypeChar } from './styled';

interface TypeBadgeProps {
  type?: string;
  width?: number | undefined;
  height?: number | undefined;
}

const TypeBadge: React.FC<TypeBadgeProps> = ({ type, width, height }) => {
  return type && type[0] ? (
    // <Tooltip title={type}>
      <TypeChar className='typeBadge' width={width?width:0} height={height?height:0}>{type[0].toUpperCase()}</TypeChar>
    // </Tooltip>
  ) : null;
}

export default TypeBadge;
