import {
    listCompleteFocusedPrioritized,
    listFocusedPrioritized,
    removeFocusedPriority as removeFocusedPriorityAPI,
    removeFocusedPriorities as removeFocusedPrioritiesAPI
} from "../apm-apis";

import { ActionTypes } from "./type";
import Notify from "utils/notify";

import store from "Redux/store";

export const listFocusedPrioritise = (options = { loader: true }) => async (dispatch) => {
    const state = store.getState();
    const startDate = state.listFocusedPrioritise.week.startDate || null;

    try {
        if (options.loader)
            dispatch({ type: ActionTypes.FETCH_FOCUSED_PRIORITISE_REQUEST });

        const { data } = await listFocusedPrioritized(startDate);

        dispatch({
            type: ActionTypes.FETCH_FOCUSED_PRIORITISE_SUCCESS,
            payload: data
        });

    } catch (error) {
        Notify.error(error, true);
        dispatch({
            type: ActionTypes.FETCH_FOCUSED_PRIORITISE_FAILURE,
            payload: error
        });
    }
};

export const listCompleteFocusedPrioritise = (options = { loader: true }) => async (dispatch) => {
    const state = store.getState();
    const startDate = state.listFocusedPrioritise.week.startDate || null;

    try {
        if (options.loader)
            dispatch({ type: ActionTypes.FETCH_COMPLETE_FOCUSED_PRIORITISE_REQUEST });

        const { data } = await listCompleteFocusedPrioritized(startDate);
        dispatch({
            type: ActionTypes.FETCH_COMPLETE_FOCUSED_PRIORITISE_SUCCESS,
            payload: data
        });

    } catch (error) {
        Notify.error(error, true);
        dispatch({
            type: ActionTypes.FETCH_FOCUSED_PRIORITISE_FAILURE,
            payload: error
        });
    }
};

export const removeFocusedPriority = (focusedPriorityId) => async (dispatch) => {
    try {

        dispatch({ type: ActionTypes.REMOVE_FOCUSED_PRIORITISE_REQUEST, payload: { focusedPriorityId } });
        const { data } = await removeFocusedPriorityAPI(focusedPriorityId);
        dispatch({
            type: ActionTypes.REMOVE_FOCUSED_PRIORITISE_SUCCESS,
            payload: data
        });
        dispatch(listCompleteFocusedPrioritise());
    } catch (error) {
        Notify.error(error, true);
        dispatch({
            type: ActionTypes.REMOVE_FOCUSED_PRIORITISE_FAILURE,
            payload: error
        });
    }
}

export const removeFocusedPriorities = (focusedPriorityIds, callback) => async (dispatch) => {
    try {
        const { data } = await removeFocusedPrioritiesAPI(focusedPriorityIds);

        dispatch({
            type: ActionTypes.REMOVE_FOCUSED_PRIORITISE_SUCCESS,
            payload: data
        });

        if (callback) {
            callback();
        }
    } catch (error) {
        Notify.error(error, true);
        dispatch({
            type: ActionTypes.REMOVE_FOCUSED_PRIORITISE_FAILURE,
            payload: error
        });
    }
}

export const setWeek = payload => async dispatch => {
    dispatch({
        type: ActionTypes.SET_WEEK_FOCUSED_PRIORITISE,
        payload
    });
}
