import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Formik } from "formik";
import * as yup from "yup";

import * as Layout from '../styled';
import * as Styled from './styled';

// import { forgotPassword, resetState } from '../../../modules/actions/AuthActions';
import { ErrorFocus } from '../../../utils/ErrorFocus';

import { SIGN_IN_PATH } from 'utils/routes';
import { PasswordResetLink } from 'Redux/PasswordResetLink/action';
import { getAppBaseURL } from 'utils/utility';

const schema = yup.object().shape({
  email: yup.string().required('Email is required')
});

const initialValues = {
  email: ''
}

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const $form = useRef(null);

  const [animate, setAnimate] = useState(false);

  const { loading } = useSelector(
    (state) => state.resetPasswordLinkReducer
  );

  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 100)
  }, []); 

  /* useEffect(() => {
    if (!appParam || (appParam !== 'pp' && appParam !== 'dm')) {
      navigate('/');
    }
  }, [appParam]); */

  const onNavigate = () => {
    navigate(`/forgot-password/success`, { state: { type: true, email: $form.current.values.email } });
  }

  const onSubmitHandle = form => {
    const url = getAppBaseURL();
    dispatch(PasswordResetLink({ ...form, url: `https://${url}` }, onNavigate));
  }

  return (
    <Layout.Screen>
      <Layout.Column $middle>
        <Styled.FormWrapper>
          <Layout.Title $animate={animate} $animateDelay={100}>Reset your password</Layout.Title>
          <Styled.FormText $animate={animate} $animateDelay={150}>First, let’s find your account.</Styled.FormText>

          <Formik
            validationSchema={schema}
            onSubmit={onSubmitHandle}
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={true}
            innerRef={$form}
          >
            {({ handleSubmit, handleReset, handleChange, values, errors }) => (
              <Styled.FormStyled noValidate onSubmit={handleSubmit}>
                <Styled.FormGroup $animate={animate} $animateDelay={200}>
                  <Styled.Label className="authLabel">Email*</Styled.Label>
                  <div className="flex-grow-1 position-relative">
                    <Styled.TextBoxs
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={errors.email}
                      aria-label="Email"
                    />
                    <Styled.FormError>
                      {errors.email}
                    </Styled.FormError>
                  </div>
                </Styled.FormGroup>

                <Styled.SubmitWrapper $animate={animate} $animateDelay={250}>
                  <Styled.SubmitStyled
                    type="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    Continue
                  </Styled.SubmitStyled>
                </Styled.SubmitWrapper>

                <ErrorFocus />
              </Styled.FormStyled>
            )}
          </Formik>

          <Styled.LinkboxStyled $animate={animate} $animateDelay={300}>
            <Link to={`${SIGN_IN_PATH}`}>Cancel</Link>
          </Styled.LinkboxStyled>
        </Styled.FormWrapper>
      </Layout.Column>
    </Layout.Screen>
  );
}

export default ForgotPassword;
