import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 6px;
`;

export const Body = styled.div`
  padding: 3px 4px;
  display: flex;
  justify-content: space-between;
`;

export const RightBar = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;

  span{
    width: 50%;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #1B2A3D;
  }
`;

export const EmptyBox = styled.div`
  width: 73px;
`;

export const LeftBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  border-right: 0.5px solid #CED0D4;
  width: 50%;
  margin-left: 10px;

  span{
    color: red;
  }
`;


export const AreaHeader = styled.span`
  display: flex;
  justify-content: space-between;
`;

export const LeverageButton = styled.div`
  position: absolute;
  left: 70%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 2px;

  path {
    stroke-width: 1.5;
    fill: #3D464D;
    stroke: #fff;
  }

  svg {
    height: 20px !important;
  }
`;

export const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const List = styled.div`
  height: 21px;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1px;
`;

export const AreaBox = styled.div`
  position: relative;
  margin-top: 8px;
`;

interface AreaProps {
  $color?: string;
}

export const Area = styled.div<AreaProps>`
  flex: 1;
  height: 100%;
  background-color: #ECEEF1;

  ${({ $color }) => $color && `
    background-color: ${$color};
  `};
`;

export const Score = styled.span`
  width: 100%;
  text-align: center;
  color: rgba(#1B2A3D, 0.8);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-transform: capitalize;
  padding: 3px 0;
  margin-top: 2px;
`;

export const DirectImpactStyle = styled.span`
  display: flex;
  align-items: baseline;
`;

export const IndirectImpactStyle = styled.span`
  display: flex;
  align-items: baseline;
`;
