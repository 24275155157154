import styled, { css } from 'styled-components';

import { Avatar } from "antd";
import Button from "components/Button";

export const Container = styled.div`
  width: 100%;
`;

export const TableHeadCol = styled.div`
  color: var(--neutral-500, var(--Input-header, #737373));
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;

  ${({ $width }) => $width && css`
    width: ${$width};
  `}

  ${({ $ml }) => $ml && css`
    margin-left: ${$ml};
  `}

  ${({ $flex }) => $flex && css`
    flex: ${$flex || '1'};
  `}
`;

export const Heading = styled.h1`
  color: #06203a;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  vertical-align: middle;
  font-size: 18px;
  line-height: 24px;
  margin: 0;

  ${({ theme }) => theme.max("sm")`
    font-size: 14px;
  `}
`;

export const NextButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;

export const ContainerItem = styled.div`
  width: 100%;
  height: calc(100% - 67px - 59px - 46px);
`;

export const TableContainer = styled.div`
  padding: 10px;
  width: 100%;
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;

  ${({ theme }) => theme.min("sm")`
    ::-webkit-scrollbar {
      width: 11px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #71aaca;
      border-radius: 10px;
      background: #71aaca;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #116595;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #116595;
    }
  `}
`;

export const Row = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  border-left: ${({ border }) => `4px solid ${border}`};
  margin-bottom: 4px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.17);
  position: relative;
  padding: 8px 16px 8px 12px;

  ${({ theme }) => theme.max("sm")`
    flex-wrap: nowrap;
    flex-direction: column;
  `}
`;

export const LeftSection = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 17vw;
  padding-right: 20px;
  overflow: hidden;
`;

export const Text = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #000000;

  ${({ $area }) => $area && css`
    display: initial;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #525252;

    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `}

  ${({ $category }) => $category && css`
    display: initial;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    color: #171717;

    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `}

  ${({ $goal }) => $goal && css`
    color: #171717;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    word-break: break-word;
  `}

  @media screen and (max-width: 1439px) {
    font-size: ${({ tablet }) => tablet && "12px"};
  }

  @media screen and (max-width: 540px) {
    max-width: 100%;
    font-size: 12px;
    font-weight: ${({ isMob }) => (isMob ? "500" : "600")};
    line-height: 20px;
  }
`;

export const MidSection = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  gap: 12px;

  @media screen and (max-width: 540px) {
    padding: 0rem;
    width: 100%;
    white-space: pre-wrap;
  }
`;

export const Input = styled.input`
  width: 20rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  background-color: white;
  padding: 0.56rem 0.5rem;
  border: none;
`;

export const HeaderText = styled.h3`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #fff;
  margin: 0;
`;

export const RightSection = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;

  @media screen and (max-width: 540px) {
    padding: 0rem;
    width: 100%;
    white-space: pre-wrap;
  }
`;

export const WrapAvatar = styled.div`
  width: 24px;
  margin-right: 12px;
`;

export const IdAvatar = styled(Avatar)`
  background-color: #e7f1f6 !important;

  .ant-avatar-string {
    color: #1193ea !important;
    font-family: "Inter" !important ;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    vertical-align: middle !important;
  }
`;

export const WrapHead = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
`;

export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  padding-top: 6px;
  overflow: hidden;
`;

export const HeaderZeroSection = styled.div`
  width: calc(44px + 26px);

  @media screen and (max-width: 1439px) {
    transform: translateX(10px);
  }
`;

export const HeaderLeftSection = styled.div`
  width: 22%;
  display: flex;

  @media screen and (max-width: 1439px) {
    transform: translateX(10px);
  }
`;

export const HeaderMidSection = styled.div`
  width: 31%;
  display: flex;

  @media screen and (max-width: 1439px) {
    transform: translateX(5px);
  }
`;

export const HeaderRightSection = styled.div`
  width: 30%;
  display: flex;

  @media screen and (max-width: 1439px) {
    transform: translateX(-15px);
  }
`;
export const HeaderPrivateSection = styled.div`
  width: 10%;
  display: flex;
  transform: translateX(18px);

  @media screen and (max-width: 1439px) {
    justify-content: flex-end;
    transform: translateX(-50px);
  }
  @media screen and (max-width: 744px) {
    justify-content: flex-end;
    transform: translateX(-30px);
  }
`;
export const BtnWrapper = styled.div`
  margin-left: 16px;
`;

export const Wrapper = styled.div`
  width: 100%;
  min-height: 50px;
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  padding: 0 8px;

  @media screen and (max-width: 540px) {
    padding: 6px;
    padding-top: 0;
    flex-direction: column;
  }
`;

export const Title = styled.div`
  color: #fff;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: ${({ isMob }) => (isMob ? "space-between" : "center")};
  padding: ${({ isMob }) => (isMob ? "4px 8px" : "0px")};
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  background-color: #fa8f21;

  @media screen and (max-width: 540px) {
    font-size: 14px;
    width: ${({ isExtend }) => isExtend && "100%"};
  }
`;

export const InputField = styled.div`
  width: 100%;
  padding: 5px;
  background-color: #feab3d;
  display: flex;
  align-items: center;
`;

export const InputText = styled.p`
  margin: 0;
  padding-left: 0.8rem;
  font-weight: 700;
  color: #fff;
  line-height: 20px;
  font-size: 14px;
  flex-wrap: wrap;
  width: 90%;
  @media screen and (max-width: 540px) {
    font-size: 12px;
    font-weight: 600;
    padding-left: 4px;
  }
`;

export const PurposeWrapper = styled.div`
  width: 100%;
  padding: 6px 0;

  @media screen and (max-width: 540px) {
    padding-top: 0;
  }
`;

export const PurposeContent = styled.div`
  display: flex;
  height: 100%;
  padding: 0 8px;

  @media screen and (max-width: 540px) {
    flex-direction: column;
    padding: 0 6px;
  }
`;

export const PurposeTitle = styled.h3`
  color: #fff;
  width: 90px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  background-color: #85c4c9;

  @media screen and (max-width: 540px) {
    margin: 0;
    width: 100%;
    padding: 0.25rem;
    font-size: 14px;
  }
`;

export const PurposeContainer = styled.div`
  display: flex;
  margin: auto;
  width: 100%;
  padding: 6px 2px 6px 12.8px;
  background-color: #d1eeea;

  @media screen and (max-width: 540px) {
    flex-direction: column;
    padding: 5px 0px;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const Label = styled.label`
  color: var(--neutral-500, var(--Input-header, #737373));
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const InputItem = styled.input`
  /* width: 100%; */
  max-width: 100%;
  height:38px;
  line-height: 36px;
  border: none;
  outline: none;
  /* border-radius: 4px; */
  /* background-color: #fff; */
  padding: 0 0 0 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* border: 1px solid #d1eeea; */
  border: 1px solid  #E5E5E5;
background:  #FFF;

&:focus{
 border: 1px solid #71AACA;
background:  #FFF;
box-shadow: 0px 0px 0px 2px #D0E3ED;
}




  &::placeholder {
    color: #8e97a3;
    font-size: 14px;
  }
  @media screen and (max-width: 1439px) {
    font-size: 14px;
    font-weight: 400;
  }
`;

export const GoalActionWrap = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 540px) {
    justify-content: center;
    align-items: center;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;

  @media screen and (max-width: 540px) {
    flex-direction: column;
  }
`;

export const ModifiedButton = styled(Button)`
  background: linear-gradient(161.19deg, #1263de 18.35%, #448fff 74.45%);
`;
export const SwitchWrapper = styled.div`
  width: 10%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding-left: 4px;
  ${({ theme }) => theme.max("xs")`
  display:none !important;
  `}
`;
export const SwitchWrapperMobile = styled.div`
  display: none !important;

  ${({ theme }) => theme.max("xs")`
  display:flex !important;
  align-items: center;
  gap:0.5rem;
  `}
`;