import { FC, ChangeEvent, useState, useEffect } from 'react';
import { InputField } from "elements";

import { Wrapper, CustomSlider } from './styled';

interface IProps {
  value: number;
  max?: number;
  disabled?: boolean;
  showSlider?: boolean;
  onChange: (duration: number) => void;
}

const convertMinutesToTime = (time: number) => {
  const hours = (Math.floor(time / 60)) || 0;
  const mins = (time % 60) || 0;

  return {
    h: hours,
    m: mins
  };
};

const DurationForm: FC<IProps> = ({ value, max, disabled, showSlider, onChange }) => {
  const [time, setTime] = useState(0);
  const [duration, setDuration] = useState({
    h: 0,
    m: 0
  });

  useEffect(() => {
    setDuration(convertMinutesToTime(value));
  }, [value]);

  const handleTimeChange = (value: number) => {
    setTime(value);
  };

  const handleAfterTimeChange = (value: number) => {
    onChange(value);
    setDuration(convertMinutesToTime(value));
  };

  useEffect(() => {
    setTime(duration.h * 60 + duration.m);
    onChange(duration.h * 60 + duration.m);
  }, [duration]);

  const handleChange = (unit: string) => (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value.length) {
      setDuration(prev => {
        return {
          ...prev,
          [unit]: 0
        };
      });

      return;
    }

    let value = parseInt(e.target.value);

    if (isNaN(value)) {
      return;
    }

    if (unit === "h") {
      value = Math.min(999, Math.max(0, value));
    } else if (unit === "m") {
      value = Math.min(59, Math.max(0, value));
    }

    setDuration(prev => {
      return {
        ...prev,
        [unit]: value
      };
    });
  };

  // Todo: for later;
  const showError = false;

  return (
    <Wrapper $disabled={disabled}>
      {showSlider ? <CustomSlider
        value={time}
        min={15}
        step={15}
        max={max ? max : undefined}
        tooltip={{
          open: false
        }}
        disabled={disabled}
        onChange={handleTimeChange}
        onAfterChange={handleAfterTimeChange}
      /> : null}

      <InputField
        /* @ts-ignore */
        style={{ width: 70, paddingRight: 12 }}
        value={duration.h}
        size="xs"
        placeholder="-"
        suffix={"h"}
        error={
          showError &&
          (!duration.h || duration.h === 0) &&
          (!duration.m || duration.m === 0)
        }
        disabled={disabled}
        onChange={handleChange("h")}
        // onBlur={handleBlur}
      />
      <InputField
        /* @ts-ignore */
        style={{ width: 70 }}
        value={duration.m}
        size="xs"
        placeholder="-"
        suffix={"m"}
        error={
          showError &&
          (!duration.h || duration.h === 0) &&
          (!duration.m || duration.m === 0)
        }
        disabled={disabled}
        onChange={handleChange("m")}
        // onBlur={handleBlur}
      />
    </Wrapper>
  );
}

export default DurationForm;
