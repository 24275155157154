import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 43px);
`;

export const Step = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 32px 9px 22px;
  background-color: #fff;
  border-bottom: 1px solid #C1CAD1;
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 365px;
`;

export const Toggler = styled.button`
  display: flex;
  width: 24px;
  height: 24px;
  background: transparent;
  padding: 0;
  border: none;

  transform: rotate(180deg);
  transition: all .3s;

  ${({ $active }) => $active && `
    transform: rotate(0deg);
  `};
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 12px 4px 12px 12px;
  gap: 7px;
`;

export const Step1 = styled.div`
  width: 100%;
  width: ${({ isWidth }) => isWidth && "100%"};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 4px 0;
  margin: ${({ isMargin }) => isMargin && "0"};

  ${({ theme }) => theme.max("sm")`
    display: none;
  `}
`;

export const WrapHead = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: calc(28% + 80px);
`;

export const GoalHeading = styled.span`
  color: var(--Neutral-900, var(--Color-2, #171717));
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;

export const GoalText = styled.span`
  color: var(--Neutral-900, var(--Color-2, #171717));
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const InputLabel = styled.label`
  color: var(--Input-header, #737373);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 9px;
`;

export const Planwrapper = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 12px;
  border-radius: 0 0 5px 5px;
`;

export const Item = styled.span`
  color: var(--Input-header, #737373);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  width: 100%;

  ${({ $type }) => $type && css`
    display: flex;
    align-items: center;
    gap: 6px;
  `}
`;

export const Goalsitem = styled.div`
  width: 100%;
`;

export const GoalsRow = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0 4px 0 0;

  ${({ theme }) => theme.max("sm")`
    flex-direction: column;
    padding: 0 4px 4px;
  `}
`;

export const GoalsCol = styled.div`
  ${({ $goal }) =>
    $goal &&
    `
    width: 90px;
  `}

  ${({ $accountability }) => $accountability && `
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    width: 14%;
    margin-right: 20px;
  `}

  ${({ theme }) => theme.max("sm")`
    width: 100%;
  `}

  ${({ $name }) =>
    $name &&
    `
    flex: 1;
    padding-left: 17px;
    padding-right: 3px;
  `}

  ${({ $date }) => $date && `
    width: 7vw;
    display: flex;
    flex-direction: column;
  `}

  ${({ $dropdown }) =>
    $dropdown &&
    `
    width: 180px;
    margin-left: 6px;
    padding-bottom: 8px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}

  ${({ $private }) => $private && `
    width: 195px;
  `}

  ${({ $habit }) => $habit && `
    display: flex;
    align-items: center;
    // width: calc(140px + 51px);
    gap: 11px;

    .ant-btn-compact-first-item {
      display: none;
    }

    .ant-btn {
      background-color: transparent;
      border: none;
      transform: rotate(90deg);
    }
  `}

  ${({ $dot }) =>
    $dot &&
    `
    width: 61px;
    display:flex;
    justify-content: center ;
    // align-items: center;
    margin-top:0.28rem;
    float:right;
    `}

  ${({ theme }) => theme.max("sm")`
    ${({ $name }) =>
      $name &&
      `
      padding: 10px 8px;
    `}

    ${({ $date }) =>
      $date &&
      `
      margin-left: 0;

      &:first-of-type {
        margin-right: 3.5px;
      }

      &:last-of-type {
        margin-left: 3.5px;
      }
    `}

    ${({ $dropdown }) =>
      $dropdown &&
      `
      margin-left: 0;
      padding: 0 7px 5px 7px;
      background: #E28F21;
    `}
  `}
`;

export const PrivateLabel = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 2px 6px;
  border-radius: 24px;
  background: var(--Primary-50, #ECF3F6);
  color: var(--primary-500-main, #1271A6);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  height: 100%;
  margin-bottom: 4px;
  border-bottom: 1px solid #D9D9D9;
  padding: 0 0 12px 0;
`;

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 4px 4px 0;
`;

export const HeadCol = styled.div`
  // border: 1px solid red;

  ${({ $actions }) => $actions && css`
    display: flex;
    align-items: center;
  `}

  ${({ $width }) => $width && css`
    width: ${$width};
  `}

  ${({ $flex }) => $flex && css`
    flex: 1;
  `}

  ${({ $dnd }) => $dnd && css`
    // margin-right: 3.8vw;
    // padding-right: 30px;
    // margin-right: 30px;
  `}

  ${({ $deadline }) => $deadline && css`
    display: flex;
    align-items: center;
    gap: 12px;
    margin-right: 2vw;
  `}

  ${({ $startline }) => $startline && css`
    margin-right: 2vw;
  `}

  ${({ $last }) => $last && css`
    ::before {
      content: "";
    }
  `}
`;

export const ActionsWrapper = styled.div`
  display: flex;

  ${({ $first }) => $first && `
    width: calc(28% + 80px);
    // padding-right: 30px;
  `}

  ${({ $action }) => $action && `
    gap: 12px;
    // margin-right: 2vw;
  `}

  ${({ $fill }) =>
    $fill &&
    `
    flex: 1;
  `}

  ${({ theme }) => theme.max("sm")`
    flex: 1;
    width: 100%;

    ${({ $action }) =>
      $action &&
      `
      background: #E28F21;
      padding: 3px 7px 0 7px;
    `}
  `}
`;

export const InputValue = styled.span`
  color: var(--Neutral-900, var(--Color-2, #171717));
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
`;

export const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PContainer = styled.div`
  max-width: 590px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

export const PTitle = styled.span`
  color: var(--Text-Body-Light-state-gray, #798B99);
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

export const PText = styled.span`
  color: var(--Text-Body-Light-state-gray, #798B99);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  line-height: 20px;
`;
