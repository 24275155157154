import axios from "axios";
import React, { useState, useRef, useEffect } from 'react';

import useScrollSpy from '../../../utils/scrollspy';
import Loader from '../../Loader';

import * as Styled from './styled';

const apiBaseURL = "https://hat-arootah-web-24408-staging.botics.co/api/v1/"

const links = [
  { id: 0, text: "Terms Of Use", href: "#terms_of_use" },
  { id: 1, text: "Privacy Policy", href: "#privacy_policy" },
  { id: 2, text: "Waiver", href: "#waiver" },
  { id: 3, text: "Disclaimer", href: "#disclaimer" }
];

const Content = ({ readOnly, isActive, isModal, loading, onSubmit }) => {
  const [agreement, setAgreement] = useState(false);
  const [legal, setLegal] = useState({
    terms: '',
    privacy: '',
    waiver: '',
    disclaimer: ''
  });

  const [legalLoading, setLoading] = useState(true);
  const [hasReadAll, setHasReadAll] = useState(false);

  const $textWrapper = useRef(null);
  const $scrollingSection = useRef(null);
  const $label = useRef(null);

  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    scrollingElement: $scrollingSection,
    offsetPx: -360
  });

  useEffect(() => {
    if (!isActive)
      setAgreement(false);
  }, [isActive]); 

  useEffect(() => {
    if (activeSection === 3)
      setHasReadAll(true);
  }, [activeSection]); 

  useEffect(() => {
    if (!legalLoading && window.location.hash) {
      const target = document.querySelector(window.location.hash);

      if (!target)
        return;

      const offsetTop = target.offsetTop - 42;

      $scrollingSection.current.scrollTo({
        top: offsetTop,
        behavior: "smooth"
      });
    }
  }, [legalLoading]);

  useEffect(() => {
    document.addEventListener('click', handleClick);

    // Cleanup function to remove event listeners when component is unmounted
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []); 

  useEffect(() => {
    if (agreement && !hasReadAll) {
      setAgreement(false);
      $label.current.classList.add('shake-it-off');

      setTimeout(() => {
        $label.current.classList.remove('shake-it-off');
      }, 820);
    }
  }, [agreement]); 

  useEffect(() => {
    try {
      const fetchData = async () => {
        const [terms, privacy, waiver, disclaimer] = await Promise.all([
          axios.get(`${apiBaseURL}terms_and_conditions/latest/`),
          axios.get(`${apiBaseURL}privacy_policy/latest/`),
          axios.get(`${apiBaseURL}waiver_and_release/latest/`),
          axios.get(`${apiBaseURL}disclaimer/latest/`)
        ]);

        setLegal({
          terms: terms.data.terms,
          privacy: privacy.data.terms,
          waiver: waiver.data.terms,
          disclaimer: disclaimer.data.terms
        });

        setLoading(false);
        onScrollShadowHandle();
      }

      fetchData();
    } catch (error) {
      console.error(error);
    }
  }, []); 

  // Attach a click event listener to all <a> elements on the page
  const handleClick = (event) => {
    const anchor = event.target.closest('a');

    if(anchor && anchor.hash) {
      event.preventDefault();

      const target = document.querySelector(anchor.hash);

      if (!target)
        return;

      const offsetTop = target.offsetTop - 42;

      $scrollingSection.current.scrollTo({
        top: offsetTop,
        behavior: "smooth"
      });
    }
  }

  const $navContainer = useRef(null);

  const onChange = evt => {
    setAgreement(evt.target.checked);
  };

  const onScrollHandle = () => {
    onScrollShadowHandle();
  }

  const onScrollShadowHandle = () => {
    if (!$scrollingSection.current)
      return;

    const top = $scrollingSection.current.scrollTop || 0 + 42;

    if (top > 42)
      $textWrapper.current.classList.add('scroll-one');
    else
      $textWrapper.current.classList.remove('scroll-one');

    if (top < $scrollingSection.current.scrollHeight - $scrollingSection.current.clientHeight)
      $textWrapper.current.classList.add('scroll-two');
    else {
      setHasReadAll(true);
      $textWrapper.current.classList.remove('scroll-two');
    }
  }

  return (
    <Styled.Wrapper $modal={isModal}>
      <Styled.Side ref={$navContainer} $modal={isModal}>
        <Styled.Nav>
          {links.map(({ id, text, href }) => (
            <Styled.Link
              key={id}
              href={href}
              /* {...(readOnly && { href })}
              $disabled={!readOnly} */
              $active={activeSection === id}
              $modal={isModal}
            >
              {text}
            </Styled.Link>
          ))}
        </Styled.Nav>
      </Styled.Side>
      <Styled.Content>
        <Styled.TextWrapper ref={$textWrapper}>
          {legalLoading ? (
            <Styled.SpinWrapper>
              <Loader />
            </Styled.SpinWrapper>
          ) : (
            <Styled.Text id="scrollContainer" ref={$scrollingSection} onScroll={onScrollHandle}>
              {legal.terms && <div id="terms_of_use" dangerouslySetInnerHTML={{__html: legal.terms}} ref={sectionRefs[0]} />}
              {legal.privacy && <div id="privacy_policy" dangerouslySetInnerHTML={{__html: legal.privacy}} ref={sectionRefs[1]} />}
              {legal.waiver && <div id="waiver" dangerouslySetInnerHTML={{__html: legal.waiver}} ref={sectionRefs[2]} />}
              {legal.disclaimer && <div id="disclaimer" dangerouslySetInnerHTML={{__html: legal.disclaimer}} ref={sectionRefs[3]} />}
            </Styled.Text>
          )}
        </Styled.TextWrapper>

        {!readOnly && <Styled.Footer>
          <Styled.CheckboxLabel ref={$label}>
            <Styled.Checkbox
              checked={agreement}
              onChange={onChange}
            />
            <Styled.LabelCheckboxText>
              I have read and agree to the terms and conditions above.
            </Styled.LabelCheckboxText>
          </Styled.CheckboxLabel>

          <Styled.ActionButton type="button" loading={loading} disabled={!agreement || loading} onClick={onSubmit}>
            Continue
          </Styled.ActionButton>
        </Styled.Footer>}
      </Styled.Content>
    </Styled.Wrapper>
  );
}

export default Content;
