export const ActionTypes = {
    FETCH_LIST_PRIORITIZED_ACTIONS_REQUEST: "FETCH_LIST_PRIORITIZED_ACTIONS_REQUEST",
    FETCH_LIST_PRIORITIZED_ACTIONS_SUCCESS: "FETCH_LIST_PRIORITIZED_ACTIONS_SUCCESS",
    FETCH_LIST_PRIORITIZED_ACTIONS_FAILURE: "FETCH_LIST_PRIORITIZED_ACTIONS_FAILURE",

    FETCH_LIST_PRIORITIZED_ACTIONS_GROUPED_REQUEST: "FETCH_LIST_PRIORITIZED_ACTIONS_GROUPED_REQUEST",
    FETCH_LIST_PRIORITIZED_ACTIONS_GROUPED_SUCCESS: "FETCH_LIST_PRIORITIZED_ACTIONS_GROUPED_SUCCESS",
    FETCH_LIST_PRIORITIZED_ACTIONS_GROUPED_FAILURE: "FETCH_LIST_PRIORITIZED_ACTIONS_GROUPED_FAILURE",

    MUTATE_PRIORITIZED_VALUES: "MUTATE_PRIORITIZED_VALUES",
    UPDATE_PRIORITIZED_VALUES: "UPDATE_PRIORITIZED_VALUES",

    TOGGLE_VIEW: "TOGGLE_VIEW"
}