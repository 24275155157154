import React, { useState } from "react";
import _ from "lodash";

import InputAreaAI from "components/InputAreaAI";
import LockedOver from "components/LockedOver/LockedOver";

import * as Styles from "../styled";

const PurposeItem = ({ item, index: i, userData, onChange }) => {
  const [pleasure, setPleasure] = useState(item?.goal?.pleasure);
  const [pain, setPain] = useState(item?.goal?.pain);

  const handlePleasureChange = (value, _, ai) => {
    setPleasure(value);

    if (ai)
      onChange(item?.id, 'pleasure', value);
  }

  const handlePainChange = (value, _, ai) => {
    setPain(value);

    if (ai)
      onChange(item?.id, 'pain', value);
  }

  const handlePleasureBlur = () => {
    onChange(item?.id, 'pleasure', pleasure);
  }

  const handlePainBlur = () => {
    onChange(item?.id, 'pain', pain);
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      e.target.blur();
    }
  }

  return (
    <Styles.Row key={item?.id} border={item?.color}>
      {item?.is_private && userData?.id !== item?.user && (
        <LockedOver index={i + 1} width="50px" />
      )}
      <Styles.GoalActionWrap>
        <Styles.ItemContainer>
          <Styles.LeftSection>
            <Styles.WrapAvatar>
              <Styles.IdAvatar size={24}>{i + 1}</Styles.IdAvatar>
            </Styles.WrapAvatar>

            <Styles.WrapHead>
              <Styles.TitleWrap>
                <Styles.Text $area title={item.name}>{item.name}</Styles.Text>
                <Styles.Text $category title={item?.goal?.category_name}>
                  {item?.goal?.category_name}
                </Styles.Text>
              </Styles.TitleWrap>
              <Styles.Text $goal>
                {item?.goal?.goal_name}
              </Styles.Text>
            </Styles.WrapHead>
          </Styles.LeftSection>

          <Styles.MidSection>
            <Styles.InputWrapper>
              <Styles.Label>Rewards</Styles.Label>
              <InputAreaAI
                id={`rewards_${item?.id}`}
                name="pleasure"
                value={item?.goal?.pleasure}
                placeholder="Rewards for Achievement"
                onChange={handlePleasureChange}
                onKeyUp={handleKeyUp}
                onBlur={handlePleasureBlur}
                style={{ wordBreak: 'break-word' }}
                max={255}
                assistant={{
                  type: 'reward',
                  color: item?.color,
                  title: "Rewards",
                  order: i + 1,
                  area: item.name,
                  category: item?.goal?.category_name,
                  goal: item?.goal?.goal_name
                }}
              />
            </Styles.InputWrapper>

            <Styles.InputWrapper>
              <Styles.Label>Consequences</Styles.Label>
              <InputAreaAI
                id={`consequences_${item?.id}`}
                name="pain"
                value={item?.goal?.pain}
                placeholder="Consequences for Non-Achievement"
                onChange={handlePainChange}
                onKeyUp={handleKeyUp}
                onBlur={handlePainBlur}
                style={{ wordBreak: 'break-word' }}
                max={255}
                assistant={{
                  type: 'consequence',
                  color: item?.color,
                  title: "Consequences",
                  order: i + 1,
                  area: item.name,
                  category: item?.goal?.category_name,
                  goal: item?.goal?.goal_name
                }}
              />
            </Styles.InputWrapper>
          </Styles.MidSection>
        </Styles.ItemContainer>
      </Styles.GoalActionWrap>
    </Styles.Row>
  )
}

export default PurposeItem;
