import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Button } from './styled';

// @ts-ignore
import { useToken } from "utils/utility";

// @ts-ignore
import { Logout as logout } from "Redux/Logout/action";

// @ts-ignore
import { ReactComponent as ArrowForwardIcon } from "assets/svg/arrow-forward.svg";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { token } = useToken();

  useEffect(() => {
    return () => {
      setLoading(false);
    }
  }, []);

  const onClickHandle = () => {
    setLoading(true);

    // @ts-ignore
    dispatch(logout(navigate));
  }

  if (!token)
    return null;

  return (
    <Button disabled={loading} onClick={onClickHandle}>
      Log out
      <ArrowForwardIcon />
    </Button>
  );
}

export default Logout;
