// @ts-ignore
import { listCategories } from "../apm-apis";

import { ActionTypes } from "./type";

export const fetchCategories = (payload: string) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });
    const { data } = await listCategories(payload);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });
  }
};
