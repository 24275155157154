import React, { useState } from "react";
import { Popover } from "antd";

import {
  Wrapper,
  Title,
  SubTitle,
  CloseBtn,
  Head,

  Goal,
  GoalTitle,
} from "./styled";

//@ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/priorities/close.svg";

//@ts-ignore
import { ReactComponent as GoalIcon } from "assets/images/priorities/goal.svg";

interface GoalPopoverProps {
  data: {
    area_name?: string;
    action?: {
      context?: string;
    };
    category_name?: string;
    goal_name?: string;
    color?: string;
  };
}

const GoalPopover: React.FC<GoalPopoverProps> = ({ data }) => {
  const area_name = data?.area_name;
  const category_name = data?.category_name;

  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const PopoverContent = () => {
    return (
      <Wrapper>
        <Head>
          <Title>{data?.goal_name}</Title>
          <CloseBtn onClick={hide}>
            <CloseIcon />
          </CloseBtn>
        </Head>
        <SubTitle>
          {area_name} - {category_name}
        </SubTitle>
      </Wrapper>
    );
  }

  return (
    <Popover
      // @ts-ignore
      getPopupContainer={(trigger) => trigger.parentNode}
      align={{ offset: [0, 0] }}
      content={PopoverContent}
      title={null}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomRight"
      overlayClassName="goal_popover"
      overlayStyle={{ zIndex: 9999 }}
    >
      <Goal className="goal-toggle" $visible={open}>
        <GoalIcon />
        <GoalTitle>See Goal</GoalTitle>
      </Goal>
    </Popover>
  );
};

export default GoalPopover;
