import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { Button } from "elements";

import CongratsModal from "components/CongratsModal";
import StepLayout from "../StepLayout/index";
import Group from "./Group/index";

import logo from "assets/images/header_logo_modern.svg";
import arrowDownIcon from "assets/images/journeys/arrow-down.svg";

import { getCookie } from "utils/StorageVariables";
import { ACTIONS_PATH } from "utils/routes";

import { RetrieveMasterPlanDetails } from "Redux/RetrieveMasterPlan/action";

// TODO:
// import LockedOver from "components/LockedOver/LockedOver";

import * as Styles from "./styled.js";

const MasterPlan = () => {
  const componentRef = useRef();
  const [masterPlanData, setMasterPlanData] = useState();
  const [showCongratsModal, setShowCongratsModal] = useState(true);

  const dispatch = useDispatch();
  const domainName = getCookie("domainName");
  // const userData = JSON.parse(getCookie("userDetail"));

  useEffect(() => {
    let MasterPlanId = getCookie("MasterPlanId");
    dispatch(RetrieveMasterPlanDetails(MasterPlanId));
  }, []);

  const { masterPlanDetails } = useSelector(
    (state) => state.RetrieveMasterPlanDetailsReducer
  );

  useEffect(() => {
    if (!masterPlanDetails?.areas) return;
    const res = SortArr(masterPlanDetails?.areas);
    setMasterPlanData(res);
  }, [masterPlanDetails?.areas]);

  const SortArr = (data) =>
    data?.sort(function (a, b) {
      return a?.goal?.priority - b?.goal?.priority;
    });

  const navigate = useNavigate();

  const handlePrev = () => {
    navigate(ACTIONS_PATH);
  };

  const handleNext = () => {
    navigate("/final-step");
  };

  const PrintButton = () => {
    return (
      <ReactToPrint
        trigger={() => (
          <Button styleType="link" size="medium" type="button">
            Download PDF
            <img src={arrowDownIcon} alt="" />
          </Button>
        )}
        content={() => componentRef?.current}
      />
    );
  };

  const pageStyles = () => {
    return `
      @page { size: portrait; }
      @page { margin-top: 24px; margin-bottom: 0; }
      @page :first { margin-top: 0; margin-bottom: 50px; }`;
  };

  return (
    <StepLayout
      title="Master Plan"
      nextPageTitle="Save" // Rank Priorities
      footerLeft={<PrintButton />}
      smallerFooter
      onPrev={handlePrev}
      onNext={handleNext}
    >
      <Styles.Container>
        <Styles.ContainerItem>
          <Styles.TableContainer>
            <Styles.TableItem ref={componentRef}>
              <style>{pageStyles()}</style>

              <Styles.TableHeader>
                <Styles.HeaderItem>
                  <Styles.Logo src={logo} alt="logo" />
                  <Styles.Span></Styles.Span>
                  <Styles.Heading>
                    {domainName || ""} Goal Setting by Arootah
                  </Styles.Heading>
                </Styles.HeaderItem>
                <Styles.HeaderItemRight>
                  <Styles.Title>Master Plan</Styles.Title>
                </Styles.HeaderItemRight>
              </Styles.TableHeader>

              <Styles.TableBody>
                {masterPlanData && masterPlanData?.map(item => (
                  <Group data={item} />
                ))}
              </Styles.TableBody>

              <Styles.FooterText id="footer">
                Copyright &copy; Arootah 2024. All Rights Reserved
              </Styles.FooterText>
            </Styles.TableItem>
          </Styles.TableContainer>
        </Styles.ContainerItem>
      </Styles.Container>

      <CongratsModal
        active={showCongratsModal}
        onClose={() => setShowCongratsModal(false)}
      />
    </StepLayout>
  );
};

export default MasterPlan;
