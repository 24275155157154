import styled from "styled-components";

export const Wrapper = styled.div`
  z-index: 999999;
`;

export const Void = styled.div`
  display: flex;
  align-self: normal;
  flex: 1;
  cursor: pointer;
`;

export const Content = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 3.5px 6px;
  max-height: 300px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #CDCDCD;
  border-right: 1px solid #CDCDCD;
  border-left: 1px solid #CDCDCD;

  ${({ $active }) => $active && `
    border-top-color: #FF8B00;
    border-bottom-color: #FF8B00;
    border-right-color: #FF8B00;
  `}

  &:hover {
    background-color: #F4F4F4;

    .goal-toggle {
      visibility: visible;
    }
  }

  &:focus-within {
    background-color: #F4F4F4;

    .goal-toggle {
      visibility: visible;
    }
  }

  ${({ $task }) => $task ? `
    border-top: 1px solid #CDCDCD;
  ` : `
    &:hover {
      background: #F4F4F4;
    }
  `}

  ${props => props.$Zindex ? `
    border: 1px solid #FFA500;
  ` : ''}

  ${({ $first }) => $first && `
    border-top: 1px solid #CDCDCD;
  `}
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  min-height: 46px;
  overflow: hidden;
  flex: 1;
`;

export const Order = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 18px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #CAE4F1;
  color: #1689CA;
  text-align: center;
  font-family: Inter;
  font-size: 9px;
  line-height: 8px;
  margin-right: 10px;
`;

export const AreaBadge = styled.div`
  width: 5px;
  min-width: 5px;
  height: 35px;
  border-radius: 4px;
  margin-right: 10px;
  background: ${({ $color }) => $color ? $color : '#fff'};
`;

export const DragBtn = styled.span`
  display: flex;
  width: 22px;
  height: 22px;
`;

export const Chart = styled.div`
  width: 40px;
  height: 40px;
  margin-left: 9px;
  margin-right: 9px;

  button {
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    background-color: transparent;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  // flex: 1;
  align-self: stretch;
  overflow: hidden;

  ${({ $task }) => $task ? `` : `
    cursor: pointer;
  `}
`;

export const Title = styled.span`
  overflow: hidden;
  color: var(--fill-frame-dark-navy, #05192E);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const Priority = styled.span`
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--Generic-Black, #000);
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 30px;

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: #f00;
    border-radius: 50%;
  }

  ${({ $priority }) => $priority && $priority === 'high' && `
    &::before {
      background-color: #FF0000;
    }
  `};

  ${({ $priority }) => $priority && $priority === 'medium' && `
    &::before {
      background-color: #FEAB3D;
    }
  `};

  ${({ $priority }) => $priority && $priority === 'low' && `
    &::before {
      background-color: #8E97A3;
    }
  `};
`;

export const Icon = styled.img``;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

/* export const Time = styled.span`
  color: var(--generic-black, #000);
  text-align: end;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 48px;
`; */

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  transition: transform .25s;
  
  ${({ $free }) => $free && `
    width: initial;
  `}

  ${({ $disabled }) => $disabled && `
    pointer-events: none;
  `}

  ${({ $style }) => $style && `
    width: initial;
  `}

  ${({ $pencil }) => $pencil && `
    margin-right: 10px;
  `}

  ${({ $color }) => $color && `
    margin-right: 10px;
  `}

  ${({ $edit }) => $edit && `
    path {
      stroke: #fff;
      fill: #000000;
      width: 34px;
      height: 34px;
    }
  `}
`;

export const MobileScreens = styled.div`
  display: flex;
  gap: 8px;
`;
