
const location = window.location.href;

const apiProdURL = 'goals.arootah.com';
const apiStagingURL = 'goal-setting-workbo-37-staging.botics.co';

const userProdURL = 'auth.arootah.com';
const userStagingURL = 'compensation-tool-3752-staging.botics.co';

const hatProdURL = 'app.arootah.com';
const hatStagingURL = 'hat-arootah-web-24408.botics.co';

let apiURL = apiProdURL;
let userURL = userProdURL;
let hatURL = hatProdURL;

// Array of staging urls
const validStagingURLs = [
  'goal-setting-workbo-37-staging.botics.co',
  'localhost',
  '127.0.0.1'
];

// Checking if current url is a valid url
if (process.env.NODE_ENV === 'development' || validStagingURLs.some(url => location.includes(url))) {
  apiURL = apiStagingURL;
  userURL = userStagingURL;
  hatURL = hatStagingURL;
}

console.log(`${apiURL === apiProdURL && process.env.NODE_ENV === 'production' ? '🟢' : '🟡'} API:`, apiURL);

export const ROOT_API_URL = `https://${apiURL}/api/v1/`;
export const WEBSOCKET_URL = `wss://${apiURL}/ws/chat/`
export const AUTH_API_URL = `https://${userURL}/api/v1/`;
export const HAT_API_URL = `https://${hatURL}/api/v1/`;
