import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
`;

export const Order = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 18px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #4C545B;
  color: var(--Fill-Frame-White, var(--Dark-mode-bg, #FFF));
  text-align: center;
  font-family: Inter;
  font-size: 9px;
  line-height: 8px;
`;

export const Quadrant = styled.span<{ $quad: string; }>`
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 22px;

  ${({ $quad }) => $quad === "q1" && `
    color: var(--destructive-500-main, #F00000);
  `}

  ${({ $quad }) => $quad === "q2" && `
    color: var(--success-500-main, #59A310);
  `}

  ${({ $quad }) => $quad === "q3" && `
    color: #B15DF3;
  `}

  ${({ $quad }) => $quad === "q4" && `
    color: #FF9900;
  `}
`;

export const Title = styled.span`
  color: var(--Fill-Button-Prussian-blue, #112538);
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  word-break: break-word;
`;

export const Header = styled.div<{ $color?: string; }>`
  background: #fff;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  padding: 4px 9px 5px 4px;
  border-bottom: 1px solid var(--Neutral-300, #CDCDCD);

  ${({ $color }) => $color && `
    border-top: 3px solid ${$color};
  `}
`;

export const HeaderCol = styled.div`
  display: flex;
  gap: 7px;
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const HeadCol = styled.div`
  border-radius: 2px;
  background: var(--Neutral-100, #F5F5F5);
  padding: 2px 3px;
`;

export const AreaName = styled.span`
  color: #6D7784;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
`;

export const CategoryName = styled.span`
  color: #6D7784;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
`;

export const Body = styled.div<{ $forTasks: boolean; }>`
  padding: 0 4px 4px 24px;
  background: #fff;

  ${({ $forTasks }) => $forTasks && `
    padding-top: 4px;
  `}
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 0 9px 3px 24px;
  cursor: pointer;
`;

export const Count = styled.span`
  color: #6D7784;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
`;

export const Toggle = styled.button<{ $active: boolean; }>`
  width: 20px;
  height: 20px;
  padding: 0;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $active }) => $active && `
    img {
      transform: rotate(180deg);
    }
  `}
`;
