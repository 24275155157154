import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";

import Popup from "../Popup/index.jsx";
import { Button, InputField } from "elements";

import * as Styled from '../styled.js';
import { CreateCustomMetric } from "Redux/CustomMetrics/action.js";

import trashIcon from "assets/svg/trash.svg";

function isWhiteSpace(str) {
  return !str.trim().length;
}

const CreatePopup = ({ active, onClose }) => {
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);
  const [label, setLabel] = useState('');
  const [units, setUnits] = useState(['']);

  const $field = useRef(null);

  useEffect(() => {
    if (active && $field && $field.current) {
      setTimeout(() => {
        $field.current.focus();
      }, 150);
    }
  }, [active]);

  const handleAddUnit = () => {
    setUnits([...units, '']);
  }

  const handleLabelChange = e => {
    let input = e.target.value.trim();

    if (input.length > 12) {
      input = input.slice(0, 12);
    }

    setLabel(input);

    if (!isWhiteSpace(label)) {
      e.target.setCustomValidity('');
    } else {
      e.target.setCustomValidity('Please fill out the label field.');
    }
  };

  const handleUnitChange = i => e => {
    let input = e.target.value.trim();

    if (input.length > 17) {
      input = input.slice(0, 17);
    }

    setUnits(prev => prev.map((_, ui) => ui === i ? input : _));

    if (!isWhiteSpace(input)) {
      e.target.setCustomValidity('');
    } else {
      e.target.setCustomValidity('Please fill out the unit field.');
    }
  }

  const handleSave = evt => {
    evt.preventDefault();

    if (isWhiteSpace(label) || units.some(unit => isWhiteSpace(unit)))
      return;

    setDisabled(true);

    const formData = new FormData();

    // Add label to the FormData
    formData.append('label', label);

    // Process units and add them to the FormData
    units
      .filter(unit => unit.length)
      .forEach((unit, i) => {
        formData.append(`units[${i}]name`, unit);
      });

    // Dispatch the action with FormData
    dispatch(CreateCustomMetric(formData, handleClose));
  }

  const handleDelete = i => e => {
    e.preventDefault();
    setUnits(prev => prev.filter((_, ui ) => ui !== i));
  }

  const handleClose = success => {
    setDisabled(false);

    if (success) {
      setLabel('');
      setUnits(['']);
      onClose();
    }
  }

  return (
    <Popup active={active} title="Add Custom Metric" onClose={() => handleClose(true)}>
      <Styled.Form onSubmit={handleSave}>
        <Styled.Group>
          <Styled.Label>Custom Label</Styled.Label>
          <InputField value={label} onChange={handleLabelChange} size="md" placeholder="Label Name" ref={$field} required />
        </Styled.Group>

        {units.map((unit, i) => (
          <Styled.Group key={i}>
            <Styled.Label>Custom Unit</Styled.Label>

            <Styled.InputGroup>
              <InputField
                value={unit}
                id={`unit-${i}`}
                onChange={handleUnitChange(i)}
                size="md"
                placeholder="Unit Name"
                containerStyle={{ flex: 1 }}
                required
              />
              <Styled.DeleteButton onClick={handleDelete(i)}>
                <img src={trashIcon} alt="Delete" />
              </Styled.DeleteButton>
            </Styled.InputGroup>
          </Styled.Group>
        ))}

        <Styled.ActionsGroup>
          <Button type="button" styleType="link-primary" onClick={handleAddUnit}>Add Unit</Button>
        </Styled.ActionsGroup>

        <Styled.Footer>
          <Button disabled={disabled} type="submit">
            Save
          </Button>
        </Styled.Footer>
      </Styled.Form>
    </Popup>
  );
};

export default CreatePopup;
