import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 6px -4px rgba(16, 24, 40, 0.20), 0px 1px 15px -3px rgba(16, 24, 40, 0.25);
  background-color: #fff;
  width: 448px;
  margin-left: -45px;
`;

export const Body = styled.div`
  display: flex;
  padding: 10px 8px 12px 8px;
  gap: 12px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 8px 10px 8px;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;

  ${({ $flex }) => $flex && `
    flex: ${$flex};
  `};
`;

export const Text = styled.span`
  color: var(--neutral-500, var(--Input-header, #737373));
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const DropdownToggle = styled.button`
  display: flex;
  justify-content: space-evenly;
  padding: 8px 6px 8px 6px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background: transparent;
  color: #1B2A3D;
  border: 1px solid var(--neutral-200, #E5E5E5);
  outline: none;
  width: 108px;
  box-sizing: border-box;
  transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span.default {
    color: rgb(163, 163, 163);
  }

  svg {
    transform: rotate(90deg);
    transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  :hover,
  &.ant-dropdown-open {
    background: var(--primary-50, #ECF3F6);
    border-color: #ECF3F6;
  }

  &.ant-dropdown-open {
    color: #1271A6;
    font-weight: 500;

    svg {
      transform: rotate(-90deg);
    }
  }
`;
