import { updateGoal } from "api";
import { Error } from "Responses/Response";
import { ActionTypes } from "./types";

export const UpdateGoal = (id, formData, callback) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const { data } = await updateGoal(id, formData);

    dispatch({
      type: ActionTypes.SUCCESS,
    });

    dispatch({
      type: ActionTypes.UPDATE_GOAL_SUCCESS,
      payload: data
    });

    if (callback)
      callback();

  } catch (error) {
    error?.response?.data?.goal_name?.map((item) => Error(item));
    dispatch({
      type: ActionTypes.LOADING,
      payload: error
    });
  }
};
