export const ActionTypes = {
  GET_LOADING: "GET_CUSTOM_METRICS_LOADING",
  GET_ERROR: "GET_CUSTOM_METRICS_ERROR",
  GET_SUCCESS: "GET_CUSTOM_METRICS_SUCCESS",

  ADD_LOADING: "ADD_CUSTOM_METRIC_LOADING",
  ADD_ERROR: "ADD_CUSTOM_METRIC_ERROR",
  ADD_SUCCESS: "ADD_CUSTOM_METRIC_SUCCESS",

  UPDATE_LOADING: "UPDATE_CUSTOM_METRIC_LOADING",
  UPDATE_ERROR: "UPDATE_CUSTOM_METRIC_ERROR",
  UPDATE_SUCCESS: "UPDATE_CUSTOM_METRIC_SUCCESS",

  DELETE_LOADING: "DELETE_CUSTOM_METRIC_LOADING",
  DELETE_ERROR: "DELETE_CUSTOM_METRIC_ERROR",
  DELETE_SUCCESS: "DELETE_CUSTOM_METRIC_SUCCESS",

  DELETE_UNIT_LOADING: "DELETE_CUSTOM_UNIT_LOADING",
  DELETE_UNIT_ERROR: "DELETE_CUSTOM_UNIT_ERROR",
  DELETE_UNIT_SUCCESS: "DELETE_CUSTOM_UNIT_SUCCESS",

  ADD_UNIT_LOADING: "ADD_CUSTOM_UNIT_METRIC_LOADING",
  ADD_UNIT_ERROR: "ADD_CUSTOM_UNIT_METRIC_ERROR",
  ADD_UNIT_SUCCESS: "ADD_CUSTOM_UNIT_METRIC_SUCCESS",

  UPDATE_UNIT_LOADING: "UPDATE_CUSTOM_UNIT_METRIC_LOADING",
  UPDATE_UNIT_ERROR: "UPDATE_CUSTOM_UNIT_METRIC_ERROR",
  UPDATE_UNIT_SUCCESS: "UPDATE_CUSTOM_UNIT_METRIC_SUCCESS"
};
