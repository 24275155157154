import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  padding: 6px;
  border-radius: 4px;
  background: var(--Primary-50, #ECF3F6);

  ${({ theme }) => theme.min("sm")`
    ${({ $mobile }) =>
      $mobile &&
      `
      display: none;
    `};
  `}

  ${({ theme }) => theme.max("sm")`
    ${({ $desktop }) =>
      $desktop &&
      `
      display: none;
    `};
  `}
`;

export const PrevNextPlan = styled.button`
  font-family: "Poppins";
  outline: none;
  border: none;
  font-size: 14px;
  line-height: 24px;
  color: #000;
  width: 28px;
  height: 28px;
  background: #D0E3ED;
  border-radius: 3px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.max("sm")`
    padding: 0;
  `}

  img {
    width: 12px;
    height: 12px;

    ${({ $prev }) => $prev &&`
      transform: rotate(180deg);
    `};

    ${({ $next }) => $next &&`
    
    `};
  }

  ${({ $hide }) => $hide &&`
    display: none;
  `};

  &:hover {
    img {
      ${({ $prev }) => $prev &&`
        transform: rotate(180deg) ;
      `};
    }
  }
`;

export const PageCount = styled.span`
  font-family: "Poppins";
  font-size: 16px;
  line-height: 24px;
  margin: 0 16px;
  color: #06203a;
`;
