import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import isEqual from 'lodash.isequal';
import styled, { css } from 'styled-components';

import { Formik } from "formik";
import * as yup from "yup";

import TextBox from '../../../atoms/TextBox';
import TextBoxLabel from '../../../atoms/TextBoxLabel';
import Button from '../../../components/Button';
import PasswordIndicator from '../../PasswordIndicator';

import EyeIcon from '../../../assets/svg/eye.svg';
import EyeSlashIcon from '../../../assets/svg/eye-slash.svg';

import { UpdatePassword } from 'Redux/UpdatePassword/action';

const schema = yup.object().shape({
  oldPassword: yup
    .string()
    .required("This field is required."),
  newPassword: yup
    .string()
    .required("This field is required.")
    .notOneOf([yup.ref("oldPassword"), null], "New password cannot be the same as your old password.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password must have a minimum of 8 characters and at least one of each: uppercase, special character, and number."
    ),
  confirmPassword: yup
    .string()
    .required("This field is required.")
    .oneOf([yup.ref("newPassword")], "Password entries do not match.")
});

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: ''
}

const PasswordSection = () => {
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.deleteAccount);

  const [passwordCurrentVisible, setPasswordCurrentVisible] = useState(false);
  const [passwordNewVisible, setPasswordNewVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);

  const formRef = useRef(null);

  const onPasswordCurrentClickHandle = () => {
    setPasswordCurrentVisible(!passwordCurrentVisible);
  }

  const onPasswordNewVisibleClickHandle = () => {
    setPasswordNewVisible(!passwordNewVisible);
  }

  const onPasswordConfirmVisibleClickHandle = () => {
    setPasswordConfirmVisible(!passwordConfirmVisible);
  }

  const onSuccessHandle = () => {
    formRef.current.resetForm();
  }

  const onSubmitHandle = form => {
    let data = {
      old_password: form.oldPassword,
      new_password: form.newPassword
    };

    dispatch(UpdatePassword(data, onSuccessHandle));
  }

  return (
    <Group>
      <Formik
        validationSchema={schema}
        onSubmit={onSubmitHandle}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        innerRef={formRef}
      >
        {({ handleSubmit, handleReset, handleChange, values, errors }) => (
          <FormStyled noValidate onSubmit={handleSubmit}>
            <FormBody>
              <FormGroup>
                <Label className="authLabel">Old Password*</Label>
                <div className="flex-grow-1 position-relative w-100">
                  <TextBoxs
                    type={passwordCurrentVisible ? "text" : "password"}
                    name="oldPassword"
                    value={values.oldPassword}
                    onChange={handleChange}
                    isInvalid={errors.oldPassword}
                  />
                  <Eye type="button" isInvalid={errors.oldPassword} onClick={onPasswordCurrentClickHandle}>
                    {passwordCurrentVisible
                      ? <img src={EyeSlashIcon} alt="" width="24" />
                      : <img src={EyeIcon} alt="" width="24" /> }
                  </Eye>
                  <FormError type="invalid">
                    {errors.oldPassword}
                  </FormError>
                </div>
              </FormGroup>

              <FormGroup>
                <Label className="d-flex align-items-center justify-content-between">
                  New Password*
                </Label>
                <div className="flex-grow-1 position-relative w-100">
                  <TextBoxs
                    type={passwordNewVisible ? "text" : "password"}
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleChange}
                    isInvalid={errors.newPassword}
                  />
                  <Eye type="button" isInvalid={errors.newPassword} onClick={onPasswordNewVisibleClickHandle}>
                    {passwordNewVisible
                      ? <img src={EyeSlashIcon} alt="" width="24" />
                      : <img src={EyeIcon} alt="" width="24" /> }
                  </Eye>
                  <PasswordIndicator value={values.newPassword} secondary />
                  {errors.newPassword ? null : <FormInfo>Password must have a minimum of 8 characters and at least one of each: uppercase, special character, and number.</FormInfo>}
                  <FormError type="invalid">
                    {errors.newPassword}
                  </FormError>
                </div>
              </FormGroup>

              <FormGroup>
                <Label className="authLabel">Confirm Password*</Label>
                <div className="flex-grow-1 position-relative w-100">
                  <TextBoxs
                    type={passwordConfirmVisible ? "text" : "password"}
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    isInvalid={errors.confirmPassword}
                  />
                  <Eye type="button" isInvalid={errors.confirmPassword} onClick={onPasswordConfirmVisibleClickHandle}>
                    {passwordConfirmVisible
                      ? <img src={EyeSlashIcon} alt="" width="24" />
                      : <img src={EyeIcon} alt="" width="24" /> }
                  </Eye>
                  <FormError type="invalid">
                    {errors.confirmPassword}
                  </FormError>
                </div>
              </FormGroup>
            </FormBody>

            <FormFooter>
              <CancelButton
                type="Button"
                onClick={handleReset}
                loading={loading}
                disabled={isEqual(initialValues, values) || loading}
              >
                Cancel
              </CancelButton>
              <ActionButton
                type="submit"
                loading={loading}
                disabled={isEqual(initialValues, values) || loading}
                bgcolor={'#1271A6'}
              >
                Save Changes
              </ActionButton>
            </FormFooter>
          </FormStyled>
        )}
      </Formik>
    </Group>
  );
}

export { PasswordSection };

const Group = styled.div`
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.max('lg')`
    height: fit-content;
  `}
`;

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px 20px;
  min-height: 430px;

  ${({ theme }) => theme.max('lg')`
    padding: 0 24px 24px;
    min-height: initial;
  `}

  ${({ theme }) => theme.max('sm')`
    padding: 0;
  `}
`;

const FormGroup = styled.div`
  width: 100%;
  max-width: 388px;
  margin: 22px auto 0;

  ${({ theme }) => theme.max('lg')`
    margin: 0 auto;
  `}
`;

const FormBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ theme }) => theme.max('lg')`
    flex-direction: column-reverse;
    align-items: center;
    padding: 12px 0 0;
  `}
`;

const Label = styled(TextBoxLabel)`
  min-width: 100px;
  margin-bottom: 4px;

  ${({ theme }) => theme.max('lg')`
    padding: 15px 0 0;
  `}
`;

const TextBoxs = styled(TextBox)`
  width: 100%;

  &[type="password"] {
    width: calc(100% - 36px);
    border-right: none !important;
    border-radius: 0.25rem 0 0 0.25rem;
  }
`;

const ActionButton = styled(Button)`
  background-color: ${({ bgcolor }) => bgcolor};
  color: ${({ color }) => color};
  width: 140px;
  margin-left: 5px;

  ${({ theme }) => theme.max('lg')`
    width: 100%;
    max-width: 388px;
  `}

  ${({ theme }) => theme.max('sm')`
    margin-left: 0;
  `}

  &:disabled {
    opacity: 0.5;
  }
`;

const CancelButton = styled(Button)`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-right: 20px;
  width: 48px;
  color: ${({ theme }) => theme.palette.primary.main};
  background: transparent;

  &:disabled {
    opacity: 0;
  }
`;

const Eye = styled(Button)`
  position: absolute;
  background: transparent;
  opacity: 0.8;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  padding: 0;
  border-right: 1px solid #e3e5e9;
  border-top: 1px solid #e3e5e9;
  border-bottom: 1px solid #e3e5e9;
  border-radius: 0 0.25rem 0.25rem 0;

  ${({ isInvalid }) => isInvalid && css`
    background-color: #FAF0F0;
    border-color: #d79f9f;
  `}
`;

const FormInfo = styled.div`
  margin-top: 12px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 12px;
  line-height: 16px;
  color: #818EA3;
`;

const FormError = styled.div`
  margin-top: 4px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 12px;
  line-height: 16px;
  color: #f00;
`;
