import { FC, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';

// @ts-ignore
import trashIcon from "assets/svg/trash-outlined.svg";

import { DeleteCustomRole } from 'Redux/GetRoles/action';
import { DeleteCustomValue } from 'Redux/GetValues/action';

import { Wrapper, Button } from './styled';

interface IProps {
  value: string;
  label: string;
  type: string;
}

const CustomOption: FC<IProps> = ({ value, label, type }) => {
  const dispatch = useDispatch();

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (type === 'roles') {
      dispatch(DeleteCustomRole(value));
    }
    else {
      dispatch(DeleteCustomValue(value));
    }
  }

  return (
    <Wrapper>
      {label}

      <Button onClick={handleClick} title="Delete Custom Role">
        <img src={trashIcon} alt="" />
      </Button>
    </Wrapper>
  );
}

export default CustomOption;
