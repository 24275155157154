import React, { useEffect, useState } from 'react';
import * as Styled from '../styled';

import { ProfileSection } from '../Form/ProfileSection';
import { useDispatch } from 'react-redux';
// import { updateUserProfile } from '../../../modules/actions/UserActions';
import { PasswordSection } from '../Form/PasswordSection';

import DeleteAccount from '../DeleteAccount';

import useWindowSize from 'utils/useWindowSize';
import { BREAKPOINTS } from 'theme/mixins';
import { UpdateUserData } from 'Redux/UpdateUserData/action';
// import { changeUserProfile } from 'modules/actions/AuthActions';

const AccountInformation = ({ userData }) => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  const onSubmitHandle = body => {
    dispatch(UpdateUserData(body, userData.public_id || userData.id));
  }

  return (
    <Styled.Wrapper>
      {BREAKPOINTS.md <= width && <Styled.Row $primary>
        <Styled.Col>
          <Styled.Title>Personal Information</Styled.Title>
          <Styled.Text>Add information about yourself</Styled.Text>
        </Styled.Col>
        <Styled.Col>
          <Styled.Title>Reset Password</Styled.Title>
        </Styled.Col>
      </Styled.Row>}

      <Styled.Row>
        <Styled.Col $box>
          {BREAKPOINTS.md > width && <Styled.Head>
            <Styled.Title>Personal Information</Styled.Title>
            <Styled.Text>Add information about yourself</Styled.Text>
          </Styled.Head>}
          <ProfileSection data={userData} onSubmit={onSubmitHandle} />
        </Styled.Col>
        <Styled.Col $box>
          {BREAKPOINTS.md > width && <Styled.Title>Reset Password</Styled.Title>}
          <PasswordSection />
        </Styled.Col>
      </Styled.Row>

      <Styled.Row>
        <Styled.Col>
          <DeleteAccount data={userData} />
        </Styled.Col>
      </Styled.Row>

      <Styled.Disclaimer>Changes made here will be reflected across all Arootah apps</Styled.Disclaimer>
    </Styled.Wrapper>
  );
};

export default AccountInformation;
