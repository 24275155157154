import styled, { css } from 'styled-components';

import Button from "components/Button";

export const Container = styled.div`
  width: 100%;

  @media screen and (max-width: 1439px) {
    height: calc(100% + 36px);
  }

  ${({ theme }) => theme.max("sm")`
    height: calc(100% + 8px);
  `}
`;

export const Step = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 22px;
  background-color: #fff;

  ${({ $header }) => $header && css`
    border-bottom: 1px solid #C1CAD1;
  `}

  ${({ theme }) => theme.max("sm")`
    padding: 9px 16px;
  `}

  ${({ $footer }) => $footer && css`
    padding: 7px 22px;
    border-top: 1px solid #C1CAD1;

    @media screen and (max-width: 1439px) {
      position: fixed;
      bottom: 0;
      background-color: #fff;
    }

    ${({ theme }) => theme.max("sm")`
      padding: 8px 16px;
    `}
  `}
`;

export const Heading = styled.span`
  color: var(--neutral-600, var(--Text, #525252));
  font-family: Poppins;
  font-size: 14px;
  line-height: 16px;
`;

export const Title = styled.h1`
  color: #06203a;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  vertical-align: middle;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
`;

export const Logo = styled.img`
  width: 118px;
`;

export const NextButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const HeadingTop = styled.h1`
  color: #06203a;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  vertical-align: middle;
  font-size: 18px;
  line-height: 24px;
  margin: 0;

  ${({ theme }) => theme.max("sm")`
    font-size: 14px;
  `}
`;

export const ButtonWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

export const ContainerItem = styled.div`
  width: 100%;
`;

export const TableContainer = styled.div`
  padding: 12px;
  width: 100%;
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;
  position: relative;

  @media print {
    overflow: visible;
    height: fit-content;
  }

  ${({ theme }) => theme.min("sm")`
    ::-webkit-scrollbar {
      width: 11px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #71aaca;
      border-radius: 10px;
      background: #71aaca;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #116595;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #116595;
    }
  `}
`;

export const TableItem = styled.div`
  background: #fff;
  width: 877px;
  min-height: 1130px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  padding:  16px;
  /* border: 1px solid green; */


`;

export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;

  ${({ $printOnly }) =>
    $printOnly &&
    `
    display: none;

    @media print {
      display: block;
    }
  `}
`;

export const TableBody = styled.div`
  // display: flex;
  // flex-direction: column;
  // gap: 8px;
`;

export const HeaderItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Span = styled.span`
  width: 2px;
  height: 26px;
  margin: 0 23px;
  background: #1271a6;
`;

export const HeaderItemRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const MasterPlanContainer = styled.div`
  width: 100%;
  /* padding: 0 16px; */
  margin-bottom: 8px;
  position: relative;
`;

export const PageBreak = styled.div`
  @media print {
    page-break-before: always;
    padding-top: 32px;
  }
`;

export const Item1 = styled.div`
  display: flex;
  background-color: #112699;
`;

export const Item2 = styled.div`
  background: #e7f1f6;
`;

export const Ul = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  color: #fff;
  color: ${({ isColor }) => isColor && "#000"};
  width: 100%;
  margin: 0;

  ${({ $head }) =>
    $head &&
    `
    padding: 6px 19px 5px 9px;
  `}

  ${({ $body }) =>
    $body &&
    `
    padding: 5px 4px 7px 9px;
  `}
`;

export const Li = styled.li`
  ${({ $head }) =>
    $head &&
    `
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 10px;
    line-height: 18px;
    color: #fff;
    text-transform: uppercase;
  `}

  ${({ $inner }) =>
    $inner &&
    `
    font-family: 'Inter';
    letter-spacing: -0.02em;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    color: #000000;
  `}

  ${({ $priority }) =>
    $priority &&
    `
    width: 46px;
    margin-right: 11px;
  `}

  ${({ $priorityInner }) =>
    $priorityInner &&
    `
    text-align: center;
    font-weight: 700;
  `}

  ${({ $area }) =>
    $area &&
    `
    width: calc(27px + 94px);
  `}

  ${({ $category }) =>
    $category &&
    `
    width: calc(56px + 134px);
  `}

  ${({ $measurement }) =>
    $measurement &&
    `
    width: calc(76px + 100px);
    line-break:anywhere;
  `}

  ${({ $accountability }) =>
    $accountability &&
    `
    width: calc(89px + 38px);
  `}

  ${({ $startline }) =>
    $startline &&
    `
    width: calc(53px + 45px);
  `}

  ${({ $deadline }) =>
    $deadline &&
    `
    width: 48px;
  `}
`;

export const List = styled.li`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  padding-top: 0.8rem;
  font-size: 12px;
  padding-right: 3rem;
  width: 18%;
`;

export const Item2Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  margin-bottom: 3px;
`;

export const Goals = styled.div`
  width: 356px;
  background: #d0e3ed;
`;

export const GoalsHeading = styled.h2`
  background: #116595;
  width: 100%;
  padding: 4px 11px;
  color: #fff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0;
`;

export const GoalsText = styled.p`
  font-family: "Inter";
  word-break: break-word;
  font-weight: 600;
  padding: 9px 18px 12px 12px;
  width: 100%;
  font-size: 13px;
  line-height: 16px;
  color: #000;
  margin-bottom: 0;
`;

export const ModifiedButton = styled(Button)`
  background: linear-gradient(161.19deg, #1263de 18.35%, #448fff 74.45%);
`;

export const Purpose = styled.div`
  width: 476px;
`;

export const PurposeHeading = styled.h2`
  background: #418db8;
  width: 100%;
  margin-bottom: 0;
  padding: 4px 11px;
  color: #fff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PurposeText = styled.div`
  padding: 4px 16px 3px 8px;
  background: #d0e3ed;
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ $first }) =>
    $first &&
    `
    margin-bottom: 2px;
    height: 33px;
  `}

  ${({ $last }) =>
    $last &&
    `
    margin-top: 2px;
    height: 32px;
  `}
`;

export const Text = styled.p`
  color: #000;
  font-family: "Inter";
  font-style: normal;
  word-break: break-word;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  margin-bottom: 0;
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 5px 4px;
`;

export const Action1 = styled.div`
  width: 416px;
  display: flex;
  height: auto;
  background: #d0e3ed;
`;

export const ActionHeading = styled.h2`
  background: #71aaca;
  color: #fff;
  min-width: 70px;
  min-height: 38px;
  height: auto;
  margin-bottom: 0;
  white-space: nowrap;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 38px;
  display: flex;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
`;

export const ActionText = styled.div`
  padding: 3px 20px 2px 10px;
  font-family: "Inter";
  word-break: break-word;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #404040;
  word-break: break-word;
`;

export const FooterText = styled.section`
  margin: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding-top: 12px;
  padding-bottom: 32px;
  color: #404040;
  line-height: 16px;
  letter-spacing: -0.24px;
  font-weight: 400;
  // position: absolute;
  // bottom: 0;

  @media print {
    // position: fixed;
    // position: static;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // padding-bottom: 0;
    // margin-top: 25px;
    // margin-bottom: -5px;
  }
`;

export const Link = styled.a`
  text-decoration: none !important;
  padding: 0 0.2rem;
  color: #1271a6;

  :hover {
    color: #1271a6;
  }
`;