import { Popover } from "antd";
import { useLocation } from "react-router-dom";

import { ReactComponent as AppsIcon } from "assets/svg/appSwitcher.svg";
import { ReactComponent as LogoDM } from "assets/images/auth/logoDM.svg";
import { ReactComponent as LogoPP } from "assets/images/auth/logoPP.svg?v=1";
import { ReactComponent as LogoHC } from "assets/images/auth/logoHC.svg";
import { ReactComponent as LogoGPS } from "assets/images/auth/logoGPS.svg";
import { ReactComponent as LogoAPM } from "assets/images/auth/logoAPM.svg";

import * as Styled from './styled';
import customStyles from './global.module.scss';

import {
  APM_PATH,
} from "utils/routes";
import { useState } from "react";

const getPath = () => {
  const location = window.location.href;

  // Array of staging urls
  const validURLs = [
    'goal-setting-workbo-37-staging.botics.co',
    'localhost',
    '127.0.0.1'
  ];

  if (process.env.NODE_ENV === 'development' || validURLs.some(url => location.includes(url))) {
    return {
      hcURL: 'hat-arootah-web-24408-staging.botics.co',
      ppURL: 'project-planner-36424-staging.botics.co'
    }
  }

  return {
    hcURL: 'app.arootah.com',
    ppURL: 'apps.arootah.com'
  }
}

const AppSwitcher = () => {
  const { ppURL, hcURL } = getPath();
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);

  const isAPM = pathname.includes(APM_PATH);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      overlayClassName={customStyles.popover}
      placement="bottomLeft"
      padding={0}
      content={
        <Styled.Box>
          <Styled.AppsList>
            <Styled.AppItem to={'/'} onClick={hide} $active={!isAPM}>
              <LogoGPS />
              <Styled.AppItemTitle>Goal Manager</Styled.AppItemTitle>
            </Styled.AppItem>

            <Styled.AppItem to={APM_PATH} onClick={hide} $active={isAPM}>
              <LogoAPM />
              <Styled.AppItemTitle>Time Manager</Styled.AppItemTitle>
            </Styled.AppItem>

            <Styled.AppLink href={`https://${hcURL}`} /* target="_blank" */>
              <LogoHC />
              <Styled.AppItemTitle>Habit Manager</Styled.AppItemTitle>
            </Styled.AppLink>

            <Styled.AppLink href={`https://${ppURL}/decision-manager`} /* target="_blank" */>
              <LogoDM />
              <Styled.AppItemTitle>Decision Manager</Styled.AppItemTitle>
            </Styled.AppLink>

            <Styled.AppLink href={`https://${ppURL}/project-planner`} /* target="_blank" */>
              <LogoPP />
              <Styled.AppItemTitle>Project Manager</Styled.AppItemTitle>
            </Styled.AppLink>
          </Styled.AppsList>

          <Styled.Footer>
            <Styled.Link href="https://arootah.com" target="_blank">www.arootah.com</Styled.Link>
          </Styled.Footer>
        </Styled.Box>
      }
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Styled.Toggle /* $rotate={'-90deg'} */>
        <AppsIcon />
      </Styled.Toggle>
    </Popover>
  );
}

export default AppSwitcher;
