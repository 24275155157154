import React, { useEffect, /* useLayoutEffect, */ useState/* , useRef */ } from "react";
import { Accordion } from "react-bootstrap";

import Popup from "../Popup/index.jsx";
import { Button } from "elements/index.tsx";
import MetricGroup from "../UnitGroup/index.jsx";

import { ReactComponent as PlusIcon } from "assets/images/journeys/plus.svg";

import * as Styled from '../styled.js';
import { useDispatch, useSelector } from "react-redux";
import { CreateCustomUnit, DeleteCustomMetric, DeleteCustomUnit, UpdateCustomUnit, UpdateCustomMetric } from "Redux/CustomMetrics/action.js";

const CustomizePopup = ({ active, onClose }) => {
  const dispatch = useDispatch();

  const [activeItem, setActiveItem] = useState(null);
  const [newMetrics, setNewMetrics] = useState([]);

  // const action = useRef(false);

  const { data: customMetrics } = useSelector(
    (state) => state.customMetricsReducer
  );

  useEffect(() => {
    setNewMetrics(customMetrics)
  }, [customMetrics]);

  /* useLayoutEffect(() => {
    if (newMetrics.length && !activeItem)
      setActiveItem(newMetrics[action.current ? newMetrics[newMetrics.length - 1] : 0].id);
  }, [newMetrics]); */

  const onToggleHandle = key => {
    setActiveItem(prev => prev === key ? null : key);
  }

  const handleClose = () => {
    onClose(false);
  }

  const handleAddMetric = () => {
    onClose(true);
  }

  const handleUpdateUnit = (formData, id) => {
    dispatch(UpdateCustomUnit(formData, id));
  }

  const handleUnitDelete = id => {
    dispatch(DeleteCustomUnit(id));
  }

  const handleMetricUpdate = (formData, id) => {
    dispatch(UpdateCustomMetric(formData, id));
  }

  const handleMetricDelete = id => {
    dispatch(DeleteCustomMetric(id));
  }

  const handleAddUnit = (formData, id, callback) => {
    dispatch(CreateCustomUnit(formData, id, callback));
  }

  const contentFooter = () => (
    <Button onClick={handleAddMetric} type="button" size="medium" styleType="link-primary">
      <PlusIcon />
      Add metric
    </Button>
  );

  return (
    <Popup active={active} title="Manage Custom Metric" footer={contentFooter} onClose={handleClose}>
      <Accordion as={Styled.Content} activeKey={activeItem}>
        {newMetrics.map(item => <MetricGroup
          activeKey={activeItem}
          data={item}
          onToggle={onToggleHandle}
          onUnitAdd={handleAddUnit}
          onUnitUpdate={handleUpdateUnit}
          onUnitDelete={handleUnitDelete}
          onMetricUpdate={handleMetricUpdate}
          onMetricDelete={handleMetricDelete}
          key={item.id}
        />)}
      </Accordion>
    </Popup>
  );
};

export default CustomizePopup;
