import { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from "elements";

import { Select, Spin } from "antd";
import { debounce } from 'lodash';
import { SelectProps } from 'antd/lib/select';

import GoalForm from '../GoalForm';

import { fetchGoals } from 'Redux/APM/SearchGoals/action';

import {
  NoGoals,
  NoGoalsText,
  AddGoalOption,
} from './styled';

type IProps = SelectProps & {
  value: string | null;
  category_name: string;
  setIsAddGoal: (value: boolean) => void;
  onChange: (value: any) => void;
}

const GoalsSelect: FC<IProps> = ({ value, category_name, setIsAddGoal, onChange, ...props }) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const { loading, data: goalsData } = useSelector((state: any) => state.searchGoals);

  /* const handleSearch = useCallback(
    debounce((value: string) => {
      dispatch(fetchGoals(value));
    }, 300),
    []
  ); */

  // console.log('[goalsData]', goalsData)

  const handleChange = (value: string) => {
    const item = goalsData.find((item: any) => item.id === value);

    if (item) {
      const extraData = {
        startline: item.startline,
        deadline: item.deadline,
      }

      onChange({ value: item.id, label: item.goal_name || "Unnamed Goal", ...extraData });
    }
  }

  const handleVisibleChange = (open: boolean) => {
    if (open) {
      dispatch(fetchGoals(category_name));
    }
  }

  const handleCreateGoal = () => {
    setIsOpen(true);
    setIsAddGoal(true);
  }

  const handleGoalFormClose = (goal_name?: string) => {
    if (goal_name) {
      onChange(goal_name);
    }

    setIsAddGoal(false);
    setIsOpen(false);
  }

  // console.log('[value]', value)

  return (
    <>
      <Select
        style={{
          width: "100%",
          maxWidth: 'initial'
        }}
        value={value}
        getPopupContainer={(trigger) => trigger.parentNode}
        options={goalsData.map((item: any) => ({ value: item.id, label: item.goal_name || "Unnamed Goal" }))}
        showSearch
        // onSearch={handleSearch}
        filterOption={false}
        // dropdownRender={menu => loading ? <Spin size="small" /> : menu}
        placeholder="Select..."
        // options={goals.map((goal) => ({ key: goal.key, label: <GoalOption>{goal.label}</GoalOption> }))}
        // showSearch
        notFoundContent={(
          <NoGoals>
            <NoGoalsText>You don’t have any goals created yet</NoGoalsText>
            <Button onClick={handleCreateGoal}>Create Goal</Button>
          </NoGoals>
        )}
        dropdownRender={(menu) => (
          loading ? <div className="d-flex justify-content-center py-4"><Spin size="small" /></div> :
          <>
            {goalsData.length ? <AddGoalOption onClick={handleCreateGoal}>
              Add Goal
            </AddGoalOption> : null}

            {menu}
          </>
        )}
        onDropdownVisibleChange={handleVisibleChange}
        loading={loading}
        onChange={handleChange}
        {...props}
      />

      <GoalForm
        active={isOpen}
        data={null}
        onClose={handleGoalFormClose}
      />
    </>
  );
}

export default GoalsSelect;
