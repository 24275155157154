import React from "react";
import { Wrapper, Head, SubTitle, Title, CharType, RederData } from './styled';

//@ts-ignore
import { IoClose } from "react-icons/io5";

type Activity = {
  type: string;
  label: string;
};

interface ActivityPopupProps {
  onClose: () => void;
};

const ActivityPopup: React.FC<ActivityPopupProps> = ({ onClose }) => {
  const data: Activity[] = [
    { type: 'D', label: 'Decision' },
    { type: 'H', label: 'Habit' },
    { type: 'A', label: 'Action' },
    { type: 'T', label: 'Task' },
  ];

  return (
    <Wrapper>
      <Head>
        <Title>
          Type Of Activity
        </Title>

        <IoClose onClick={onClose} style={{ cursor: 'pointer', color: "#737373", fontSize: 16 }} />       
      </Head>

      {data.map((ele, index) => (
        <RederData role="listitem" key={index}>
          <CharType>
            {ele?.type}
          </CharType>
          <SubTitle>
            {ele?.label}
          </SubTitle>
        </RederData>
      ))}
    </Wrapper>
  );
};

export default ActivityPopup;
