import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  min-height: 172px;
  border-radius: 5px 5px 0px 0px;
  border-top: 3px solid;
  padding: 10px;
  background-color: #fff;

  ${({ $color }) => $color && css`
    border-top-color: ${$color};
  `}
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-center;

  ${({ $secondary }) => $secondary && css`
    background: var(--Color-4, #F5F5F5);
    padding: 6px 0;
  `}
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;

  ${({ $align }) => $align && css`
    justify-content: ${$align};
  `}

  ${({ $justify }) => $justify && css`
    align-items: ${$justify};
  `}

  ${({ $secondary }) => $secondary && css`
    gap: 0;
  `}

  ${({ $width }) => $width && css`
    width: ${$width};
  `}

  ${({ $ml }) => $ml && css`
    margin-left: ${$ml};
  `}

  ${({ $mr }) => $mr && css`
    margin-right: ${$mr};
  `}

  ${({ $pr }) => $pr && css`
    padding-right: ${$pr};
  `}
`;

export const Title = styled.span`
  color: var(--Input-header, #737373);
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;

  ${({ $secondary }) => $secondary && css`
    color: var(--Color-2, #171717);
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  `}
`;

export const Heading = styled.span`
  color: var(--Color-2, #171717);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  > span {
    font-weight: 400;
    color: var(--Neutral-400, var(--Placeholder, #A3A3A3));
  }
`;

export const Text = styled.span`
  color: var(--Generic-Black, #000);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  word-break: break-word;
`;

export const Circle = styled.span`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #CAE4F1;
  color: #1689CA;
  font-family: Inter;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
`;

export const PopoverToggle = styled.div`
  display: flex;
  width: 134px;
  height: 36px;
  padding: 0;
  border: none;
  background: none;
`;
