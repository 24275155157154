import React, { useState, Children } from "react";
import { Select } from "antd";
import { Button } from "elements";

import styled from "styled-components";

const MissionSelect = ({ text, option, error, onSelect, onCreate, value, isWidth, ...rest }) => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (value) => {
    setSearchValue(value);
  }

  const handleAdd = () => {
    onCreate(searchValue);
    setSearchValue("");
  }

  const handleSelect = (value) => {
    onSelect(value);
    setSearchValue("");
  }

  const newOptionValue = searchValue.trim();

  return (
    <Container>
      <Content>
        <Label>{text}</Label>
        <Wrapper $error={error} className="search_select">
          <Select
            {...rest}
            getPopupContainer={(trigger) => trigger.parentNode}
            mode="multiple"
            popupClassName="mission_select"
            value={value}
            showSearch
            searchValue={searchValue}
            onSelect={handleSelect}
            style={{
              maxWidth: "100%",
              width: value && value[0]?.length > 50 ? "550px" || isWidth && "100px" : "306px"
            }}
            optionFilterProp="value"
            autoClearSearchValue={false}
            filterOption={(input, option) =>
              ((typeof option?.label === 'string' ? option.label : option?.originalLabel).toLowerCase() ?? "").includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            listHeight={234}
            dropdownRender={(menu) => {
              const firstChild = Children.toArray(menu)[0];

              const length = firstChild && firstChild._owner && firstChild._owner.return && firstChild._owner.return.memoizedProps &&
                firstChild._owner.return.memoizedProps.value &&
                firstChild._owner.return.memoizedProps.value.flattenOptions ?
                firstChild._owner.return.memoizedProps.value.flattenOptions.length :
                0;

              return (
                <>
                  {newOptionValue.length > 2 && length ? <AddOption onClick={handleAdd}>Add as custom "{newOptionValue}"</AddOption> : null}
                  {menu}
                </>
              )
            }}
            notFoundContent={newOptionValue.length > 2 ? (
              <Button onClick={handleAdd} styleType="link-primary">Add as custom "{newOptionValue}"</Button>
            ) : "Not found."}
            options={option}
            onSearch={handleSearch}
            position="bottomLeft"
          />
        </Wrapper>

        {error ? <Error>{error}</Error> : null}
      </Content>
    </Container>
  );
};

export default MissionSelect;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div``;

const Wrapper = styled.div`
  .rc-virtual-list-holder-inner {
    cursor: default;
  }

  .ant-select-item-option-grouped {
    padding-left: 12px;
  }

  .ant-select-item {
    padding: 6px 10px 6px 10px;
  }

  .ant-select-item .ant-select-item-option-content {
    color: var(--Fill-Frame-Prussian-blue, #1B2A3D);
    font-family: Inter;
    font-size: 12px;
    line-height: 20px;
  }

  .ant-select-item.ant-select-item-group {
    padding: 6px 10px 6px 8px;
    color: var(--Fill-Frame-Prussian-blue, #1B2A3D);
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    pointer-events: none;
    cursor: default !important;

    &:first-of-type {
      display: none;
    }
  }

  ${({ $error }) => $error && `
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-bottom: 1px solid #F00000 !important;
    }
  `}
`;

const Label = styled.h3`
  margin: 0;
  margin-bottom: 0.2rem;
  color: #737373;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

const Error = styled.span`
  color: var(--destructive-500-main, #F00000);
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  display: block;
`;

const AddOption = styled.button` 
  width: 100%;
  text-align: left;
  background: transparent;
  border: none;
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: var(--primary-500-main, #1271A6);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  transition: background 0.3s ease;

  :hover {
    background-color: #f5f5f5;
  }
`;
