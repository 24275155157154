import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import EmailSuccessImg from '../../../assets/images/auth/emailSuccess.svg';
import PasswordSuccessImg from '../../../assets/images/auth/passwordSuccess.svg';

import * as Layout from '../styled';
import * as Styled from './styled';

import { SIGN_IN_PATH } from 'utils/routes';

function getEmailProviderURL(email) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!emailPattern.test(email)) {
    return false;
  }

  const domain = email.split('@')[1].toLowerCase();

  if (domain === 'gmail.com' || domain === 'crowdbotics.com')
    return 'https://mail.google.com';
  else if (domain === 'outlook.com' || domain === 'hotmail.com')
    return 'https://outlook.live.com';
  else if (domain === 'yahoo.com')
    return 'https://mail.yahoo.com';
  else if (domain === 'aol.com')
    return 'https://mail.aol.com';
  return false;
}

const PasswordSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const successType = location.state ? location.state?.type : '';
  const userEmail = location.state ? location.state?.email : '';
  const emailProvider = getEmailProviderURL(userEmail);

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (!successType) {
      navigate(`/forgot-password`, { replace: true });
    } else {
      setTimeout(() => {
        setAnimate(true);
      }, 100)
    }
  }, [successType]); 

  return (
    <Layout.Screen>
      <Layout.Column $middle>
        {successType === 'forgot'
          ? (
            <Styled.FormWrapper>
              <Styled.Image src={EmailSuccessImg} width={144.89} $animate={animate} $animateDelay={100} alt="" />

              <Layout.Title $animate={animate} $animateDelay={150}>Recovery email sent!</Layout.Title>
              <Styled.FormText $animate={animate} $animateDelay={200}>An email to change your password is on its way to your inbox.</Styled.FormText>

              {emailProvider
                ? <Styled.SubmitWrapper $animate={animate} $animateDelay={250}>
                  <Styled.ActionButton
                    href={emailProvider}
                    target="_blank"
                  >
                    Check Your Inbox
                  </Styled.ActionButton>
                </Styled.SubmitWrapper>
              : <Styled.SubmitWrapper $animate={animate} $animateDelay={250}>
                  <Styled.LinkButton
                    to={`${SIGN_IN_PATH}`}
                  >
                    Return To Login
                  </Styled.LinkButton>
                </Styled.SubmitWrapper>
              }
            </Styled.FormWrapper>
          ) : (
            <Styled.FormWrapper>
              <Styled.Image src={PasswordSuccessImg} width={132.77} $animate={animate} $animateDelay={100} alt="" />

              <Layout.Title $animate={animate} $animateDelay={150}>Password Changed</Layout.Title>
              <Styled.FormText $animate={animate} $animateDelay={200}>An email to change your password is on its way to your inbox.</Styled.FormText>

              <Styled.SubmitWrapper $animate={animate} $animateDelay={250}>
                <Styled.LinkButton
                  to={`${SIGN_IN_PATH}`}
                >
                  Return To Login
                </Styled.LinkButton>
              </Styled.SubmitWrapper>
            </Styled.FormWrapper>
          )
        }
      </Layout.Column>
    </Layout.Screen>
  );
}

export default PasswordSuccess;
