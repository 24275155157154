import Notify from "utils/notify";
import { listPrioritizedActivities, listPrioritizedActivitiesGrouped } from "../apm-apis";
import { ActionTypes } from "./type";

export const fetchListPrioritizedActions = (options = { loader: true }) => async (dispatch, getState) => {
    try {
        const { filters } = getState().prioritizedActions;
        const { journeys, filterByType, areas, order, sort } = filters;

        const filtersParams = {
            journey: journeys.filter(j => j !== 'all'),
            area: areas.filter(a => a !== 'all'),
            action_type: filterByType.filter(a => a !== 'all'),
        };
        
        if(sort) {
            if(order && order === 'descending') {
                filtersParams.ordering = `-${sort}`;
            } else {
                filtersParams.ordering = sort;
            }
        }

        if (options.loader)
            dispatch({ type: ActionTypes.FETCH_LIST_PRIORITIZED_ACTIONS_REQUEST });

        const { data } = await listPrioritizedActivities(filtersParams);

        const processedData = data.filter(a => a.action.context || a.action.name);

        dispatch({
            type: ActionTypes.FETCH_LIST_PRIORITIZED_ACTIONS_SUCCESS,
            payload: /* data */processedData,
            length: processedData.length
        });
    } catch (error) {
        Notify.error(error, true)
        dispatch({
            type: ActionTypes.FETCH_LIST_PRIORITIZED_ACTIONS_FAILURE,
            payload: error
        });
        dispatch({ type: ActionTypes.FETCH_LIST_PRIORITIZED_ACTIONS_REQUEST });
    }
};

export const fetchListPrioritizedActionsGrouped = (options = { loader: true }) => async (dispatch, getState) => {
    try {
        const { filters } = getState().prioritizedActions;
        const { journeys, filterByType, areas, order, sort } = filters;

        const filtersParams = {
            journey: journeys.filter(j => j !== 'all'),
            area: areas.filter(a => a !== 'all'),
            action_type: filterByType.filter(a => a !== 'all'),
        };

        let sortCustom = sort;

        if (sort === 'indirect_impact')
            sortCustom = 'prioritized_activities__indirect_impact';
        else if (sort === 'urgency_score')
            sortCustom = 'prioritized_activities__urgency_score';

        if(sortCustom) {
            if (order && order === 'descending') {
                filtersParams.order_by = `-${sortCustom}`;
            } else {
                filtersParams.order_by = sortCustom;
            }
        }

        if (options.loader)
            dispatch({ type: ActionTypes.FETCH_LIST_PRIORITIZED_ACTIONS_GROUPED_REQUEST });

        const { data } = await listPrioritizedActivitiesGrouped(filtersParams);

        // Data must be processed on the FE because of the messy and inconsistent way the API is structured 🤷‍♀️
        const processedData = data
            .map(group => ({
                ...group,
                actions: group.actions
                    .filter((a, index, self) =>
                        index === self.findIndex((t) => (
                            t.id === a.id
                        ))
                    )
                    .filter(a => a.context || a.name)
                    .map(a => {
                        const { id: actionId, context, startline, deadline, priority, type, prioritized_activities, ...rest } = a;
                        const { id: priorityId, user, ...prioritizedActivity } = prioritized_activities[0];

                        return {
                            ...rest,
                            ...prioritizedActivity,
                            action: {
                                id: actionId,
                                context,
                                startline,
                                deadline,
                                priority,
                                type,
                                user
                            },
                            id: priorityId,
                            category_name: group.goal.category_name,
                            goal_name: group.goal.goal_name,
                            goal_id: group.goal.id,
                        }
                    })
            }))
            .filter(group => group.actions.length > 0);

        dispatch({
            type: ActionTypes.FETCH_LIST_PRIORITIZED_ACTIONS_GROUPED_SUCCESS,
            payload: /* data */processedData,
            length: processedData.reduce((acc, group) => acc + group.actions.length, 0)
        });
    } catch (error) {
        Notify.error(error, true);

        dispatch({
            type: ActionTypes.FETCH_LIST_PRIORITIZED_ACTIONS_GROUPED_FAILURE,
            payload: error
        });

        dispatch({ type: ActionTypes.FETCH_LIST_PRIORITIZED_ACTIONS_GROUPED_REQUEST });
    }
};

export const updatePriorizedFilters = (filters) => async (dispatch, getState) => {
    try {
        dispatch({ type: ActionTypes.UPDATE_PRIORITIZED_VALUES, payload: filters });

        setTimeout(() => {
            const { simpleView } = getState().prioritizedActions;

            if (simpleView)
                dispatch(fetchListPrioritizedActions());
            else
                dispatch(fetchListPrioritizedActionsGrouped());
        }, 0);
    } catch (error) {
        Notify.error(error, true)
    }
};

export const toggleView = () => async (dispatch, getState) => {
    try {
        dispatch({ type: ActionTypes.TOGGLE_VIEW });

        setTimeout(() => {
            const { simpleView } = getState().prioritizedActions;

            if (simpleView)
                dispatch(fetchListPrioritizedActions());
            else
                dispatch(fetchListPrioritizedActionsGrouped());

            // dispatch(fetchTasks());
        }, 0);
    } catch (error) {
        Notify.error(error, true)
    }
};

export const getPrioritizedActionData = (focusedPriorityId, callback) => async (_, getState) => {
    const data = getState().prioritizedActions;
    const { simpleView } = getState().prioritizedActions;

    if (simpleView) {
        const item = data.prioritizedData.find(item => item.id === focusedPriorityId);
        callback(item);
    }
    else {
        const item = data.prioritizedDataGrouped
            .flatMap(group => group.actions)
            .find(action => action.id === focusedPriorityId);
        callback(item);
    }
}
