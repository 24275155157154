import React, { useCallback, useEffect, useRef, useState } from "react";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactSwitch from "react-switch";
import { Dropdown } from "elements";
import _ from "lodash";

import plusIcon from "assets/images/journeys/plus.svg";

import missionIcon from "assets/images/journeys/mission.svg";
import missionIconActive from "assets/images/journeys/mission_active.svg";

import { ReactComponent as Trash } from "assets/images/priorities/trash.svg";
import { ListAreas } from "Redux/ListAreas/action";

import {
  RetrieveMasterPlanDetails,
  RetrieveMasterPlanDetailsNull,
} from "Redux/RetrieveMasterPlan/action";
import { UpdateUserArea } from "Redux/UpdateUserArea/action";
import { DeleteUserArea } from "Redux/DeleteUserArea/action";
import { UpdateGoal } from "Redux/UpdateGoal/action";
import { UpdateLastStep } from "Redux/UpdateLastStep/action";
import { GenerateAreaCategories } from "Redux/GenerateAreaCategory/action";
import { setIsDisabledStep, SetSelectedGoalAction } from "Redux/Global/action";

import MissionStatement from "./MissionStatment";
import LockedOver from "components/LockedOver/LockedOver";

import * as Styles from "./styled.js";
import StepLayout from "../StepLayout";

import Notify from "utils/notify";
import { PRIORITIZE_PATH } from "utils/routes";
import { getCookie } from "utils/StorageVariables";
import { PrioritizeAreaAndCategories } from "Redux/PrioritizeAreaAndCategories/action";

import cc from "classnames";
import styled from './styled.module.scss';

const Mission = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isDisabled, setIsDisabled] = useState(false);
  const { masterPlanDetails } = useSelector((state) => state.RetrieveMasterPlanDetailsReducer);
  const { SelectedClient } = useSelector((state) => state.globalReducer);

  const [areasAndCategories, setAreasAndCategories] = useState([]);
  const [areas, setAreas] = useState([]);
  const [focusedSelect, setFocusedSelect] = useState(null);

  const [missionStatmentGoal, setMissionStatementGoal] = useState(null);
  const [isShow, setisShow] = useState(false);

  const [openedDropdown, setOpenedDropdown] = useState({ type: null, id: null });

  const currentRef = useRef(null);

  useEffect(() => {
    const maxLength = 60;
    let currentInputField = null;

    const handleInput = (event) => {
      if (event.target.value.length > maxLength) {
        event.target.value = event.target.value.slice(0, maxLength);
      }
    };

    if (openedDropdown?.id) {
      const dropdownEl = document.querySelector(`[data-select_id="${openedDropdown.id}"]`);
      const inputEl = dropdownEl?.querySelector('.ant-select-selection-search-input');

      if (inputEl) {
        inputEl.setAttribute('maxLength', maxLength);
        inputEl.addEventListener('input', handleInput);
        currentInputField = inputEl; // Keep track of the current input field
      }
    }

    // Cleanup function to remove event listener
    return () => {
      if (currentInputField) {
        currentInputField.removeEventListener('input', handleInput);
      }
    };
  }, [openedDropdown?.id]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const redirectParam = queryParams.get('redirect');

    if (redirectParam && isDisabled) {
      queryParams.delete('redirect');
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`
      window.history.replaceState(null, '', newUrl);
      handleFieldsValidation();
      return;
    } else if (redirectParam) {
      // Remove the query param after processing to avoid processing again after a refresh
      queryParams.delete('redirect');
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`
      window.history.replaceState(null, '', newUrl);
      navigate(redirectParam, { replace: true });
    }
  }, [location.search, navigate, isDisabled]);

  useEffect(() => {
    let MasterPlanId = getCookie("MasterPlanId");

    dispatch(RetrieveMasterPlanDetails(MasterPlanId));

    return () => {
      dispatch(RetrieveMasterPlanDetailsNull(null));
      setAreasAndCategories([]);
    };
  }, []);

  const userData = JSON.parse(getCookie("userData"));

  useEffect(() => {
    if (!masterPlanDetails)
      return;

    if (!masterPlanDetails?.areas?.length) {
      dispatch(GenerateAreaCategories(masterPlanDetails?.areas?.length + 1));
    } else {
      if (areasAndCategories.length < masterPlanDetails?.areas?.length) {
        setTimeout(() => {
          focusNextEmpty();
        }, 50);
      }

      setAreasAndCategories(handleFormatArrayByKey(masterPlanDetails?.areas));
    }

    return () => {
      setAreasAndCategories([]);
    };
  }, [masterPlanDetails]);

  useEffect(() => {
    if (!areasAndCategories)
      return;

    let emptyAreas = areasAndCategories?.filter(
      (item) => 
        (!item?.name || !item?.goal?.category_name) && 
        !(item?.is_private && SelectedClient)
    );

    setIsDisabled(emptyAreas?.length > 0);
    dispatch(setIsDisabledStep(emptyAreas?.length > 0));

    return;
  }, [areasAndCategories, SelectedClient]);

  useEffect(() => {
    let domainId = getCookie("domainId");
    dispatch(ListAreas(domainId));
  }, []);

  const { listArea } = useSelector((state) => state.listAreaReducer);

  useEffect(() => {
    if (!listArea)
      return;

    let allAreas = [];

    listArea?.map((subDomain) => {
      allAreas = [
        ...allAreas,
        ...subDomain?.areas?.map((area) => {
          return {
            name: area?.name,
            id: area?.id,
            color: area?.color,
            categories: area?.categories,
          };
        }),
      ];
    });

    setAreas(allAreas);
  }, [listArea]);

  const handleFormatArrayByKey = (orderedArr) =>
    orderedArr?.sort(function (a, b) {
      return a?.goal?.priority - b?.goal?.priority;
    });

  const { loading } = useSelector(
    (state) => state.generateAreaCategoriesReducer
  );

  const groupedAreas = listArea?.map((subDomain) => {
    return {
      label: subDomain?.name,
      options: subDomain?.areas?.map((area) => {
        return { label: area?.name, value: area?.id };
      }),
    };
  });

  const deleteRow = (id) => {
    let MasterPlanId = getCookie("MasterPlanId");
    let payloads = [...masterPlanDetails?.areas.filter(area => area.id !== id)].map((item, i) => {
      return {
        goal: item?.goal?.id,
        priority: i + 1,
      };
    });

    dispatch(DeleteUserArea(id));
    dispatch(PrioritizeAreaAndCategories(payloads, MasterPlanId));
    dispatch(SetSelectedGoalAction(null));
  };

  const handleSelectArea = (area, uId, i) => {
    let foundArea = areas.find((item) => item?.id === area?.value);
    let areasAndCategoriesCopy = [...areasAndCategories];
    let findedObj = areasAndCategoriesCopy?.find((item) => item?.id === uId);
    if (!foundArea) {
      if (!area.name) return;
      findedObj.name = area?.name;
      findedObj.is_custom = true;
    } else {
      findedObj.name = foundArea?.name;
      findedObj.color = foundArea?.color;
      findedObj.is_custom = false;
    }
    // empty previous category value on area change
    dispatch(UpdateGoal(findedObj?.goal?.id, { category_name: "" }));
    findedObj.goal.category_name = "";
    findedObj.goal.is_custom = false;
    let { name, color, is_custom } = findedObj;
    handleAreaUpdate({ name, color, is_custom }, uId);
    areasAndCategoriesCopy[i] = findedObj;
    setAreasAndCategories(areasAndCategoriesCopy);
  };

  const handleSelectCategory = (category, uId, i, isCustom) => {
    let areasAndCategoriesCopy = [...areasAndCategories];
    let findedObj = areasAndCategoriesCopy?.find((item) => item?.id === uId);
    if (!category?.label) return;
    findedObj.goal.category_name = category?.label;
    findedObj.goal.is_custom = isCustom;
    let { category_name } = findedObj?.goal;
    handleCategoryUpdate({ category_name }, findedObj.goal.id);
    areasAndCategoriesCopy[i] = findedObj;
    setAreasAndCategories(areasAndCategoriesCopy);
  };

  const handleDropdownVisibleChange = (id, type) => open => {
    if (open) {
      setOpenedDropdown({ type, id });
    } else if (openedDropdown.type === type && openedDropdown.id === id) {
      setOpenedDropdown({ type: null, id: null });

      if (type === 'area') {
        // Debounce to ensure this doesn't immediately reopen
        setTimeout(() => {
          const $dropdown = document.querySelector(`[data-select_id=${id}]`);

          if ($dropdown && !$dropdown.classList.contains('mission_dropdown--empty'))
            focusNextEmpty();
        }, 100);
      }
    }
  }

  const focusNextEmpty = () => {
    const missionDropdown = document.querySelectorAll('.mission_dropdown--empty');

    if (missionDropdown.length) {
      const antSelect = missionDropdown[0].querySelector('.ant-select-selector');

      if (antSelect) {
        const focusId = missionDropdown[0].getAttribute('data-select_id');

        setFocusedSelect(focusId);
      }
    }
  }

  const handleAddMore = () => {
    setIsDisabled(true);

    dispatch(GenerateAreaCategories(masterPlanDetails?.areas?.length + 1, data => {
      let MasterPlanId = getCookie("MasterPlanId");
      let payloads = [...data.areas]
        .sort((a, b) => a.goal?.priority - b.goal?.priority)
        .map((item, i) => {
          return {
            goal: item?.goal?.id,
            priority: i + 1,
          };
        });

      dispatch(PrioritizeAreaAndCategories(payloads, MasterPlanId));
      focusNextEmpty();
    }));
  };

  const handleAreaUpdate = useCallback(
    _.debounce(
      (updatedArea, areaId) =>
        dispatch(UpdateUserArea(areaId, { ...updatedArea })),
      500
    ),
    []
  );

  /* const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIndexView(null);
      }
    }
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  } */

  const handleCategoryUpdate = useCallback(
    _.debounce(
      (updatedGoal, goalId) => dispatch(UpdateGoal(goalId, { ...updatedGoal })),
      500
    ),
    []
  );

  /* useEffect(() => {
    if (areasAndCategories?.length === 1) return setDltBtnDisable(true);
    return setDltBtnDisable(false);
  }, [areasAndCategories?.length]); */

  useEffect(() => {
    if (focusedSelect) {
      const antSelect = document.querySelector(`.mission_dropdown--empty[data-select_id="${focusedSelect}"]`);

      if (antSelect) {
        setTimeout(() => {
          const selector = antSelect.querySelector('.ant-select-selector');

          if (selector) {
            selector.click();
          }

          setFocusedSelect(null);
        }, 50);
      }
    }
  }, [focusedSelect]);

  // console.log('[is disabled]', isDisabled)

  const handleNext = () => {
    if (isDisabled) {
      handleFieldsValidation();
      return;
    }

    const stepFormData = new FormData();
    stepFormData.append("step", 1);
    dispatch(UpdateLastStep(stepFormData));
    navigate(PRIORITIZE_PATH);
  };

  const hanlePvt = ({ is_private, id }) => {
    const pvtFormData = new FormData();
    pvtFormData.append("is_private", !is_private);
    dispatch(UpdateUserArea(id, pvtFormData));
  };

  const handleFieldsValidation = () => {
    Notify.error('Complete all fields to proceed.');

    const missionDropdown = document.querySelectorAll('.mission_dropdown--empty');

    if (missionDropdown.length) {
      const antSelect = missionDropdown[0].querySelector('.ant-select-selector');

      if (antSelect) {
        const focusId = missionDropdown[0].getAttribute('data-select_id');

        setFocusedSelect(focusId);
      }
    }
  }

  const capitalize = (str) =>
    str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
      return letter.toUpperCase();
    });

  const handleNavigate = step => {
    if (isDisabled) {
      handleFieldsValidation();
      return;
    }

    navigate(step);
  }

  const HeaderContent = () => {
    return (
      <>
        <Styles.TableHeadCol $width="30vw" $ml={"24px"} $pl={"22px"}>
          Areas
        </Styles.TableHeadCol>
        <Styles.TableHeadCol>Categories</Styles.TableHeadCol>
      </>
    );
  };

  return (
    <StepLayout
      title="1. Select Your Areas & Categories"
      header={<HeaderContent />}
      isNextDisabled={loading}
      nextPageTooltip="Please Select Your Areas & Categories"
      onNext={handleNext}
      onNavigate={handleNavigate}
    >
      <Styles.TableContainer>
        {areasAndCategories?.map((areaCategory, i) => {
          const isLocked = areaCategory?.is_private && userData?.id !== areaCategory?.user;

          return (
            <Styles.Row key={i} border={areaCategory?.color}>
              {isLocked && (
                <LockedOver width="40px" display="row" index={i + 1} />
              )}
  
              <Styles.Count>{i + 1}</Styles.Count>
              <Styles.ItemContent>
                <Styles.LeftSide>
                  <Select
                    virtual={false}
                    data-select_id={`area-${i}`}
                    className={cc(styled.dropdown, { [styled.empty]: !areaCategory?.name })}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    showSearch
                    placeholder="Select Area or Enter Your Own"
                    optionFilterProp="children"
                    onSearch={(value) => {
                      handleSelectArea(
                        { name: capitalize(value), value: null },
                        areaCategory?.id,
                        i
                      )
                    }}
                    onChange={(value) => {
                      handleSelectArea(value, areaCategory?.id, i);
                    }}
                    onDropdownVisibleChange={handleDropdownVisibleChange(`area-${i}`, 'area')}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        ?.toLowerCase()
                        ?.includes(input.toLowerCase())
                    }
                    options={groupedAreas}
                    value={
                      areaCategory?.name
                        ? {
                          label: areaCategory?.name,
                          value: "",
                        }
                        : null
                    }
                    labelInValue={true}
                    defaultValue={areaCategory?.name}
                    showAction={(focusedSelect === `area-${i}` && openedDropdown.type !== 'area') ? ['focus'] : []}
                    autoFocus={(focusedSelect === `area-${i}` && openedDropdown.type !== 'area')}
                    disabled={isLocked}
                  />
                </Styles.LeftSide>
                <Styles.RightSide>
                  <Select
                    virtual={false}
                    ref={`cat-${i}` === focusedSelect ? currentRef : null}
                    data-select_id={`cat-${i}`}
                    className={cc(styled.dropdown, { [styled.empty]: !areaCategory?.goal?.category_name })}
                    popupClassName="mission_dropdown_category"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    showSearch
                    placeholder="Select Category or Enter Your Own"
                    optionFilterProp="children"
                    onChange={(value) =>
                      handleSelectCategory(value, areaCategory?.id, i, false)
                    }
                    onDropdownVisibleChange={handleDropdownVisibleChange(`cat-${i}`, 'cat')}
                    onSearch={(value) =>
                      handleSelectCategory(
                        { label: capitalize(value), value: null },
                        areaCategory?.id,
                        i,
                        true
                      )
                    }
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        ?.toLowerCase()
                        ?.includes(input.toLowerCase())
                    }
                    options={
                      [
                        {
                          label: areaCategory?.name,
                          options: areas
                            ?.find((area) => area?.name === areaCategory?.name)
                            ?.categories?.map((item) => {
                              return {
                                label: item?.name,
                                value: item?.id,
                              };
                            }) || []
                        }
                      ]
                    }
                    value={
                      areaCategory?.goal?.category_name
                        ? {
                          label: areaCategory?.goal?.category_name,
                          value: "",
                        }
                        : null
                    }
                    labelInValue={true}
                    defaultValue={areaCategory?.goal?.category_name}
                    showAction={(focusedSelect === `cat-${i}` && openedDropdown.type !== 'cat') ? ['focus'] : []}
                    autoFocus={(focusedSelect === `cat-${i}` && openedDropdown.type !== 'cat')}
                    disabled={isLocked}
                  />
                </Styles.RightSide>
              </Styles.ItemContent>
  
              <Styles.InfoWrapper>
                {areaCategory?.name && areaCategory?.goal?.category_name ? <Styles.IconButton
                  title="Mission Statement"
                  onClick={() => {
                    setMissionStatementGoal({
                      category: areaCategory?.goal?.category_name,
                      area: areaCategory?.name,
                      priority: areaCategory?.goal?.priority,
                      goal: areaCategory?.goal?.id,
                      hasMissionStatement:
                        areaCategory?.goal?.has_mission_statement,
                      // hasMissionStatement: true,
                    });
                    setisShow(true);
                  }}
                  disabled={
                    !areaCategory?.name || !areaCategory?.goal?.category_name
                  }
                >
                  {areaCategory?.goal?.has_mission_statement ? (
                    <img src={missionIconActive} alt="mission" />
                  ) : (
                    <img src={missionIcon} alt="mission" />
                  )}
                </Styles.IconButton> : null}
  
                <Dropdown
                  secondary
                  menuItems={[
                    {
                      key: "private",
                      element: (
                        <Styles.DropdownItem>
                          Make Private
                          <Styles.SwitchWrapper>
                            <ReactSwitch
                              checked={areaCategory?.is_private}
                              checkedIcon={false}
                              uncheckedIcon={false}
                              onColor="#1689CA"
                              offColor="#cdcdcd"
                              handleDiameter={10}
                              height={15}
                              width={28}
                              onChange={() => hanlePvt(areaCategory)}
                            />
                          </Styles.SwitchWrapper>
                        </Styles.DropdownItem>
                      ),
                      hide: userData?.id !== areaCategory?.user,
                    },
                    {
                      key: "remove",
                      element: (
                        <Styles.DropdownButton>
                          Delete Row
                          <Trash />
                        </Styles.DropdownButton>
                      ),
                      disabled: !(areasAndCategories?.length > 1),
                      onClick: () => deleteRow(areaCategory?.id),
                    },
                  ]}
                />
              </Styles.InfoWrapper>
            </Styles.Row>
          )
        })}

        <Styles.ActionsRow>
          <Styles.AddButton
            type="button"
            disabled={isDisabled || loading}
            onClick={handleAddMore}
          >
            Add More
            <img src={plusIcon} alt="+" />
          </Styles.AddButton>
        </Styles.ActionsRow>
      </Styles.TableContainer>

      {isShow && missionStatmentGoal && (
        <MissionStatement
          missionStatmentGoal={missionStatmentGoal}
          setMissionStatementGoal={setMissionStatementGoal}
          isShow={isShow}
          setisShow={setisShow}
        />
      )}
    </StepLayout>
  );
};

export default Mission;
