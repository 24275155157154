import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select, Spin } from "antd";
import { debounce } from 'lodash';
import { SelectProps } from 'antd/lib/select';

import { fetchCategories } from 'Redux/APM/SearchCategories/action';

// @ts-ignore
import styled from './styled.module.scss';

type IProps = SelectProps & {
  value: any;
  onChange: (value: any) => void;
}

const CategoriesSelect: FC<IProps> = ({ value, onChange, ...props }) => {
  const dispatch = useDispatch();

  const { loading, data: categoriesData } = useSelector((state: any) => state.searchCategories);

  useEffect(() => {
    dispatch(fetchCategories(''));
  }, []);

  const handleSearch = useCallback(
    debounce((value: string) => {
      dispatch(fetchCategories(value));
    }, 300),
    []
  );

  const handleChange = (value: string) => {
    const item = categoriesData.find((item: any) => item.id === value);

    if (item) {
      onChange(item);
    }
  }

  let dataValue = value ? { value, label: categoriesData.find((item: any) => item.id === value)?.name } : null;

  return (
    <Select
      style={{
        width: "100%",
        maxWidth: 'initial'
      }}
      popupClassName={styled.categoriesSelectPopup}
      getPopupContainer={(trigger) => trigger.parentNode}
      placeholder="Search..."
      options={categoriesData.map((item: any) => ({ value: item.id, label: `${item.name} / ${item.area.name} / ${item.area.subdomain.domain.name}` }))}
      showSearch
      onSearch={handleSearch}
      filterOption={false}
      dropdownRender={menu => loading ? <div className="d-flex justify-content-center py-4"><Spin size="small" /></div> : menu}
      loading={loading}
      // @ts-ignore
      value={dataValue}
      onChange={handleChange}
      // open
      {...props}
    />
  );
}

export default CategoriesSelect;
