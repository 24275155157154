import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Formik } from "formik";
import * as yup from "yup";

import { ReactComponent as EyeIcon } from "assets/svg/eye.svg";
import { ReactComponent as EyeSlashIcon } from "assets/svg/eye-slash.svg";

import LaptopImg from "assets/images/auth/laptop2.png";

import PasswordIndicator from '../../../components/PasswordIndicator';
import useWindowSize from '../../../utils/useWindowSize';

import * as Layout from '../styled';
import * as Styled from './styled';

import { Register } from 'Redux/Register/action';
import { ErrorFocus } from '../../../utils/ErrorFocus';
import { BREAKPOINTS } from 'theme/mixins';
import { BackNavigate } from '../Components/BackNavigate';

import { INDEX_PATH, SIGN_IN_PATH, WELCOME_PATH } from 'utils/routes';

const schema = yup.object().shape({
  first_name: yup.string()
    .matches(/^[A-Za-z]+$/, "First name can't include special characters or numbers.")
    .required("First name is required"),
  last_name: yup.string()
    .matches(/^[A-Za-z]+$/, "Last name can't include special characters or numbers.")
    .required("Last name is required"),
  email: yup.string().email('Email must be valid').required('Email is required'),
  password: yup.string()
    .required("This field is required.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Password must have a minimum of 8 characters and at least one of each: uppercase, special character, and number."
    ),
  confirmPassword: yup
    .string()
    .required("This field is required.")
    .oneOf([yup.ref("password"), null], "Password entries do not match.")
});

const initialValues = {
  email: '',
  first_name: '',
  last_name: '',
  password: '',
  confirmPassword: ''
}

const SignUp = ({ active, onNext }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  const appParam = 'gps';

  const [animate, setAnimate] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);

  const { loading } = useSelector((state) => state.registerUserReducer);

  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 100)
  }, []); 


  const navigateHandle = () => {
    onNext()
  }

  const onSubmitHandle = ({ first_name, last_name, email, password }) => {
    dispatch(
      Register(
        {
          first_name,
          last_name,
          email,
          password,
          password_2: password
        },
        navigateHandle
      )
    );
  }

 

  const onPasswordVisibleClickHandle = () => {
    setPasswordVisible(!passwordVisible);
  }

  const onPasswordConfirmVisibleClickHandle = () => {
    setPasswordConfirmVisible(!passwordConfirmVisible);
  }

  if (!active)
    return null;

  return (
    <Layout.Screen $signup>
      <BackNavigate to={INDEX_PATH} />

      <Layout.Column $first>
        <Styled.FormWrapper>
          <Layout.Title $animate={animate} $animateDelay={100}>Create an Account</Layout.Title>
          <Styled.FormText $animate={animate} $animateDelay={150}>The start of your peak performance journey is just minutes away.</Styled.FormText>

          <Formik
            validationSchema={schema}
            onSubmit={onSubmitHandle}
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={true}
          >
            {({ handleSubmit, handleReset, handleChange, values, errors }) => (
              <Styled.FormStyled noValidate onSubmit={handleSubmit}>
                <Styled.FormGroup $animate={animate} $animateDelay={200}>
                  <Styled.Label className="authLabel">First Name*</Styled.Label>
                  <div className="flex-grow-1 position-relative">
                    <Styled.TextBoxs
                      type="text"
                      name="first_name"
                      value={values.first_name}
                      onChange={handleChange}
                      isInvalid={errors.first_name}
                      aria-label="First name"
                    />
                    <Styled.FormError>
                      {errors.first_name}
                    </Styled.FormError>
                  </div>
                </Styled.FormGroup>

                <Styled.FormGroup $animate={animate} $animateDelay={250}>
                  <Styled.Label className="authLabel">Last Name*</Styled.Label>
                  <div className="flex-grow-1 position-relative">
                    <Styled.TextBoxs
                      type="text"
                      name="last_name"
                      value={values.last_name}
                      onChange={handleChange}
                      isInvalid={errors.last_name}
                      aria-label="Last name"
                    />
                    <Styled.FormError>
                      {errors.last_name}
                    </Styled.FormError>
                  </div>
                </Styled.FormGroup>

                <Styled.FormGroup $animate={animate} $animateDelay={300}>
                  <Styled.Label className="authLabel">Email*</Styled.Label>
                  <div className="flex-grow-1 position-relative">
                    <Styled.TextBoxs
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={errors.email}
                      aria-label="Email"
                    />
                    <Styled.FormError>
                      {errors.email}
                    </Styled.FormError>
                  </div>
                </Styled.FormGroup>

                <Styled.FormGroup $animate={animate} $animateDelay={350}>
                  <Styled.Label className="d-flex align-items-center justify-content-between">
                    Password*
                  </Styled.Label>
                  <div className="flex-grow-1 position-relative w-100">
                    <Styled.TextBoxs
                      type={passwordVisible ? "text" : "password"}
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={errors.password}
                      aria-label="Password"
                      data-testid="password-input"
                    />
                    <Styled.Eye className="form-control-eye" type="button" onClick={onPasswordVisibleClickHandle}>
                      {passwordVisible
                        ? <EyeSlashIcon />
                        : <EyeIcon /> }
                    </Styled.Eye>
                    <PasswordIndicator value={values.password} secondary />
                    {errors.password ? null : <Styled.FormInfo>Password must have a minimum of 8 characters and at least one of each: uppercase, special character, and number.</Styled.FormInfo>}
                    <Styled.FormError>
                      {errors.password}
                    </Styled.FormError>
                  </div>
                </Styled.FormGroup>

                <Styled.FormGroup $animate={animate} $animateDelay={400}>
                  <Styled.Label className="authLabel">Confirm Password*</Styled.Label>
                  <div className="flex-grow-1 position-relative w-100">
                    <Styled.TextBoxs
                      type={passwordConfirmVisible ? "text" : "password"}
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      isInvalid={errors.confirmPassword}
                      aria-label="Confirm password"
                      data-testid="password-confirm-input"
                    />
                    <Styled.Eye className="form-control-eye" type="button" onClick={onPasswordConfirmVisibleClickHandle}>
                      {passwordConfirmVisible
                        ? <EyeSlashIcon />
                        : <EyeIcon /> }
                    </Styled.Eye>
                    <Styled.FormError type="invalid">
                      {errors.confirmPassword}
                    </Styled.FormError>

                    {/* <Styled.FormInfo>*Indicates required field.</Styled.FormInfo> */}
                  </div>
                </Styled.FormGroup>

                <Styled.SubmitWrapper $animate={animate} $animateDelay={450}>
                  <Styled.SubmitStyled
                    type="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    Sign Up
                  </Styled.SubmitStyled>
                </Styled.SubmitWrapper>

                <ErrorFocus />
              </Styled.FormStyled>
            )}
          </Formik>

          <Styled.Linkbox $animate={animate} $animateDelay={500}>
            Already a Member?
            <Link to={`${SIGN_IN_PATH}`}>Log In</Link>
          </Styled.Linkbox>
        </Styled.FormWrapper>
      </Layout.Column>

      {width > BREAKPOINTS.sm && <Layout.Column $second $login>
        <Styled.Wrapper $animate={animate} $app={appParam}>
          {appParam === 'pp' && <img src="https://i.ibb.co/x7QctV5/project-Planner.png" alt="" />}
          {appParam === 'dm' && <img src="https://i.ibb.co/g6J06MV/decision-Matrix.png" alt="" />}
          {appParam === 'gps' && <img src={LaptopImg} alt="" />}
        </Styled.Wrapper>
      </Layout.Column>}

      {/* <Layout.Column $second $text>
        <Styled.Wrapper>
          <Styled.Heading>I’m ready, Habit Coach</Styled.Heading>
          <Styled.Subheading>Create an account to get started and join the Habit Coach community.</Styled.Subheading>
          <Styled.Features>
            <Styled.Feature>Discover the habits from the best coaches and advisors</Styled.Feature>
            <Styled.Feature>Unlock in-depth insights about your routines</Styled.Feature>
            <Styled.Feature>Manage all your most important habits in one place</Styled.Feature>
          </Styled.Features>
        </Styled.Wrapper>
      </Layout.Column> */}
    </Layout.Screen>
  );
}

export default SignUp;
