import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import { DatePicker, InputArea } from "elements";
import moment, { Moment } from "moment";

import Accordion from "react-bootstrap/Accordion";

import Group from "./Group";
import Impact from "./Impact";
import TimePopover from "../TimePopover";

import {
  Background,
  Wrapper,
  TopContent,
  Content,
  ListItemCount,
  Header,
  Body,
  HeaderContent,
  // TitleWrapper,
  // Title,
  CloseBtn,
  List,
  ListTitle
} from "./styled";

//@ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/priorities/close.svg";
//@ts-ignore
import { ReactComponent as Priority } from "assets/images/priorities/common/priority.svg";
//@ts-ignore
import { ReactComponent as CalendarBlueIcon } from "assets/images/priorities/calendar-blue.svg";
//@ts-ignore
import { ReactComponent as HourglassIcon } from "assets/images/priorities/hourglass.svg";
//@ts-ignore
import { ReactComponent as FlashGreenIcon } from "assets/images/priorities/lightning-green.svg";
//@ts-ignore
import { availableAreas } from "Redux/APM/AvailableAreas/action";
import { updateAction } from "Redux/APM/CreateAction/action";
import TitleInput from "./TitleInput";

interface PriorityData {
  id: number;
  color: string;
  progress: number;
  type: string;
  focused: boolean;
  time: string;
  title: string;
  order: number;
  startline?: string;
  deadline?: string;
  action?: {
    context: string;
  };
  calculated_priority?: number;
  urgency_score?: number;
  estimated_weekly_duration?: number;
  management_time: number;
  total_time: number;
  delegation: number;
}

interface SelectedItem {
  area_name: string;
  category_name: string;
  management_time: number;
  total_time: number;
  delegation_time: number;
  estimated_weekly_duration: number;
}

interface IGoal {
  id: string;
  color: string;
  gap_percentage: number | null;
  goal_name: string;
  category_name: string;
  startline: string | null;
  deadline: string | null;
}

const initial: any = {
  id: 0,
  color: "#ccc",
  progress: 100,
  type: "",
  focused: false,
  time: "",
  title: "",
  order: 0,
};

const dateFormat = "YYYY/MM/DD";

const PriorityEdit: React.FC<{
  data?: PriorityData;
  goal?: IGoal;
  active: boolean;
  index: number;
  defaultManagementTime: number;
  delegation: number;
  screenSizes: string;
  onClose: () => void;
}> = ({
  data = initial,
  goal,
  active,
  onClose,
  index,
}) => {
  const dispatch = useDispatch();

  const [isRendered, setIsRendered] = useState(active);
  const [isActiveState, setIsActiveState] = useState(false);

  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);

  const [title, setTitle] = useState("");

  const { color, id } = data;

  useEffect(() => {
    if (data?.startline) {
      setStartDate(moment(data.startline));
    }

    if (data?.deadline) {
      setEndDate(moment(data.deadline));
    }

    if (data?.context || data?.action?.context) {
      setTitle(data?.context || data?.action?.context);
    }
  }, [data?.startline, data?.deadline, data?.context, data?.action?.context, active]);

  const startline = startDate
    ? moment(startDate).format("YYYY-MM-DD")
    : undefined;

  const deadline = endDate
    ? moment(endDate).format("YYYY-MM-DD")
    : undefined;

  function calculateDaysLeft(startDateStr: string, endDateStr: string) {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    const timeDifference = endDate.getTime() - startDate.getTime();

    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  }

  const currentDate = new Date().toISOString().split("T")[0];
  const daysLeft = deadline && calculateDaysLeft(currentDate, deadline);

  useEffect(() => {
    if (id) {
      dispatch(availableAreas(id));
    }
  }, [id]);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        handleClose();
      }
    };

    document.addEventListener("keydown", handleEsc);

    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [onClose]);

  useEffect(() => {
    if (active) {
      setIsRendered(true);
      setTimeout(() => setIsActiveState(true), 50); // Delay setting $active to true by 50ms
    } else {
      setIsActiveState(false);
      setTimeout(() => setIsRendered(false), 500); // Delay unmounting by 500ms
    }
  }, [active]);

  if (!isRendered) {
    return null;
  }

  const handleDateChange = (field: string) => (date: any) => {
    if (field === "startline") {
      setStartDate(date);
    }
    else {
      setEndDate(date);
    }
  }

  const handleClose = () => {
    const formData = new FormData();

    formData.append("prioritized_activity_id", id);

    if (startline)
      formData.append("startline", startline);

    if (deadline)
      formData.append("deadline", deadline);

    if (title)
      formData.append("action_name", title);

    dispatch(updateAction(formData, () => {}, { notify: false, loader: false }));

    onClose();
  }

  let actionDeadline = moment(data.deadline);
  let goalDeadline = moment(goal?.deadline);

  let minStartline = moment(goal?.startline).toDate();
  let maxStartline = deadline && goal?.deadline
    ? moment.min(actionDeadline, goalDeadline).toDate()
    : (deadline ? moment(deadline).toDate() : moment(goal?.deadline).toDate());

  return createPortal(
    <>
      <Wrapper $active={isActiveState}>
        <Header $color={goal?.color || color}>
          <HeaderContent>
            <div style={{ width: "100%" }}>
              <TopContent>
                <CloseBtn onClick={handleClose}>
                  <CloseIcon />
                </CloseBtn>
              </TopContent>

              <TitleInput
                value={title}
                onChange={setTitle}
              />
            </div>
          </HeaderContent>
        </Header>

        <Body>
          <Accordion
            as={Content}
            defaultActiveKey={["Priority", "DATE", "Urgency", "TIME", "Impact"]}
            alwaysOpen
          >
            <Group
              title="Priority"
              text="Indicates the current action item's priority"
              icon={Priority}
              separator
            >
              <List $priority>
                <ListTitle>Priority</ListTitle>
                <ListItemCount>#{index}</ListItemCount>
              </List>
            </Group>

            <Group
              title="Impact"
              text="Guides prioritization for maximum return."
              icon={FlashGreenIcon}
              separator
            >
              <Impact id={id} selectedItem={data} />
            </Group>

            <Group
              title="DATE"
              dayLeft={daysLeft ? Number(daysLeft) : undefined}
              text="Shows time period for this action item."
              icon={CalendarBlueIcon}
              separator
            >
              <List>
                <ListTitle>Startline</ListTitle>
                <DatePicker
                  value={startline ? moment(startline) : null}
                  format={dateFormat}
                  placeholder="Set date"
                  customSize="xs"
                  inputReadOnly
                  disabledDate={
                    (current: any) => (
                      current && (
                        current > moment(maxStartline).subtract(1, 'day').endOf('day') || 
                        current < moment(minStartline).startOf('day')
                      )
                    )
                  }
                  onChange={handleDateChange('startline')}
                  allowClear={true}
                />

                <ListTitle>Deadline*</ListTitle>
                <DatePicker
                  customSize="xs"
                  value={deadline ? moment(deadline) : null}
                  format={dateFormat}
                  placeholder="Set date"
                  inputReadOnly
                  disabledDate={
                    (current: any) => (
                      current && (
                        (goalDeadline !== undefined && current > moment(goalDeadline).endOf('day')) || 
                        (startline === null ? current < moment().endOf('day') : current < moment.min(moment(startline).endOf('day'), moment().endOf('day')))
                      )
                    )
                  }
                  onChange={handleDateChange('deadline')}
                  allowClear={false}
                />
              </List>
            </Group>

            <List style={{ marginTop: -20, marginBottom: 24 }}>
              <ListTitle>Urgency Score</ListTitle>
              <ListTitle>{data?.urgency_score}</ListTitle>
            </List>

            <Group
              title="TIME"
              text="Total time to complete this activity."
              icon={HourglassIcon}
              separator
            >
              <TimePopover
                id={id}
                delegation_time={data?.delegation_time}
                management_time={data?.management_time}
                inline
              />
            </Group>
          
          </Accordion>
        </Body>
      </Wrapper>

      <Background
        $active={isActiveState}
        onClick={isActiveState ? handleClose : undefined}
      />
    </>,
    document.getElementById("portal") as HTMLElement
  );
};

PriorityEdit.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default PriorityEdit;
