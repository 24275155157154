import { ActionTypes } from "./types";
import { ActionTypes as GoalActionTypes } from '../UpdateGoal/types';
import { ActionTypes as ActionInputTypes } from '../UpdateActions/types';
import { ActionTypes as CreateActionTypes } from '../CreateAction/types';

let INITIAL_STATE = {
  loading: false,
  error: null,
  masterPlanDetails: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.SUCCESS:
      return {
        ...state,
        loading: false,
        masterPlanDetails: null,
        masterPlanDetails: action.payload,
      };

    case ActionTypes.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GoalActionTypes.UPDATE_GOAL_SUCCESS:
      return {
        ...state,
        loading: false,
        masterPlanDetails: {
          ...state.masterPlanDetails,
          areas: state.masterPlanDetails.areas.map(area => area.id === action.payload.parent_area
            ? { ...area, goal: action.payload }
            : area)
        }
      };

    case CreateActionTypes.SUCCESS:
      try {
        return {
          ...state,
          loading: false,
          masterPlanDetails: {
            ...state.masterPlanDetails,
            areas: state.masterPlanDetails.areas.map(area => ({
              ...area,
              goal: {
                ...area.goal,
                actions: area.goal?.id === action.payload?.goal?.id
                  ? [...area.goal.actions, action.payload]
                  : area.goal.actions
              }
            }))
          }
        };
      } catch (e) {
        console.error('🔴', e)
      }

    case ActionTypes.PRIORITIZE:
      try {
        return {
          ...state,
          loading: false,
          masterPlanDetails: {
            ...state.masterPlanDetails,
            areas: [...action.payload.areas]
          }
        };
      } catch (e) {
        console.error('🔴', e)
      }

    case ActionInputTypes.SUCCESS:
      try {
        return {
          ...state,
          masterPlanDetails: {
            ...state.masterPlanDetails,
            areas: state.masterPlanDetails.areas.map((area) => {
              if (area.goal?.id === action.payload.goal) {
                const updatedGoal = {
                  ...area.goal,
                  actions: area.goal.actions.map((goalAction) => {
                    if (goalAction.id === action.payload?.id) {
                      return { ...goalAction, ...action.payload };
                    } else {
                      return goalAction;
                    }
                  }),
                };
                return { ...area, goal: updatedGoal };
              } else {
                return area;
              }
            }),
          }
        }
      } catch (e) {
        console.error('🔴', e)
      }

    default:
      return state;
  }
};
