import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";
import ActivityItem from "../ActivityItem";

import { Wrapper, Title, Group, LoaderWrapper, SubTitle } from "./styled";

import { IFocusedExtreme } from "Redux/APM/ListFocusedExtremes/type";

interface IData {
  most_time_consuming: IFocusedExtreme[];
  least_priority: IFocusedExtreme[];
}

interface IProps {
  data: IData;
  loading: boolean;
  onTimeChange?: (id: string, value: number, original: number) => void;
  onRemove?: (id: string, time: number) => void;
}

const List: FC<IProps> = ({ data, loading, onTimeChange, onRemove }) => {
  const { most_time_consuming, least_priority } = data;

  return (
    <Wrapper>
      <Group>
        <Title>Most Time-consuming Activities</Title>

        <SubTitle>Est.Duration</SubTitle>

        {loading ? (
          <Loader />
        ) : (
          most_time_consuming.map((item) => (
            <ActivityItem
              id={item.id}
              activity={item.prioritized_activity}
              task={item.task}
              priority={item.priority}
              key={item.id}
              adjustDuration
              showRemoveBtn
              onTimeChange={onTimeChange}
              onRemove={onRemove}
            />
          ))
        )}
      </Group>

      <Group>
        <Title>Low Priority Activities</Title>

        {loading ? (
          <Loader />
        ) : (
          least_priority.map((item) => (
            <ActivityItem
              id={item.id}
              activity={item.prioritized_activity}
              task={item.task}
              priority={item.priority}
              key={item.id}
              adjustDuration
              showRemoveBtn
              onTimeChange={onTimeChange}
              onRemove={onRemove}
            />
          ))
        )}
      </Group>
    </Wrapper>
  );
};

const Loader = () => {
  return (
    <LoaderWrapper>
      <Skeleton width={"100%"} height={42} count={1} inline={true} />
      <Skeleton width={"100%"} height={42} count={1} inline={true} />
      <Skeleton width={"100%"} height={42} count={1} inline={true} />
    </LoaderWrapper>
  );
};

export default List;
