import { Menu, Dropdown, Space } from 'antd';
import { ReactComponent as Dots } from "./dotIcon.svg";
import { ReactComponent as Share } from "./Share.svg";
import { ReactComponent as Export } from "./export.svg";
import { ReactComponent as Delete } from "./delete.svg";

import { useState } from 'react'; // Import the useState hook
import styled from 'styled-components';



const MissionDropDown = ({ deleteOpenModal, setDeleteOpenModal = () => { } }) => {
    const menu = (
        <Menu>
            {/* <Menu.Item key="1"  >Share <Share />   </Menu.Item>
            <Menu.Item key="2">Export <Export /></Menu.Item> */}
            <Menu.Item style={{ color: ' #F90' }} key="3" onClick={() => setDeleteOpenModal(true)}> Delete <Delete /></Menu.Item>
        </Menu>
    );
    const [visible, setVisible] = useState(false); // State to control the menu visibility

    const handleMenuClick = () => {
        setVisible(!visible); // Toggle the menu visibility
    };

    const handleMenuVisibleChange = (flag) => {
        if (!flag) {
            setVisible(false); // Close the menu when it's not visible
        }
    };

    return (
        <Container >
            <div className='mission_dropDown'>
                <Dropdown
                    overlay={menu}
                    visible={visible}
                    onVisibleChange={handleMenuVisibleChange}
                    placement="bottomRight"

                >
                    <a className="ant-dropdown-link" onClick={handleMenuClick}>
                        <Space>
                            <Dots />
                        </Space>
                    </a>
                </Dropdown>
            </div>
        </Container>
    );
};

export default MissionDropDown;

const Container = styled.div`
width: 100%;

`;
