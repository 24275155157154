import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 23px;
  background: var(--Neutral-100, #f5f5f5);
  gap: 20px;
`;

export const Title = styled.span`
  color: var(--Oxford-blue, #06203a);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
`;

export const SubTitle = styled.span`
  color: var(--Neutral-500, #737373);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  margin-left: 56%;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
