import { isRouteErrorResponse } from "react-router-dom";
import { listAvailableAreasAndIndirectScores } from "../apm-apis";
import { ActionTypes } from "./type";

export const availableAreas = (id) => async (dispatch) => {
    try {
        dispatch({ type: ActionTypes.FETCH_AVAILABLE_AREAS_REQUEST });
        const { data } = await listAvailableAreasAndIndirectScores(id);

        dispatch({  
            type: ActionTypes.FETCH_AVAILABLE_AREAS_SUCCESS,
            payload: data
        });
    } catch (error) {
        isRouteErrorResponse(error?.response?.data?.error);
        dispatch({
            type: ActionTypes.FETCH_AVAILABLE_AREAS_FAILURE,
            payload: error
        });
    }
};