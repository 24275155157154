import styled from "styled-components";

interface BackgroundProps {
  $active: boolean;
  $higher: boolean;
}

export const Background = styled.div<BackgroundProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(79, 89, 102, 0.50);
  top: 0;
  left: 0;
  z-index: 998;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s, visibility .25s;

  ${({ $active }) => $active && `
    opacity: 1;
    visibility: visible;
  `};

  ${({ $higher }) => $higher && `
    z-index: 1000;
  `};
}`;

interface WrapperProps {
  $active: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 390px;
  height: calc(100vh - 70px - 16px);
  background: #fff;
  border-radius: 8px;
  right: 0;
  top: 0;
  margin-top: 70px;
  margin-right: 16px;
  z-index: 999;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  transition: transform .25s, opacity .25s, visibility .25s;

  ${({ $active }) =>
    $active &&
    `
    transform: translateX(0%);
    opacity: 1;
    visibility: visible;
  `};
}`;

export const Header = styled.div`
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px 8px 0px 0px;
  border-top: 2px solid var(--Dark-mode-bg, #FFF);
  border-right: 2px solid var(--Dark-mode-bg, #FFF);
  border-left: 2px solid var(--Dark-mode-bg, #FFF);
  background: var(--primary-500-main, #1271A6);
`;

export const HeaderHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const HeaderTitle = styled.span`
  color: var(--Fill-Frame-White, var(--Dark-mode-bg, #FFF));
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 2.16px;
  text-transform: uppercase;
`;

export const CloseBtn = styled.button`
  display: flex;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  padding: 0;
  transition: transform 0.25s;
  margin-left: 10px;

  svg .st0 {
    fill: var(--Fill-Frame-White, var(--Dark-mode-bg, #FFF));
  }

  &:hover {
    transform: rotate(180deg);
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 12px;
  overflow-y: auto;
  margin-right: 6px;
  margin-top: 6px;
  gap: 10px;

  ::-webkit-scrollbar:vertical {
    width: 6px !important;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important ;
  }

  ::-webkit-scrollbar-thumb:vertical {
    background-color: #acb4bc;
    border-radius: 100px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  
`;

export const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--Neutral-500, #737373);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const ErrorLabel = styled.span`
  color: var(--Neutral-500, #f00);
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
  margin-top: -6px;
`;

export const GoalOption = styled.span`
  padding: 6px 10px;
  background: transparent;
  border: none;
  color: #1B2A3D;
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px;
  gap: 10px;
  border-top: 1px solid #C1CAD1;
`;
export const QuestionIcn = styled.div`

margin-left:5px;
// cursor:pointer
`;

