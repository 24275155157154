import React, { useState, useCallback, useRef } from "react";
import Switch from "react-switch";

import { Dropdown } from 'elements';
import _ from "lodash";

import LockedOver from "components/LockedOver/LockedOver";
import PopoverCustom from "components/PriorityApp/SimpleView/PopoverCustom/index.tsx";
import UnitsSelector from "../UnitsSelector/index.jsx";

import InputAreaAI from "components/InputAreaAI";

import { ReactComponent as ChevronRightIcon } from "assets/images/journeys/chevron-right.svg";

import * as Styles from "../styled.js";
import { useDispatch } from "react-redux";

import { UpdateGoal } from "Redux/UpdateGoal/action.js";
import { UpdateUserArea } from "Redux/UpdateUserArea/action.js";

import { useDebouncedUpdates } from "utils/utility.js";

const items = [
  {
    key: "1",
    element: "Daily",
  },
  {
    key: "2",
    element: "Weekly",
  },
  {
    key: "3",
    element: "Monthly",
  },
  {
    key: "4",
    element: "Quarterly",
  },
  {
    key: "5",
    element: "Yearly",
  },
];

const MeasurementItem = ({ data: item, index: i, firstIndex, userDataId, predefinedMetrics, customMetrics, containerRef, onAddMetricPopupOpen, onManageToggle }) => {
  const dispatch = useDispatch();

  const [goal, setGoal] = useState(item?.goal?.goal_name ?? '');
  const [isAssistantOpen, setIsAssistantOpen] = useState(false);

  const $field = useRef();
  const $measurement = useRef();

  useState(() => {
    if (i === firstIndex) {
      setTimeout(() => {
        if ($field.current)
          $field.current.focus();
      }, 150);
    }
  }, [i, firstIndex]);

  const updateGoal = useCallback((name, value) => {
    dispatch(UpdateGoal(item?.goal?.id, { [name]: value }));
  }, [item?.goal?.id, dispatch]);

  const getDebouncedUpdate = useDebouncedUpdates(updateGoal);

  const handleUpdate = useCallback((name, value) => {
    const debouncedUpdate = getDebouncedUpdate(name);
    debouncedUpdate(value);
  }, [getDebouncedUpdate]);

  const onUnitsChangeHandle = (e) => {
    if (e.key === "custom")
      onAddMetricPopupOpen(true);
    else if (e.key === "manage")
      onManageToggle();
    else {
      const unit = findUnitById(e.key);

      if (!unit || unit?.name === item?.goal?.measurement_metric_name)
        return;

      dispatch(UpdateGoal(item?.goal?.id, {
        measurement_metric_name: unit.name,
        measurement_start_value: null,
        measurement_current_value: null,
        measurement_target_value: null,
      }));
    }
  };

  const handleGoals = (value) => {
    setGoal(value);
    handleUpdate("goal_name", value);
  };

  const hanlePvt = ({ is_private, id }) => {
    const pvtFormData = new FormData();
    pvtFormData.append("is_private", !is_private);
    dispatch(UpdateUserArea(id, pvtFormData));
  };

  const handleMeasurementsChange = (field, value) => {
    handleUpdate(field, value);
  };

  const handleFrequencyChange = (event) => {
    const item = items.find(i => i.key === event.key);

    if (!item)
      return;

    handleUpdate("frequency", item.element);
  };

  const handleAssistantSelect = ({ text, value }) => {
    dispatch(UpdateGoal(item?.goal?.id, {
      measurement_metric_name: text/* .substring(0, 6) */,
      measurement_start_value: null,
      measurement_current_value: null,
      measurement_target_value: value
    }, () => {
      setTimeout(() => {
        if ($measurement.current) {
          $measurement.current.click();
        }
      }, 100);
    }));
  };

  const handleAssistantOpen = value => {
    setIsAssistantOpen(value);
  }

  function findUnitById(id) {
    for (const category of [...predefinedMetrics, ...customMetrics]) {
      for (const unit of category.units) {
        if (unit.id === id) {
          return unit;
        }
      }
    }
    return null; // Return null if no matching unit is found
  }

  const isLocked = item?.is_private && userDataId !== item?.user;

  return (
    <Styles.Row border={item?.color}>
      {isLocked && (
        <LockedOver width="32px" display="row" index={i + 1} />
      )}

      {userDataId === item?.user && (
        <Styles.SwitchWrapperMobile>
          <Styles.TextPvt>Private</Styles.TextPvt>
          <Switch
            onChange={hanlePvt}
            checked={item?.is_private}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor="#1371a6"
            offColor="#cdcdcd"
            handleDiameter={12}
            height={18}
            width={30}
          />
        </Styles.SwitchWrapperMobile>
      )}

      <Styles.RowContent>
        <Styles.LeftSection>
          <Styles.WrapAvatar>
            <Styles.IdAvatar size={24}>{i + 1}</Styles.IdAvatar>
          </Styles.WrapAvatar>

          <Styles.WrapHead>
            <Styles.WrapText>
              <Styles.Text $area>{item.name}</Styles.Text>
              <Styles.Text $category>
                {item?.goal?.category_name}
              </Styles.Text>
            </Styles.WrapText>

            <InputAreaAI
              id={`goal_${item?.id}`}
              value={goal}
              placeholder="State your goal"
              onChange={handleGoals}
              style={{ wordBreak: 'break-word' }}
              max={300}
              tooltip={i === firstIndex}
              assistant={{
                type: 'goal',
                color: item?.color,
                title: "Goal Statement",
                order: i + 1,
                area: item.name,
                category: item?.goal?.category_name
              }}
              ref={$field}
              containerRef={containerRef}
              disabled={isLocked}
            />
          </Styles.WrapHead>
        </Styles.LeftSection>

        <Styles.OldSection>
          <Styles.OldMeasurement>
            {item.goal?.measurement && !item?.goal?.gap_percentage
              ? `# ${item.goal?.measurement}`
              : null}
          </Styles.OldMeasurement>
        </Styles.OldSection>

        <Styles.MidSection>
          <PopoverCustom
            keepOpen={isAssistantOpen}
            popoverStyle={{
              containerStyle: {
                zIndex: '998'
              }
            }}
            defaultBoundaryElement
            content={
              <UnitsSelector
                key={item?.id}
                id={item?.id}
                predefinedData={predefinedMetrics}
                customData={customMetrics}
                goalData={item?.goal}
                assistant={{
                  type: "measurement",
                  color: item?.color,
                  title: "Measurement",
                  order: i + 1,
                  area: item.name,
                  category: item?.goal?.category_name,
                  goal: item?.goal?.goal_name,
                  onSelect: handleAssistantSelect
                }}
                onClick={onUnitsChangeHandle}
                onChange={handleMeasurementsChange}
                onOpen={handleAssistantOpen}
              />
            }
          >
            <Styles.PopoverToggle>
              <InputAreaAI
                id={`measurement_${item?.id}`}
                value={
                  `${item?.goal?.measurement_current_value ||
                    item?.goal?.measurement_target_value
                    ? `${item?.goal?.measurement_current_value ??
                    " - "
                    }/${item?.goal?.measurement_target_value ??
                    " - "
                    }`
                    : "-"} ${item?.goal?.measurement_metric_name}`}
                title={
                  `${item?.goal?.measurement_current_value ||
                    item?.goal?.measurement_target_value
                    ? `${item?.goal?.measurement_current_value ??
                    " - "
                    }/${item?.goal?.measurement_target_value ??
                    " - "
                    }`
                    : "-"} ${item?.goal?.measurement_metric_name}`
                }
                suffix={item?.goal?.measurement_metric_name}
                style={{ /* height: 38,  */minWidth: 106, textAlign: 'left' }}
                assistant={{
                  type: "measurement",
                  color: item?.color,
                  title: "Measurement",
                  order: i + 1,
                  area: item.name,
                  category: item?.goal?.category_name,
                  goal: item?.goal?.goal_name,
                  onSelect: handleAssistantSelect
                }}
                ref={$measurement}
                readOnly
              />
            </Styles.PopoverToggle>
          </PopoverCustom>
        </Styles.MidSection>

        <Styles.GapSection>
          <Styles.GapText >
            {item?.goal?.gap_value ?? "-"}{" "}
            <span title={item?.goal?.measurement_metric_name}>{item?.goal?.measurement_metric_name}</span>
          </Styles.GapText>
        </Styles.GapSection>

        <Styles.GapPercentSection>
          <Styles.GapText>
            {item?.goal?.gap_percentage
              ? `${item?.goal?.gap_percentage}%`
              : "-"}
          </Styles.GapText>
        </Styles.GapPercentSection>

        <Styles.FrequencySection>
          <Dropdown
            value={items.find(i => i.element === item?.goal?.frequency)?.key || ''}
            menuItems={items}
            onChange={handleFrequencyChange}
            secondary
          >
            <Styles.FrequencyToggler>
              {item?.goal?.frequency}
              <ChevronRightIcon />
            </Styles.FrequencyToggler>
          </Dropdown>
        </Styles.FrequencySection>

        {/* 🟠 According to the Figma design */}
        {/* {userData?.id === item?.user && (
          <Styles.SwitchWrapper>
            <Switch
              onChange={() => hanlePvt(item)}
              checked={item?.is_private}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#1371a6"
              offColor="#cdcdcd"
              handleDiameter={12}
              height={18}
              width={30}
            />
          </Styles.SwitchWrapper>
        )} */}
      </Styles.RowContent>
    </Styles.Row>
  );
}

export default MeasurementItem;
