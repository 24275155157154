import styled from 'styled-components';

// @ts-ignore
import { fadeInUpMixin } from 'components/Auth/animated';
import { DataType } from './data';

export const Wrapper = styled.div<{ $color?: string | undefined; $active?: boolean; }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  border-radius: 8px;
  border-top: 4px solid #fff;
  background: var(--Dark-mode-bg, #FFF);
  box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.57);
  width: 400px;
  height: calc(100% - 60px - 12px - 24px);
  right: 18px;
  top: calc(60px + 12px);
  z-index: 99999;
  padding: 10px 0 14px 0;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  transition: transform .35s ease-in-out, opacity .35s ease-in-out, visibility .35s ease-in-out;

  ${({ $color }) => $color && `
    border-top-color: ${$color};
  `}

  ${({ $active }) => $active && `
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 9px;
  margin: 0 12px;
  border-bottom: 1px solid var(--Color-3, #D9D9D9);
`;

export const Title = styled.span`
  color: var(--Header, #112538);
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.68px;
  text-transform: uppercase;
`;

export const ToggleClose = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  transition: background-color .15s ease-in-out;

  :hover {
    background: var(--Neutral-100, #F5F5F5);
  }
`;

export const Details = styled.div<{ $mb?: number; }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  border-bottom: 1px solid var(--Color-3, #D9D9D9);
  margin: 0 12px 18px 12px;

  ${({ $mb }) => $mb && `
    margin-bottom: ${$mb}px;
  `}
`;

export const Measurement = styled.span`
  color: var(--Header, #112538);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 6px;
  padding: 0 12px;
`;

export const Order = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--Primary-50, #ECF3F6);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #1689CA;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  line-height: 16px;
  margin-right: 6px;
`;

export const DetailsWrap = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
`;

export const Area = styled.span`
  color: var(--Neutral-600, var(--Text, #525252));
  font-family: Inter;
  font-size: 12px;
  line-height: 18px;
  margin-right: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Category = styled.span`
  color: var(--Neutral-900, var(--Color-2, #171717));
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  margin-bottom: 12px;
  padding: 0 12px;
`;

export const Scrollable = styled.div<{ $hasScrollbar: boolean }>`
  overflow-y: ${({ $hasScrollbar }) => ($hasScrollbar ? 'auto' : 'hidden')};
  padding-right: ${({ $hasScrollbar }) => ($hasScrollbar ? '12px' : '0px')};

  ::-webkit-scrollbar:vertical {
    width: 4px !important;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important ;
  }

  ::-webkit-scrollbar-thumb:vertical {
    background-color: #D7DADE;
    border-radius: 100px;
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ListItem = styled.li<{ $active?: boolean; $animate?: boolean; $animateDelay?: number; }>`
  display: flex;
  padding: 8px;
  color: var(--Neutral-900, var(--Color-2, #171717));
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
  border-radius: 3px;
  background: var(--Neutral-100, #F5F5F5);
  border: 1px solid #fff;
  transition: all .15s ease-in-out;

  ${fadeInUpMixin};

  ${({ $active }) => $active && `
    border-color var(--Primary-200, #A0C6DB);
    background: var(--Primary-50, #ECF3F6);
  `}

  :hover {
    border-color var(--Primary-200, #A0C6DB);
    background: var(--Primary-50, #ECF3F6);
  }
`;

export const LiContent = styled.span<{ $type?: DataType; }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  ${({ $type }) => $type === DataType.ACTION && `
    flex-direction: column;
    align-items: flex-start;
  `}

  ${({ $type }) => $type === DataType.MEASUREMENT && `
    justify-content: flex-start;

    span {
      :first-of-type {
        width: 50%;
        padding-right: 8px;
      }

      :nth-of-type(2) {
        flex: 1;
      }
    }
  `}
`;

export const Footer = styled.div<{ $animate?: boolean; $animateDelay?: number; }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;

  ${fadeInUpMixin};
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 0 12px;
`;

export const Disclaimer = styled.span`
  width: 100%;
  font-family: Inter;
  color: var(--Neutral-500, var(--Input-header, #737373));
  font-size: 12px;
  line-height: 16px;
`;

// Loader
export const Loader = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LoaderText = styled.span<{ $animate?: boolean; $animateDelay?: number; }>`
  margin-top: 12px;
  margin-bottom: 20px;
  color: var(--Fill-Frame-Davys-gray, var(--subheading, #4C545B));
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;

  ${fadeInUpMixin};
`;

export const LoaderImg = styled.img<{ $animate?: boolean; $animateDelay?: number; }>`
  ${fadeInUpMixin};
`;
