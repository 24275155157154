import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Label = styled.label`
  color: var(--neutral-500, var(--Input-header, #737373));
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const Form = styled.form``;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid var(--fill-controller-gainsboro-pressed, #CED0D4);
  padding: 12px 0 0;
`;

export const Group = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 12px;
`;

export const InputGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 4px;

  &:hover {
    background: var(--fill-controller-gainsboro-hover, #F0F1F3);
  }
`;

export const ActionsGroup = styled.div`
  display: flex;
`;
