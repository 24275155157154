import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  border-left: 4px solid transparent;
  background: #FFF;
  padding: 8px 12px 8px 12px;
  min-height: 184px;
  max-width: 440px;
  position: relative;

  ${({ $color }) => $color && `
    border-left-color: ${$color};
  `};
`;

export const StatusIcon = styled.div`
  position: absolute;
  width: 38px;
  height: 27px;
  top: 0;
  left: 0;
  margin-top: 11px;
  margin-left: -10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.span`
  color: var(--Oxford-blue, #06203A);
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.36px;

  ${({ $push }) => $push && `
    margin-left: 25px;
  `};
`;

export const Time = styled.span`
  color: var(--fill-icon-cadet-gray, #858E99);
  font-family: Inter;
  font-size: 10px;
  min-height: 15.7px;
`;

export const Body = styled.div`
  // flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Text = styled.span`
  color: var(--stroke-text-body-prussian-blue, #1B2A3D);
  font-family: Poppins;
  font-size: 13px;
  line-height: 16px;
`;

export const ViewAreas = styled.button`
  color: var(--primary-500-main, #1271A6);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border: none;
  background-color: transparent;
  padding: 4px 0;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 3px;
  background: var(--primary-500-main, #1271A6);
  padding: 8px 12px;
  color: var(--generic-white, #FFF);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  border: none;

  &:disabled {
    opacity: 0.5;
  }
`;

export const PrivateInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const PrivateBadge = styled.span`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 8px;
  border-radius: 24px;
  background: var(--warning-500-main, #F90);

  color: var(--Generic-White, var(--Dark-mode-bg, #FFF));
  font-family: Inter;
  font-weight: 500;
  line-height: 20px;
`;

export const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProgressContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProgressLabel = styled.span`
  color: var(--Fill-Button-Oxford-blue, #06203A);
  font-family: Inter;
  font-size: 12px;
  line-height: 24px;
`;

export const ProgressPercent = styled.span`
  color: var(--Fill-Frame-Cadet-gray, #858E99);
  font-family: Inter;
  font-size: 12px;
  line-height: 24px;
`;

export const Progress = styled.div`
  height: 6px;
  background: #EDEDED;
  border-radius: 6px;
  position: relative;
  width: 100%;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: inherit;
    width: ${({ width }) => width};
    background-color: #59A310;
  }
`;
